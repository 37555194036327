import React from "react";
const PercentageIcon = ({ width = 21, height = 19, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 21 19"
    fill="none"
    {...props}
  >
    <path
      d="M13.2991 17.7747H8.16508C3.51883 17.7747 1.53369 15.9622 1.53369 11.7198V7.03215C1.53369 2.7898 3.51883 0.977234 8.16508 0.977234H13.2991C17.9453 0.977234 19.9305 2.7898 19.9305 7.03215V11.7198C19.9305 15.9622 17.9453 17.7747 13.2991 17.7747ZM8.16508 2.14915C4.22047 2.14915 2.81719 3.43045 2.81719 7.03215V11.7198C2.81719 15.3215 4.22047 16.6028 8.16508 16.6028H13.2991C17.2437 16.6028 18.647 15.3215 18.647 11.7198V7.03215C18.647 3.43045 17.2437 2.14915 13.2991 2.14915H8.16508Z"
      fill="currentColor"
    />
    <path
      d="M7.79708 12.5158C7.6345 12.5158 7.47193 12.4611 7.34358 12.3439C7.09544 12.1173 7.09544 11.7423 7.34358 11.5158L12.9482 6.39838C13.1963 6.17181 13.607 6.17181 13.8552 6.39838C14.1033 6.62495 14.1033 6.99997 13.8552 7.22654L8.25058 12.3439C8.12223 12.4611 7.95965 12.5158 7.79708 12.5158Z"
      fill="currentColor"
    />
    <path
      d="M8.14784 8.67961C7.21516 8.67961 6.45361 7.98429 6.45361 7.1327C6.45361 6.2811 7.21516 5.58575 8.14784 5.58575C9.08051 5.58575 9.84203 6.2811 9.84203 7.1327C9.84203 7.98429 9.08051 8.67961 8.14784 8.67961ZM8.14784 6.76549C7.92536 6.76549 7.73711 6.92957 7.73711 7.14052C7.73711 7.35146 7.91681 7.51552 8.14784 7.51552C8.37887 7.51552 8.55854 7.35146 8.55854 7.14052C8.55854 6.92957 8.37031 6.76549 8.14784 6.76549Z"
      fill="currentColor"
    />
    <path
      d="M13.744 13.1569C12.8113 13.1569 12.0498 12.4616 12.0498 11.61C12.0498 10.7584 12.8113 10.063 13.744 10.063C14.6767 10.063 15.4382 10.7584 15.4382 11.61C15.4382 12.4616 14.6767 13.1569 13.744 13.1569ZM13.744 11.2428C13.5215 11.2428 13.3333 11.4068 13.3333 11.6178C13.3333 11.8287 13.513 11.9928 13.744 11.9928C13.975 11.9928 14.1547 11.8287 14.1547 11.6178C14.1547 11.4068 13.975 11.2428 13.744 11.2428Z"
      fill="currentColor"
    />
  </svg>
);
export default PercentageIcon;
