const itemExists = (cartItems, newItem) => {
  return cartItems?.some((item) => item.item_id === newItem.item_id);
};

export const cartReducer = (state, action) => {
  switch (action.type) {
    case "get_cart":
      debugger;
      return {
        ...state,
        cart: action?.payload,
        cartItems: action?.payload?.items,
      };

    case "add_bundle":
      const items = action.payload;
      const updatedCartItems = state.cartItems?.map((item) => {
        if (itemExists(state.cartItems, item)) {
          // Item exists, update quantity
          const newItem = items?.find(
            (newItem) => newItem.item_id === item.item_id
          );
          return { ...item, quantity: (item.qty = newItem?.qty) };
        }
        return item;
      });

      // Items that don't exist, add them to cartItems
      const newItems = items?.filter(
        (newItem) => !itemExists(state.cartItems, newItem)
      );
      const mergedCartItems = [...updatedCartItems, ...newItems];

      return { ...state, cartItems: mergedCartItems };

    case "add_item":
      // Your logic to update an item in the cart
      const newItem = action?.payload;

      const itemIndex = state?.cartItems?.findIndex(
        (item) =>
          (newItem?.extension_attributes?.product_sku || newItem?.sku) ===
          (item?.extension_attributes?.product_sku || item.sku)
      );

      let newCartItems = [...state?.cartItems];
      if (itemIndex > -1) {
        newCartItems = state.cartItems?.map((item, index) => {
          if (index === itemIndex) {
            return { ...item, qty: newItem.qty };
          }
          return item;
        });
      } else {
        newCartItems = [...state.cartItems, newItem];
      }
      return {
        ...state,
        cartItems: newCartItems,
      };

    case "remove":
      return {
        ...state,
        cartItems: state?.cartItems?.filter(
          (item) =>
            (item?.data?.item_id || item.item_id) !== action.payload.item_id
        ),
      };
    case "shipping_address":
      return {
        ...state,
        shippingAddress: action.payload,
      };
    case "clear":
      return {
        cart: {},
        cartItems: [],
        shippingAddress: null,
      };
    default:
      return state;
  }
};
