// use selected attributes to retrive only needed data from backend
const requiredAtteributes = [
  "sku",
  "name",
  "option_text_brand",
  "option_text_a_brand",
  "prices_with_tax",
  "image",
  "_media_",
  "category",
  "suggest",
  "stock",
  "label",
  "keywords",
  "rewrite_url",
  "attributes",
  "eligible_for_tabby",
  "eligible_for_tamara",
  "alKhobarRegion",
  "allRegions",
  "eligible_for_mispay",
  "product_fees",
  "option_text_eligible_for_tabby",
  "option_text_eligible_for_tamara",
  "back_orders",
  "show_outofstock",
  "reviews_count",
  "up_sell_products",
  "custom_related_products",
  "cart_rule",
  "free_gifts",
  "qty",
  "brand_logo",
  "highlight",
  "option_text_brand",
  "entity_id",
];

const selectedAtteributes = requiredAtteributes
  ?.map((attr) => `select=${attr}`)
  ?.join("&");
export default selectedAtteributes;
