import CategoryCard from "../cards/CategoryCard";
import TitleArrows from "./TitleArrows";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { useState } from "react";
import {
  CategoriesBreakPoints,
  HomeCategoryBreakPoints,
} from "@/constants/BreakPoints";
import { event_view_promotion } from "@/utils/gtm";
import useInViewPort from "@/hooks/global/useInViewPort";

const Category = ({ language, data: homeData, fromCategory = null, t }) => {
  const data = (fromCategory ? { value: homeData } : homeData) || {};
  const [swiper, set_swiper] = useState({});

  const fireViewPromotion = () => {if (data?.value?.length > 0) {
    const updatedData = data?.value?.map(item => ({
      promotionImage: item?.[`img-${language}`],
      key: data?.key,
      position: data?.position,
      id: item?.id,
      name: item?.[`title-${language}`],
    }))
    event_view_promotion(updatedData);
  }}
   const [ref] = useInViewPort(fireViewPromotion);

  return (
    data?.value?.length > 0 && (
      <section
        className={`${
          !fromCategory ? "w-11/12 mx-auto" : "lg:block ms-[4.5%]"
        }`}
      >
        {!fromCategory ? (
          <TitleArrows
            swiper={swiper}
            title={
              data[`title-${language}`]
                ? data[`title-${language}`]
                : t("Shop by Category")
            }
            viewTitle={t("View All Categories")}
            url="/categories" // !TODO replace slpash by view all categories url
          />
        ) : null}
        <Swiper
          key={language}
          dir={language === "en" ? "ltr" : "rtl"}
          breakpoints={
            !fromCategory ? HomeCategoryBreakPoints : CategoriesBreakPoints
          }
          spaceBetween={20}
          className="my-7"
          modules={[Autoplay]}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          onInit={(e) => set_swiper(e)}
          loop
        >
          {data?.value.map((category, idx) =>
            // category?.status &&
            {
              if (!fromCategory && (category?.status || category?.active)) {
                return (
                  <SwiperSlide key={idx}>
                    <CategoryCard
                      ref={ref}
                      data={data}
                      img={
                        category["img-" + language]?.startsWith("http")
                          ? category["img-" + language]
                          : process.env.NEXT_PUBLIC_IMG_BASEURL +
                            `/json/images/${category["img-" + language]}`
                      }
                      name={category[`title-${language}`]}
                      id={category?.id}
                      url={category["category-url"]}
                    />
                  </SwiperSlide>
                );
              } else {
                return (
                  <SwiperSlide key={idx}>
                    <CategoryCard
                      ref={ref}
                      data={data}
                      img={
                        category?.category_icon
                          ? process.env.NEXT_PUBLIC_IMG_BASEURL +
                            category?.category_icon?.replace("/media", "/")
                          : ""
                      }
                      id={category?.id}
                      name={category["name"]}
                      url={category["url_key"]}
                      fromCategory={fromCategory}
                    />
                  </SwiperSlide>
                );
              }
            }
          )}
        </Swiper>
      </section>
    )
  );
};

export default Category;
