// components/ErrorBoundary.js
import React from "react";
import { useRouter } from "next/router";
import Notfound from "./icons/NotFound";

const ErrorBoundaryWrapper = ({ children }) => {
  const { locale, push } = useRouter();
  const router = useRouter();
  const currentRoute = router.pathname;
  return (
    <ErrorBoundary locale={locale} push={push} currentRoute={currentRoute}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error("Error Boundary caught an error:", error, errorInfo);
  }

  render() {
    const { locale } = this.props;
    const {currentRoute} = this.props;
    const isCartOrCheckout = currentRoute.includes("cart") || currentRoute.includes("checkout");
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="my-5 flex flex-col items-center gap-2 justify-center sm:my-10 min-h-[70vh]">
          <Notfound className="w-80 h-80 mb-5" />
          <p className="text-xl font-extrabold">
          {!isCartOrCheckout? ( 
            locale === "en" ? "Something went wrong" : "حدث خطأ"
          ) : (
          locale === "en" ? "Can't reach your cart" : "لا يمكن الوصول لسلتك"
          )}
          
          </p>
          <p className="mt-2 w-80 text-center text-zinc-500 max-sm:text-[15px] sm:max-w-96">
          {!isCartOrCheckout? ( 
            locale === "en"
            ? "An unexpected error occurred. Please try again later or contact support if the problem persists."
            : "حدث خطأ غير متوقع. الرجاء المحاولة مرة أخرى لاحقًا أو الاتصال بالدعم إذا استمرت المشكلة."
            ):(
            locale === "en"
            ? "sorry, please log in to your account to access your cart"
            : "عفوا, يرجى تسجيل الدخول بحساب مسجل للوصول الى سلة مشترياتك"
          )}
    
          </p>
          <div className="flex gap-2">
            <button
              onClick={() => window.location.reload()}
              className="mt-3 rounded-md border bg-orange-600 text-white px-4 py-2"
            >
              {locale === "en" ? "Reload Page" : "تحديث الصفحة"}
            </button>
            <button
              onClick={() => (window.location.href = "/")}
              className="mt-3 rounded-md border bg-orange-600 text-white px-4 py-2"
            >
              {locale === "en"
                ? "Return To Home Page"
                : "العودة للصفحة الرئيسية"}
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
