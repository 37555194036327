import { Suspense, useEffect, createContext, useReducer } from "react";
import { CompareProvider } from "@/context/compare/compare-context";
import { WishlistProvider } from "@/context/wishlist/Provider";
import { CheckoutProvider } from "@/context/checkout/provider";
import { intialState, modalReducer } from "@/context/modal";
import { AppProvider } from "@/context/global/provider";
import { CartProvider } from "@/context/cart/provider";
import { registerLocale } from "react-datepicker";
import { appWithTranslation } from "next-i18next";
import { SessionProvider } from "next-auth/react";
import { Cairo } from "next/font/google";
import { useRouter } from "next/router";

import Layout from "@/layout";
import dynamic from "next/dynamic";
import Modals from "@/components/modals";
import en from "date-fns/locale/en-US"; // Example for English locale
import ar from "date-fns/locale/ar-SA"; // Example for Spanish locale
import NextNProgress from "nextjs-progressbar";
import ErrorBoundary from "@/components/ErrorBoundary";
import SuspenseFallback from "@/components/SuspenseFallback";

import "@/styles/globals.css";
import { SWRConfig } from "swr";
// import PromotionPopup from "@/components/promoPopup";
import Script from "next/script";
import * as Sentry from "@sentry/react";

registerLocale("en", en);
registerLocale("ar", ar);

const cairoFont = Cairo({
  weight: [ "400", "600", "700", "800"],
  style: ["normal"],
  display: "swap",
  subsets: ["arabic"],
  variable: "--font-cairo",
});

Sentry.init({
  dsn: "https://be9284a0735f70565e3cd4134a281e3c@o4508709427806208.ingest.de.sentry.io/4508709434490960",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: process.env.NODE_ENV === "development" ? 1.0 : 0.1, 
  replaysSessionSampleRate : process.env.NODE_ENV === "development" ? 1.0 : 0.1, 
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.NODE_ENV,
  release: process.env.REACT_APP_VERSION || 'unknown-release',
});

if (typeof window !== "undefined") {
  import("swiper/css");
  import("swiper/css/pagination");
  import("swiper/css/zoom");
  import("react-datepicker/dist/react-datepicker.css");
}
const Toaster = dynamic(() => import("react-hot-toast").then((c) => c.Toaster), {
  ssr: false,
});

export const ModalContext = createContext(null);

const App = ({ Component, pageProps }) => {
  const { locale } = useRouter();
  const [state, dispatch] = useReducer(modalReducer, intialState);
  useEffect(() => {
    window.onresize = () => {
      const windowSize = window.devicePixelRatio;
      if (windowSize < 1) {
        document.body.style.width = windowSize * 100 + "%";
        document.body.style.margin = "auto";
      } else {
        document.body.style.width = "100%";
        document.body.style.margin = "auto";
      }
    };
  });

  // Direction
  useEffect(() => {
    document.body.style.direction = locale === "en" ? "ltr" : "rtl";
  }, [locale]);

  return (
    <>
    
     <Sentry.ErrorBoundary fallback={<p>An error occurred!</p>}>
      <Script src="/service-worker.js" id="v4tech" strategy="worker" async />
      {/* <Script src="/service-worker.js" id="v4tech" strategy="lazyOnload" async /> */}
      <Suspense fallback={<SuspenseFallback />}>
        <Toaster position="bottom-center" reverseOrder={false} />
        <div className={`${cairoFont.variable} font-sans`}>
          <SessionProvider session={pageProps?.session}>
            <NextNProgress
              color="#FFF"
              startPosition={0.3}
              stopDelayMs={200}
              height={4}
              showOnShallow={false}
            />

            <SWRConfig value={{ provider: () => new Map() }}>
              <AppProvider>
                <CompareProvider>
                  <WishlistProvider>
                    <CheckoutProvider>
                      <CartProvider>
                        <ModalContext.Provider value={{ state, dispatch }}>
                          {/* <PromotionPopup /> */}
                          <Layout>
                            <ErrorBoundary>
                              <Component {...pageProps} />
                            </ErrorBoundary>
                          </Layout>
                          <Modals />
                        </ModalContext.Provider>
                      </CartProvider>
                    </CheckoutProvider>
                  </WishlistProvider>
                </CompareProvider>
              </AppProvider>
            </SWRConfig>
          </SessionProvider>
        </div>
      </Suspense>
      </Sentry.ErrorBoundary>
    </>
  );
};

export default appWithTranslation(App);
