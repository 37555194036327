const AddIcon = (props) => (
  <svg
    width={14}
    height={15}
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.75 8.33364H0.75C0.34 8.33364 0 7.9603 0 7.51008C0 7.05987 0.34 6.68652 0.75 6.68652H12.75C13.16 6.68652 13.5 7.05987 13.5 7.51008C13.5 7.9603 13.16 8.33364 12.75 8.33364Z"
      fill="currentColor"
    />
    <path
      d="M6.75 14.9222C6.34 14.9222 6 14.5489 6 14.0987V0.921705C6 0.471492 6.34 0.0981445 6.75 0.0981445C7.16 0.0981445 7.5 0.471492 7.5 0.921705V14.0987C7.5 14.5489 7.16 14.9222 6.75 14.9222Z"
      fill="currentColor"
    />
  </svg>
);
export default AddIcon;
