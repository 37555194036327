import CloseIcon from "@/components/icons/CloseIcon";
import React, { useRef } from "react";
import { useTranslation } from "next-i18next";
// import useOnClickOutside from "@/hooks/useOnClickOutside";
// import useOnClickOutsideV2 from "@/hooks/global/useOnClickOutsideV2";

const ProcessingPopUp = ({ Context }) => {
  const { t } = useTranslation("cart");
  const ref = useRef()
  function closeAll(){
    Context?.dispatch({ type: "closeAll" })
  }

  //useOnClickOutsideV2([ref], () => closeAll());

  return (
    <div ref={ref} className = "bg-white w-11/12 mx-auto sm:w-[640px] max-h-[95vh] overflow-y-auto p-5 rounded-md">
      <div className = "flex items-center justify-between pb-5 border-b">
        {/* <h2  className = "flex items-center gap-2 font-bold sm:text-lg">
          <PercentageIcon width = {24} height = {25} />
          <span>{t("Add Coupon")}</span>
        </h2> */}
          <CloseIcon
            className = "cursor-pointer size-5 hidden"
            onClick   = {() => closeAll()}
          />
      </div>
      <p className="jutisfy-center"> {t("processing your cart")} </p>
    </div>
  );
};

export default ProcessingPopUp;
