import AddEditAddress from "@/components/forms/addresses/AddEditAddress";
import CloseIcon from "@/components/icons/CloseIcon";
import useOnClickOutside from "@/hooks/useOnClickOutside";

import { useTranslation } from "next-i18next";
import { useRef } from "react";

const EditAddress = ({ Context }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("user");
  const ref = useRef();
  useOnClickOutside(ref, () => Context?.dispatch({ type: "editAddress" }));
  return (
    <div
      ref={ref}
      className="bg-white w-11/12 mx-auto sm:w-[640px] max-h-[95vh] overflow-y-auto p-3 rounded-sm"
    >
      <div className="flex items-center justify-between pb-2 border-b">
        <h2 className="font-bold sm:text-lg">{t("Edit Address")}</h2>
        <CloseIcon
          className="cursor-pointer size-5"
          onClick={() => Context?.dispatch({ type: "editAddress" })}
        />
      </div>
      <AddEditAddress
        t={t}
        language={language}
        Context={Context}
        payload={Context?.state.payload}
      />
    </div>
  );
};

export default EditAddress;
