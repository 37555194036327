import Menu from "./Menu";
import Navbar from "./Navbar";
// import TopHeader from "./TopHeader";

const Header = ({
  t,
  inputValue,
  setInputValue,
  isChangeLang,
  searchInputRef,
  setIsChangeLang,
  ourSearchInput
}) => {
  return (
    <>
      {/* <TopHeader /> */}
      <Menu {...{ isChangeLang, setIsChangeLang, t }} />
      <Navbar
        {...{
          inputValue,
          setInputValue,
          searchInputRef,
          t,
          isChangeLang,
          setIsChangeLang,
          ourSearchInput
        }}
      />
    </>
  );
};

export default Header;
