import { getProductsRating } from "@/services/product";
import { getCookie } from "cookies-next";
import { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";

const useGetProductsRating = (productsRatingCacheKey) => {
  const handshake = getCookie("handshake");
  const { cache } = useSWRConfig();
  const {
    trigger: triggerProductsRating,
    data: productsRating,
    isMutating: isMutatingProductsRating,
  } = useSWRMutation(
    productsRatingCacheKey,
    (_, { arg: entity_ids }) => getProductsRating(handshake, entity_ids),
    {
      populateCache: true,
      revalidateOnFocus: false,
      revalidateOnMount: !cache.get(productsRatingCacheKey),
    }
  );

  // console.log('cache.get(productsRatingCacheKey)', cache.get(productsRatingCacheKey))

  return {
    triggerProductsRating,
    productsRating: productsRating || {},
    isMutatingProductsRating,
  };
};

export default useGetProductsRating;
