const Arrow = ({ width = 7, height = 13, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 7 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.608607 13C0.453911 13 0.299215 12.9475 0.177086 12.835C-0.0590288 12.6176 -0.0590288 12.2577 0.177086 12.0402L5.48561 7.15141C5.87642 6.79149 5.87642 6.20663 5.48561 5.84672L0.177086 0.957895C-0.0590288 0.740447 -0.0590288 0.380534 0.177086 0.163086C0.413202 -0.0543619 0.804012 -0.0543619 1.04013 0.163086L6.34865 5.05191C6.76389 5.43432 7 5.95169 7 6.49906C7 7.04643 6.77203 7.56381 6.34865 7.94622L1.04013 12.835C0.917999 12.94 0.763303 13 0.608607 13Z"
      fill="currentColor"
    />
  </svg>
);
export default Arrow;
