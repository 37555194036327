export const appReducer = (state, action) => {
  switch (action.type) {
    case "GET_CAT_TREE":
      return {
        ...state,
        categoryData: action?.payload,
      };
    case "RESET_APP":
      return {
        categoryData: [],
      };
    case "get_ctree":
      return {
        ...state,
        categoryData: action?.payload,
        // menuTreeData: modifyMenuTreeData(action?.payload?.children_data),
        // megaMenuTreeData: modifyMegaMenuTreeData(
        //   action?.payload?.children_data
        // ),
        cacheTime: action?.payload?.cacheTime, // 30 minutes in milliseconds
      };
    case "get_cities":
      return {
        ...state,
        allCities: action?.payload,
        // menuTreeData: modifyMenuTreeData(action?.payload?.children_data),
        // megaMenuTreeData: modifyMegaMenuTreeData(
        //   action?.payload?.children_data
        // ),
        // cacheTime: action?.payload?.cacheTime, // 30 minutes in milliseconds
      };
    case "set_selc_ctree":
      return {
        ...state,
        selectedCategoryData: action?.payload,
      };
    case "set_region":
      return {
        ...state,
        reg: action?.payload,
      };
    case "clear":
      return {
        categoryData: [],
        reg: null,
        allCities: [],
      };
    default:
      return state;
  }
};
