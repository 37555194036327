const C_Service = (props) => (
  <svg
    width={24}
    height={20}
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.0112 10.424V9C21.0112 6.61307 20.0618 4.32387 18.3719 2.63604C16.6819 0.948213 14.3899 0 12 0C9.61005 0 7.318 0.948213 5.6281 2.63604C3.93817 4.32387 2.98878 6.61307 2.98878 9V10.424C1.93863 10.8858 1.0792 11.6939 0.55437 12.7131C0.0295376 13.7322 -0.128841 14.9004 0.105747 16.0223C0.340336 17.1441 0.953653 18.1513 1.84302 18.8755C2.73239 19.5996 3.84383 19.9966 4.99127 20H6.99373V10H4.99127V9C4.99127 7.14347 5.72968 5.363 7.04406 4.05025C8.3585 2.7375 10.1412 2 12 2C13.8588 2 15.6415 2.7375 16.9559 4.05025C18.2703 5.363 19.0087 7.14347 19.0087 9V10H17.0062V18H13.0012V20H19.0087C20.1562 19.9966 21.2676 19.5996 22.1569 18.8755C23.0463 18.1513 23.6597 17.1441 23.8942 16.0223C24.1289 14.9004 23.9705 13.7322 23.4456 12.7131C22.9208 11.6939 22.0614 10.8858 21.0112 10.424ZM4.99127 18C4.19463 18 3.43062 17.6839 2.86731 17.1213C2.30399 16.5587 1.98753 15.7957 1.98753 15C1.98753 14.2043 2.30399 13.4413 2.86731 12.8787C3.43062 12.3161 4.19463 12 4.99127 12V18ZM19.0087 18V12C19.8053 12 20.5694 12.3161 21.1327 12.8787C21.696 13.4413 22.0124 14.2043 22.0124 15C22.0124 15.7957 21.696 16.5587 21.1327 17.1213C20.5694 17.6839 19.8053 18 19.0087 18Z"
      fill="currentColor"
    />
  </svg>
);
export default C_Service;
