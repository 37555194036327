export function searchInfo(data, key) {
  if (!key) {
      return data; // If no key provided, return the entire data
  }
    
    const results = [];
    
    function recursiveSearch(items) {
      
      for (const item of items) {
          if (item?.name?.toLowerCase()?.includes(String(key)?.toLowerCase())) {
            results.push(item);
          }

          if (item?.children_data) {
              const result = recursiveSearch(item?.children_data);
              if (result?.length) {
                  return result; // If found in children, return the result
              }
          }

      }
      return []; // If not found, return null
  }

  recursiveSearch(data);
  return results.length > 0 ? results : [];
}