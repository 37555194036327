export const sortKeys = [
  {
    key: "sortBy=position&sortDir=ASC",
    label: "Most Views",
  },
  {
    key: "sortBy=price&sortDir=ASC",
    label: "Price Low To High",
  },
  {
    key: "sortBy=price&sortDir=DESC",
    label: "Price High To Low",
  },
  // {
  // 	key: 'sortBy=created_at&sortDir=DESC',
  // 	label: 'latest',
  // },
];

export const footerCategories = [
  {
    id: 106,
    key: "/tv-c-106",
    label: "Tv & Audio",
  },
  {
    id: 3,
    key: "/smartphones-tablets-c-3",
    label: "Smart Phones",
  },
  {
    id: 627,
    key: "/computer-accessories-c-627",
    label: "Laptop & PCs",
  },
  {
    id: 174,
    key: "/small-appliances-c-174",
    label: "HomeAppliance",
  },
  {
    id: 237,
    key: "/gaming-c-237",
    label: "Games",
  },
  {
    id: 657,
    key: "/air-conditioners-accessories-c-657",
    label: "Air Conditions",
  },
  {
    id: 219,
    key: "/personal-care-c-219",
    label: "Personal care devices",
  },
];
