import { loginValidation } from "@/utils/valitation/auth";
import { getCookie, setCookie } from "cookies-next";
import { signIn, getSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { Form, Formik } from "formik";
import { useState } from "react";

import Input from "../Input";
import Eye from "@/components/icons/Eye";
import Eyelock from "@/components/icons/Eyelock";
import { CookieExpireAfter } from "@/helpers/common/CookieExpire";
import { event_error, event_login } from "@/utils/gtm";
import { handleTime } from "@/utils/global";
import store from "store";
import { notifyWhenAvailableProduct } from "@/services/product";
import toast from "react-hot-toast";

const Login = ({ Context }) => {
  const handshake = getCookie("handshake");
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleLogin = async ({ email, password }, { setFieldError }) => {
    setLoading(true);
    const res = await signIn("credentials", {
      email,
      password,
      handshake,
      callbackUrl: "/",
      redirect: false,
    });
    const isError = !res.ok && res.status !== 200;
    if (isError) {
      const data = {
        method: "Email",
        status: `Login Failed: ${res?.error}`,
        user_id: "",
        firstname: "",
        lastname: "",
        email,
        phone: "",
        account_creation_date: handleTime(),
        gender: "",
        city: "",
        last_purchase_date:""
      };

      const {
        method,
        status,
        user_id,
        firstname,
        lastname,
        phone,
        account_creation_date,
        gender,
        city,
        last_purchase_date
      } = data;
      console.log("res////////////// in login", res);
      event_error({
        error_message: status,
        error_type: "Request Error",
        error_url: process.env.NEXT_PUBLIC_WEBSITE_URL,
      });

      console.log("res////////////// in login", res);

      event_login(
        method,
        status,
        user_id,
        firstname,
        lastname,
        email,
        phone,
        account_creation_date,
        gender,
        city,
        last_purchase_date
      );
      console.log("res////////////// in login", res);
      setFieldError(
        "password",
        language === "en"
          ? "Invalid Credentials, please try again."
          : "البريد أو كلمة المرور غير صحيح برجاء المحاوله مره أخرى"
      );
      console.log("res////////////// in login", res);
      setLoading(false);
    } else {
      const session = await getSession();
      if (session?.mage) {
        console.log("session.user loginnnnnnnnnnnn", session.user);
        const { firstname, lastname, email, created_at, extension_attributes, telephone, gender, city, id } =
          session.user;
        setCookie("mage", session?.mage, { expires: CookieExpireAfter(14) });
        const data = {
          method: "Email",
          status: "Login Success",
          user_id: id,
          firstname,
          lastname,
          telephone,
          account_creation_date: created_at,
          gender,
          city,
          last_purchase_date: extension_attributes?.last_purchase_date
        };
        const { method, status, user_id, account_creation_date } = data;

        event_login(
          method,
          status,
          user_id,
          firstname,
          lastname,
          email,
          session?.user?.custom_attributes?.[0]?.value?.slice(2),
          account_creation_date,
          gender,
          city,
          extension_attributes?.last_purchase_date
        );
        // window.location.reload();
        Context?.dispatch({ type: "closeAll" });
      }

      // handle notify me if exist
      if (store.get("notify-me-product")) {
        const res = await notifyWhenAvailableProduct({
          handshake,
          userId: session?.user?.id,
          entity_id: store.get("notify-me-product"),
        });
        const { error, message } = res?.data?.data?.[0];
        if (error) {
          toast.error(
            message ||
              (language === "en"
                ? "Please Login and try again"
                : "برجاء تسجيل الدخول والمحاولة مره أخرى")
          );
        } else {
          toast.success(
            language === "en"
              ? "Alert subscription has been saved for this product."
              : "تم تسجيل الاشتراك في هذا المنتج."
          );
          store.remove("notify-me-product");
        }
      }
    }
    setLoading(false);
    
  };

  return (
    <div>
      <p className="mt-3 text-center sm:text-xl max-sm:text-sm">
        <span className="font-semibold text-orange-600">
          {t("If you have an account ,")}{" "}
        </span>
        <span className="font-semibold text-zinc-500">
          {t("Log in using your email.")}
        </span>
      </p>
      <Formik
        onSubmit={handleLogin}
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={loginValidation(t)}
      >
        {() => (
          <Form className="grid grid-cols-1 gap-2 mt-2">
            <Input
              name="email"
              id="email"
              type="email"
              inputClassname="p-3 placeholder:text-[#525050]"
              placeholder={t("Email")}
              t={t}
            />
            <Input
              name="password"
              id="password"
              inputClassname="p-3 placeholder:text-[#525050]"
              type={show ? "text" : "password"}
              placeholder={t("Password")}
              relative
              icon={
                !show ? (
                  <Eye
                    onClick={() => setShow(!show)}
                    className={`text-zinc-400 size-5 absolute top-1/2 ${
                      language === "en" ? "right-2" : "left-2"
                    } cursor-pointer -translate-y-1/2`}
                  />
                ) : (
                  <Eyelock
                    onClick={() => setShow(!show)}
                    className={`text-zinc-400 size-5 absolute top-1/2 ${
                      language === "en" ? "right-2" : "left-2"
                    } cursor-pointer -translate-y-1/2`}
                  />
                )
              }
              t={t}
            />
            <div className="flex items-center justify-between my-1.5 text-sm sm:my-2 sm:text-base">
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="remember-me"
                  className="cursor-pointer appearance-none size-[18px] border border-zinc-300 rounded-sm checked:bg-orange-600 checked:border-orange-600"
                />
                <label
                  htmlFor="remember-me"
                  className="cursor-pointer sm:text-lg text-zinc-500"
                >
                  {t("Remember Me ?")}
                </label>
              </div>
              <button
                onClick={() => {
                  Context?.dispatch({ type: "auth" });
                  Context?.dispatch({ type: "forgot" });
                }}
                type="button"
                className="font-bold underline sm:text-lg"
              >
                {t("Forgot Password?")}
              </button>
            </div>
            <button
              className="flex items-center justify-center w-full gap-2 p-2 text-white bg-orange-600 rounded-md max-sm:text-sm disabled:cursor-not-allowed"
              type="submit"
              disabled={loading}
            >
              {loading && (
                <span className="flex items-center justify-center w-6 loader" />
              )}
              <span>{t("Login")}</span>
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
