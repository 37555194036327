import { useTranslation } from "next-i18next";
import { useCart } from "@/context/cart";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import Image from "@/components/Image";
import CloseIcon from "../icons/CloseIcon";
import CartModalCard from "../cards/CartModalCard";
import CartModalSwiper from "../global/swiper/CartModalSwiper";
import { useBodyScrollLock } from "@/hooks/global/useBodyScrollLock";
import { getProductsBySkus } from "@/services/product";
import { getCookie } from "cookies-next";

const CartModal = ({ Context }) => {
  const handshake = getCookie("handshake");
  const [relatedProducts, setRelatedProducts] = useState([]);
  const {
    i18n: { language },
  } = useTranslation("common");
  const { push } = useRouter();
  const Data = useCart();
  useEffect(() => {
    if (Data?.cart?.cartItems?.length === 0) {
      Context?.dispatch({ type: "addToCartModal" });
    }
    const lastItemAdded =
      Data?.cart?.cartItems?.at(-1)?.custom_related_products;
    if (Array.isArray(lastItemAdded) && lastItemAdded.length > 1) {
      const getRelatedProducts = async () => {
        const { data: res } = await getProductsBySkus(
          handshake,
          lastItemAdded,
          false
        );
        res?.data?.products && setRelatedProducts(res?.data?.products);
      };
      getRelatedProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Data?.cart?.cartItems]);
  useBodyScrollLock();

  // const [showFirstDiv, setShowFirstDiv] = useState(null);

  // useEffect(() => {
  //   const randomChoice = Math.random() < 0.5; // 50% chance
  //   setShowFirstDiv(randomChoice);
  // }, []);
  return (
    <div
      className={`fixed top-0 flex flex-col-reverse sm:flex-row ${
        language === "en" ? "left-0" : "right-0"
      } w-screen h-screen z-modal`}
    >
      <div className="h-[90vh] md:h-screen min-h-[80vh]  md:max-h-screen overflow-y-auto p-4 max-sm:pt-2 bg-white max-sm:w-full w-[450px] ">
        <div className="flex items-center justify-between pb-2 border-b">
          <h1 className="text-xl font-bold text-green-600 max-sm:text-lg">
            {language === "en"
              ? "Successfuly added to your Cart"
              : "تم الأضافة إلى العربة بنجاح"}
          </h1>
          <button onClick={() => Context?.dispatch({ type: "addToCartModal" })}>
            <CloseIcon className="size-5" />
          </button>
        </div>
        <div className="flex flex-col-reverse my-2 max-sm:mb-8 max-h-80 lg:max-h-[40vh] overflow-y-auto orange-scrollbar pe-2">
          {Data?.cart?.cartItems?.map((info, i) => (
            <CartModalCard
              key={i}
              language={language}
              Data={Data}
              info={info}
            />
          ))}
        </div>
        <Image
        src="/imgs/cart-popup-img3.webp"
        className={`object-contain w-full h-auto mt-2 ${
          relatedProducts?.length == 0 ? "mb-12" : "mb-3"
        }`}
        alt="Cart Product Img!"
        loading="eager"
      /> 
        {/* {showFirstDiv === null ? null :showFirstDiv?
        <Image
        src="/imgs/cart-popup-img.webp"
        className={`object-contain w-full h-auto mt-2 ${
          relatedProducts?.length == 0 ? "mb-12" : "mb-3"
        }`}
        alt="Cart Product Img!"
        loading="eager"
      /> 
      :
      <Image
      src="/imgs/cart-popup-img2.webp"
      className={`object-contain w-full h-auto mt-2 ${
        relatedProducts?.length == 0 ? "mb-12" : "mb-3"
      }`}
      alt="Cart Product Img!"
      loading="eager"
    />
      } */}
        
        {relatedProducts.length > 1 && (
          <div className="my-3">
            <p className="mb-3 text-lg font-bold">
              {language === "en"
                ? "People who bought this also bought"
                : "منتجات ذات صلة"}
            </p>
            <CartModalSwiper products={relatedProducts} />
          </div>
        )}
        <div className="fixed bottom-0 mb-5 grid w-[98vw] sm:w-[440px] ms-1 sm:ms-1.5 rounded-lg py-1.5 z-50 grid-cols-2 gap-2 start-0 max-sm:text-sm">
          <button
            onClick={() => {
              push("/cart");
              Context?.dispatch({ type: "addToCartModal" });
            }}
            className="p-2 text-lg text-white rounded-md bg-primary"
          >
            {language === "en" ? "Go To Cart" : "الذهاب لعربة التسوق"}
          </button>
          <button
            onClick={() => Context?.dispatch({ type: "addToCartModal" })}
            className="p-2 text-lg text-black border bg-white border-black rounded-md"
          >
            {language === "en" ? "Continue Shopping" : "متابعة التسوق"}
          </button>
        </div>
      </div>
      <div
        className="max-sm:min-h-[40vh] flex-1 bg-black bg-opacity-60"
        onClick={() => Context?.dispatch({ type: "addToCartModal" })}
      ></div>
    </div>
  );
};

export default CartModal;
