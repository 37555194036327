import { ErrorMessage, Field } from "formik";

const Input = ({
  name,
  label,
  id,
  type = "text",
  placeholder = "",
  cols = "col-span-full",
  t,
  relative = false,
  icon = null,
  ...props
}) => {
  return (
    <div className={`flex flex-col ${cols}`}>
      <label
        htmlFor={id}
        className={`mb-1 cursor-pointer font-semibold ${
          props?.labelClassName || ""
        }`}
      >
        {t(label)}
      </label>
      <div>
        <div className={relative && icon && "relative"}>
          <Field
            id          = {id}
            type        = {type}
            name        = {name}
            placeholder = {t(placeholder)}
            className   = {`w-full py-2 px-3 border outline-none rounded-md max-sm:placeholder:text-sm ${
              props?.inputClassname || ""
            }`}
            {...props}
          />
          {relative && icon ? icon : null}
        </div>
        <ErrorMessage
          name={name}
          component="p"
          className="text-sm text-red-600 mt-1"
        />
      </div>
    </div>
  );
};

export default Input;
