import EmptyCartIcon from "../icons/EmptyCartIcon";

const EmptyComp = ({
  title,
  description,
  description2,
  icon = <EmptyCartIcon className="h-auto w-80" />,
  fromCart,
}) => {
  return (
    <div
      style={{ height: !fromCart ? "calc(100vh - 237px)" : "100%" }}
      className="flex flex-col items-center justify-center gap-2 p-4"
    >
      <div className="flex items-center justify-start w-72 md:w-auto">
        {icon || ""}
      </div>
      {title && (
        <p className="mb-2 text-xl font-bold sm:text-2xl mt-9">{title}</p>
      )}
      {description && (
        <p className="text-zinc-500 max-w-[642px] text-center">{description} {description2 ? <><br />{description2}</> : ''}</p>
      )}
    </div>
  );
};

export default EmptyComp;
