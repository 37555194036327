import Arrow from "@/components/icons/Arrow";
import MenuCategoryTree from "./MenuCategoryTree";
import useClient from "@/hooks/global/useClient";
import useSearchMenu from "@/hooks/header/useSearchMenu";

const CategoriesHeader = ({ categoryData, t, setShowSideMenu }) => {
  const { isClient } = useClient();

  const { setQuery, allData } = useSearchMenu(categoryData?.children_data);

  const activeCategoriesData = allData?.filter(
    (cat) => cat?.include_in_menu && cat?.product_count > 0
  );

  return (
    isClient && (
      <div className="relative border w-[238px] p-2.5 rounded-xl hidden lg:flex min-w-20 items-center justify-center gap-3 group">
        <span className="cursor-pointer text-blue-dark text-[15px] font-extrabold">
          {t("Browse Categories")}
        </span>
        <Arrow className="size-2.5 rotate-90 mt-0.5 text-blue-dark" />
        {categoryData?.children_data?.length ? (
          <div className="rounded-md hidden group-hover:block absolute top-full z-50 left-0 p-2.5 bg-white border w-full">
            <input
              type="text"
              placeholder={t("Search...")}
              className="w-full px-2 py-1.5 bg-gray-50 border border-gray-200 rounded-md focus-visible:outline-none"
              onChange={(e) => setQuery(e.target.value)}
            />
            <div className="max-h-80 overflow-y-auto orange-scrollbar my-1">
              <MenuCategoryTree
                menuTreeData={
                  activeCategoriesData?.filter(
                    (item) => item?.id != process.env.NEXT_PUBLIC_BRANDS_ID
                  )?.length
                    ? activeCategoriesData?.filter(
                        (item) => item?.id != process.env.NEXT_PUBLIC_BRANDS_ID
                      )
                    : []
                }
                setShowSideMenu={setShowSideMenu}
              />
            </div>
          </div>
        ) : null}
      </div>
    )
  );
};

export default CategoriesHeader;
