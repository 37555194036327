import { userAddressesValidation } from "@/utils/valitation/user";
import { addUserNewAddress } from "@/services/user/addresses";
import { customStyles } from "@/styles/select.style";
import { ErrorMessage, Form, Formik } from "formik";
import { useSession } from "next-auth/react";
import { getCookies } from "cookies-next";
import { useRef, useState } from "react";

import Input from "../Input";
import Select from "react-select";
import toast from "react-hot-toast";

const AddEditAddress = ({ t, language, payload, Context }) => {
  console.log("payload", payload);
  const citiesRef = useRef(null);
  const { handshake, mage } = getCookies();
  const { data: session } = useSession();
  const [loading, setLoading] = useState(false);
  const regions = Object.values(payload?.regions || []);
  const [cities, setCities] = useState(
    payload?.item && payload?.item?.region_id in payload.regions
      ? payload?.regions[payload?.item?.region_id]?.areas
      : []
  );
  const currentRegion = Object.values(payload?.regions || []).findIndex(
    (item) => item.value == payload?.item?.region_id
  );
  const currentCity = cities?.length
    ? (cities || [])?.findIndex((item) => item?.label == payload?.item?.city)
    : 0;

  const handleAddAddress = async (values) => {
    setLoading(true);
    const { data, error } = await addUserNewAddress(
      handshake,
      mage || session?.mage,
      session?.user?.id,
      { ...payload?.item, ...values },
      payload?.item?.id,
      (payload?.item?.default_billing && payload?.item?.default_shipping) ||
        payload?.addresses?.length === 0
        ? true
        : false
    );
    if (error) {
      toast.error(
        language === "en"
          ? "Error Invalid , Please check your data and try again"
          : "برجاء المحاولة مره أخرى"
      );
      setLoading(false);
      return;
    } else {
      toast.success(
        language === "en"
          ? `Address ${payload?.item?.id ? "editted" : "added"} Successfully`
          : `تم ${payload?.item?.id ? "تعديل" : "إضافة"} العنوان بنجاح`
      );
      setLoading(false);
      if (payload?.item?.id) {
        const updatedIndex = payload?.addresses?.findIndex(
          (address) => address?.id === payload?.item?.id
        );
        payload.setAddresses([
          ...payload.addresses?.slice(0, updatedIndex),
          data?.data,
          ...payload.addresses?.slice(
            updatedIndex + 1,
            payload.addresses?.length
          ),
        ]);
        Context?.dispatch({ type: "editAddress" });
      } else {
        console.log("added new address");
        payload.setAddresses([...payload.addresses, data?.data]);
        Context?.dispatch({ type: "addNewAddress" });
      }
      return;
    }
  };
  console.log("payload?.item", payload?.item);
  const intialvalues = {
    firstname:
      (payload?.item ? payload?.item?.firstname : session?.user?.firstname) ||
      "",
    lastname:
      (payload?.item ? payload?.item?.lastname : session?.user?.lastname) || "",
    telephone: payload?.item?.telephone
      ? "0".concat(payload?.item?.telephone?.slice(-9))
      : session?.user?.custom_attributes[0]?.value
      ? "0" + session?.user?.custom_attributes[0]?.value?.slice(-9)
      : "",
    email: session?.user?.email,
    city: payload?.item?.city || "",
    city_id: payload?.item?.extension_attributes?.city_id
      ? payload?.item?.extension_attributes?.city_id
      : "",
    region: payload?.item?.region?.region || "",
    region_id: payload?.item?.region_id || "",
    street: payload?.item?.street?.at(0) || "",
  };
  return (
    <Formik
      initialValues={intialvalues}
      validationSchema={userAddressesValidation(t)}
      enableReinitialize
      onSubmit={handleAddAddress}
    >
      {({ setFieldValue }) => (
        <Form className="grid grid-cols-1 gap-3 mt-3 sm:grid-cols-2">
          <Input
            id="firstname"
            label={t("First Name")}
            name="firstname"
            cols="col-span-1"
            placeholder=""
            labelClassName="text-zinc-600 font-semibold text-[15px]"
            t={t}
          />
          <Input
            id="lastname"
            label={t("Last Name")}
            name="lastname"
            cols="col-span-1"
            placeholder=""
            labelClassName="text-zinc-600 font-semibold text-[15px]"
            t={t}
          />
          <Input
            id="email"
            label={t("Email")}
            name="email"
            cols="col-span-1"
            placeholder=""
            labelClassName="text-zinc-600 font-semibold text-[15px]"
            t={t}
          />
          <Input
            id="telephone"
            label={t("Phone Number")}
            name="telephone"
            cols="col-span-1"
            placeholder=""
            minLength={10}
            maxLength={10}
            labelClassName="text-zinc-600 font-semibold text-[15px]"
            t={t}
          />
          <div className="flex flex-col">
            <label
              htmlFor="region"
              className="mb-1 font-semibold cursor-pointer text-zinc-600 text-[15px]"
            >
              {t("city")}
            </label>
            <Select
              id="region"
              placeholder={t("Select city")}
              isClearable
              isSearchable
              styles={customStyles}
              options={regions}
              defaultValue={payload?.item ? regions[currentRegion] : null}
              onChange={(e) => {
                setFieldValue("region", e?.label);
                setFieldValue("region_id", e?.value);
                setFieldValue("city", "");
                citiesRef.current.clearValue();
                setCities(e?.areas);
              }}
              name="region"
            />
            <ErrorMessage
              name="region"
              component="p"
              className="mt-1 text-sm text-red-600"
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="city"
              className="mb-1 font-semibold cursor-pointer text-zinc-600 text-[15px]"
            >
              {t("Select district")}
            </label>
            <Select
              ref={citiesRef}
              placeholder={t("Select district")}
              isClearable
              isSearchable
              styles={customStyles}
              options={cities}
              defaultValue={payload?.item ? cities[currentCity] : null}
              onChange={(e) => {
                setFieldValue("city", e?.label);
                setFieldValue("city_id", e?.value);
                setFieldValue("country_id", e?.country_code);
              }}
              name="city"
            />
            <ErrorMessage
              name="city"
              component="p"
              className="mt-1 text-sm text-red-600"
            />
          </div>
          <Input
            id="street"
            label="street"
            name="street"
            cols="col-span-full"
            placeholder=""
            as="textarea"
            rows="5"
            labelClassName="text-zinc-600 font-semibold text-[15px]"
            t={t}
          />
          <button
            type="submit"
            disabled={loading}
            className="w-full px-3 py-2 font-semibold text-white bg-orange-600 border border-orange-600 rounded-md disabled:cursor-not-allowed col-span-full sm:w-52 flex items-center justify-center gap-2"
          >
            {loading && (
              <span className="w-6 loader flex items-center justify-center" />
            )}
            {payload?.item?.customer_id ? (
              <span>{t("Edit Address")}</span>
            ) : (
              <span>{t("Add New Address")}</span>
            )}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default AddEditAddress;
