const MinusIcon = (props) => (
  <svg
    width={14}
    height={3}
    viewBox="0 0 14 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 2.15615H1C0.59 2.15615 0.25 1.78281 0.25 1.33259C0.25 0.882381 0.59 0.509033 1 0.509033H13C13.41 0.509033 13.75 0.882381 13.75 1.33259C13.75 1.78281 13.41 2.15615 13 2.15615Z"
      fill="currentColor"
    />
  </svg>
);
export default MinusIcon;
