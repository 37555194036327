import InfoIcon from "@/components/icons/InfoIcon";
import Location from "@/components/icons/Location";
import { useTranslation } from "next-i18next";

const SavedAddress = ({ address, idx, active, setActive, onEdit }) => {
  const { t } = useTranslation("checkout");

  return (
    <div
      onClick={() => setActive(idx)}
      className={`my-2 rounded-sm p-3 border cursor-pointer hover:bg-slate-200 transition-all duration-500 ${
        active ? "bg-slate-200" : "bg-white"
      }`}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Location
            className={`h-6 w-6 ${active ? "text-primary" : "text-slate-900"}`}
          />
          <span className="font-semibold text-lg">{t("Home")}</span>
        </div>
        <div className="flex items-center gap-4">
          <button className="flex items-center gap-1.5 px-3" onClick={onEdit}>
            <InfoIcon className="size-4" />
            <span className="font-semibold text-sm">{t("Edit")}</span>
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-2 mt-2">
        <div className="flex items-center gap-4 whitespace-nowrap">
          <label className="text-zinc-600 min-w-20">{t("Name")} : </label>
          <p className="font-semibold">
            {address?.firstname || ""} {address?.lastname}
          </p>
        </div>
        <div className="flex items-center gap-4 whitespace-nowrap">
          <label className="text-zinc-600 min-w-20">{t("Address")} : </label>
          <p className="line-clamp-1">
            {address?.street?.at(0)} - {address?.city}
          </p>
        </div>
        <div className="flex items-center gap-4 whitespace-nowrap">
          <label className="text-zinc-600 min-w-20">{t("Phone")} : </label>
          <p className="text-primary-800 font-semibold">{address?.telephone}</p>
        </div>
      </div>
    </div>
  );
};

export default SavedAddress;
