import axios from "@/utils/axios-config";
import * as Sentry from "@sentry/react";

const deleteUserAddress = async (handshake, mage, addressId) => {
  const config = {
    method: "delete",
    url: `/customer/address/${addressId}`,
    headers: {
      Authorization: `Bearer ${handshake}`,
      mage: "Bearer " + mage,
    },
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

const getAllUserAddress = async (handshake, mage) => {
  const config = {
    method: "get",
    url: "/customer/address",
    headers: {
      Authorization: `Bearer ${handshake}`,
      mage: "Bearer " + mage,
    },
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

// refactor of add new address from dashboard
const addUserNewAddress = async (
  handshake,
  mage,
  userId,
  values,
  addressIId,
  isDefault = false
) => {
  // const city = values?.custom_attributes?.find(
  //   (item) => item?.attribute_code === "city_id"
  // );
  const city = values?.city_id?values?.city_id: values?.custom_attributes?.find((item) => item?.attribute_code === "city_id");
  // const cityIdObj = city
  //   ? { [Object.values(city)?.at(0)]: Object.values(city)?.at(1) }
  //   : null;
    
  const address = {
    address: {
      ...(addressIId ? { id: addressIId } : {}),
      customer_id: userId,
      region: {
        region_code: values?.region_id,
        region: values?.region,
        region_id: values?.region_id,
      },
      region_id: values?.region_id,
      extension_attributes: {
        city_id: values?.city_id? city : city?.value,
      },
      // email: values?.email,
      // ...(cityIdObj
      //   ? { extension_attributes: cityIdObj }
      //   : values?.extension_attributes?.city_id
      //   ? { extension_attributes: values?.extension_attributes }
      //   : {
      //       extension_attributes: {
      //         city_id: values?.city_id,
      //       },
      //     }),
      street: Array.isArray(values?.street) ? values?.street : [values?.street],
      telephone: values?.telephone?.startsWith("966")? values?.telephone :"966"?.concat(values?.telephone?.slice(-9)),
      city: values?.city,
      country_id: values?.country_id || "SA",
      postcode: "10577",
      firstname: values?.firstname,
      lastname: values?.lastname,
      ...(isDefault ? { default_billing: true } : {}),
      ...(isDefault ? { default_shipping: true } : {}),
    },
  };
  const data = JSON.stringify({ ...address });
  let config = {
    method: "put",
    url: "/customer/address",
    headers: {
      Authorization: `Bearer ${handshake}`,
      mage: "Bearer " + mage,
    },
    data: data,
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

const getCities = async (handshake) => {
  let config = {
    method: "get",
    url: "/customer/getCities",
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

export { getAllUserAddress, addUserNewAddress, deleteUserAddress, getCities };
