// import { resetCart } from "@/helpers/cart";
import {
  ADD_PRODUCT_FEES,
  addItemToCart,
  addMultiItemsToCart,
  createCart,
} from "@/services/cart";
import { getCookie, getCookies, setCookie } from "cookies-next";
import toast from "react-hot-toast";
import { event_add_to_cart } from "../gtm";

const addICartItem = async (
  handshake,
  mage,
  quote,
  product,
  qty,
  dispatch,
  reg,
  configurations,
  Context,
  locale,
  categoryName,
  categoryID,
) => {
  const sku = Array.isArray(product?.sku) ? product?.sku?.at(0) : product?.sku;

  const { data, error, status } = await addItemToCart(
    handshake,
    mage,
    quote,
    sku,
    qty,
    configurations
  );
  // resetCart(quote, data?.quoteId, dispatch);
  if (status == 200 && !error) {
    const golden_fees_sku = process.env.NEXT_PUBLIC_PRODFEESKUS || "";
    const cities = process.env.NEXT_PUBLIC_REGIDS || "";
    const isAvailableToAddFees = cities.includes(reg?.id);
    if (isAvailableToAddFees) {
      product?.product_fees.length > 0 &&
        product?.product_fees?.forEach((fee) => {
          if(fee?.sku !== golden_fees_sku){
          Object.values(fee?.options)?.forEach(async (option) => {
            const { error } = await ADD_PRODUCT_FEES(data?.quoteId, handshake, {
              id: option?.fee_id,
              options: option?.fee_option_id,
              itemId: data?.data?.item_id,
            });

            if (error === null) {
              toast.success(
                `${
                  locale === "ar"
                    ? (option?.title_ar || "") + " اضيف بنجاح"
                    : (option?.title || "") + " added successfully"
                }`
              );
            } else {
              toast.error(
                `${
                  locale === "ar"
                    ? "اضافة " + (option?.title_ar || "") + " فشلت"
                    : "adding " + (option?.title || "") + " has faild"
                }`
              );
            }
          
          });
        }
        });
      }
    
  }

  if (error) {
    if (
      error?.error?.message ===
        "Current customer does not have an active cart." ||
      error?.error?.message ===
        "Product that you are trying to add is not available."
    ) {
      const { data: newCart } = await createCart(handshake, mage);
      if (newCart?.cart_id) {
        dispatch({ type: "clear" });
        newCart?.cart_id && setCookie("quote", newCart?.cart_id);
      }
    }
    if(error?.error?.message== "The requested qty is not available"){
      if(locale=="en"){
        error.error.message= "The requested quantity is not available";
      }
      else{
        error.error.message= "الكمية المطلوبة غير متاحة";
      }
    }
    return toast.error(
      error?.error?.message ||
        "The product that was requested doesn't exist. Verify the product and try again."
    );
  }
  if (quote != data?.quoteId) {
    dispatch({ type: "clear" });
    data?.quoteId && setCookie("quote", data?.quoteId);
  }
  dispatch({
    type: "add_item",
    payload: { ...product, ...data, qty: data?.data?.qty },
  });
  data?.message &&
    toast.success(
      locale == "en"
        ? data?.message
          ? data?.message?.[0]?.toUpperCase() +
            data?.message?.slice(1)?.toLowerCase()
          : "Product added to cart successfully!"
        : "تمت إضافة المنتج للسلة بنجاح!"
    );
  const qtyForGtm = getCookie("qtyForGtm")
  let quantity = qty
  if (qtyForGtm && qty != 1) {
    if (qty > +qtyForGtm) {
      quantity = qty - +qtyForGtm
      setCookie("qtyForGtm", qty)
    } else {
      quantity = +qtyForGtm - qty
      setCookie("qtyForGtm", qty)
    }

    event_add_to_cart({...product, categoryName: categoryName ? categoryName : (Array.isArray(product?.name) ? product?.name?.at(0) : product?.name), categoryID: categoryID ? categoryID : +product?.entity_id}, quantity, getCookie("coupon_code") || product?.coupon_code)
  } else {
    if (qty == 1) {
      event_add_to_cart({...product, categoryName: categoryName ? categoryName : (Array.isArray(product?.name) ? product?.name?.at(0) : product?.name), categoryID: categoryID ? categoryID : +product?.entity_id}, qty, getCookie("coupon_code") || product?.coupon_code)
    } else {
      event_add_to_cart({...product, categoryName: categoryName ? categoryName : (Array.isArray(product?.name) ? product?.name?.at(0) : product?.name), categoryID: categoryID ? categoryID : +product?.entity_id}, quantity, getCookie("coupon_code") || product?.coupon_code)
      setCookie("qtyForGtm", quantity)
    }
  }
  console.log('quantity/////////////// in addICartItem', quantity)
  Context?.dispatch({ type: "addToCartModal" });
};

export const handleAddItemToCart = async (
  product,
  qty,
  setLoading,
  dispatchCart,
  reg,
  configurations,
  Context,
  locale,
  categoryName,
  categoryID,
  push,
  isFastBuy = false
) => {
  setLoading(true);
  const { handshake, quote, mage } = getCookies();
  await addICartItem(
    handshake,
    mage,
    quote,
    product,
    qty,
    dispatchCart,
    reg,
    configurations,
    Context,
    locale,
    categoryName,
    categoryID, 
  );
  if (isFastBuy) {
    push("/checkout");
  }
  return setLoading(false);
};

// add items to cart

// eslint-disable-next-line no-unused-vars
const addCartItems = async (
  handshake,
  mage,
  quote,
  products,
  qty,
  dispatch
  // configurations
) => {
  const skus = products?.map((item) => item.sku);
  const { data, error } = await addMultiItemsToCart(
    handshake,
    mage,
    quote,
    skus
  );

  console.log("===========================> data", data);

  if (error) {
    if (
      error?.error?.message === "No such entity with %fieldName = %fieldValue"
    ) {
      const { data: newCart } = await createCart(handshake, mage);
      if (newCart?.cart_id) {
        dispatch({ type: "clear" });
        newCart?.cart_id && setCookie("quote", newCart?.cart_id);
      }
    }
    return toast.error(
      error?.error?.message ||
        "The product that was requested doesn't exist. Verify the product and try again."
    );
  }

  if (quote != data?.quoteId) {
    dispatch({ type: "clear" });
    setCookie("quote", data?.quoteId);
  }

  const updatedProducts = data?.data?.map((prod) => {
    const mainProductData = products?.find((el) => el.sku === prod.sku);
    return {
      ...prod,
      prices_with_tax: mainProductData?.prices_with_tax
        ? { ...mainProductData?.prices_with_tax }
        : {
            price:
              +mainProductData?.product_special_price ||
              +mainProductData?.product_price,
          },
      image: [mainProductData?.product_image || mainProductData?.image?.[0]],
    };
  });

  dispatch({ type: "add_bundle", payload: updatedProducts });
  data?.message && toast.success(data?.message);

  console.log({ skus, data, error });
};

export const handleAddItemsToCart = async (
  products,
  qty,
  setLoading,
  dispatchCart,
  configurations,
  context
) => {
  setLoading(true);
  const { handshake, quote, mage } = getCookies();
  // 1 check if user has a cart. If not generate one and save it to cookies
  if (!quote) {
    const { data: newCart } = await createCart(handshake, mage);
    // 2 .5 Set the cookie with the id of the new cart in users browser and add item to cart
    if (newCart?.cart_id) {
      newCart?.cart_id && setCookie("quote", newCart?.cart_id);
      await addCartItems(
        handshake,
        mage,
        newCart?.cart_id,
        products,
        qty,
        dispatchCart,
        configurations,
        context
      );
      return setLoading(false);
    }
    return setLoading(false);
  }
  //If user already has a cart just add the item to their existing cart
  await addCartItems(
    handshake,
    mage,
    quote,
    products,
    qty,
    dispatchCart,
    configurations,
    context
  );
  return setLoading(false);
};
