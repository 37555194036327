import React, { useEffect } from "react";
import { setCookie } from "cookies-next";
import Link from "next/link";

import { useRouter } from "next/router";

const LocaleSwitcher = ({}, ref) => {
  const { asPath, locale, query, pathname } = useRouter();
  const parsedLocale = locale === "ar" ? "en" : "ar";
  useEffect(() => {
    setCookie(parsedLocale);
  }, [parsedLocale]);

  return (
    <Link
      onClick={() => {
        setCookie("locale", parsedLocale);
      }}
      href={{ pathname, query }}
      as={asPath}
      locale={parsedLocale}
      ref={ref}
    >
      {locale === "en" ? "عربى" : "English"}
    </Link>
  );
};

export default React.forwardRef(LocaleSwitcher);
