const Heart = (props) => (
  <svg
    width={17}
    height={16}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.0625 15.2792C7.83 15.2792 7.605 15.2475 7.4175 15.1763C4.5525 14.1392 0 10.4579 0 5.01917C0 2.24833 2.1225 0 4.7325 0C6 0 7.185 0.5225 8.0625 1.45667C8.94 0.5225 10.125 0 11.3925 0C14.0025 0 16.125 2.25625 16.125 5.01917C16.125 10.4658 11.5725 14.1392 8.7075 15.1763C8.52 15.2475 8.295 15.2792 8.0625 15.2792ZM4.7325 1.1875C2.745 1.1875 1.125 2.90542 1.125 5.01917C1.125 10.4262 6.0525 13.4346 7.785 14.06C7.92 14.1075 8.2125 14.1075 8.3475 14.06C10.0725 13.4346 15.0075 10.4342 15.0075 5.01917C15.0075 2.90542 13.3875 1.1875 11.4 1.1875C10.26 1.1875 9.2025 1.74958 8.52 2.72333C8.31 3.02417 7.83 3.02417 7.62 2.72333C6.9225 1.74167 5.8725 1.1875 4.7325 1.1875Z"
      fill="currentColor"
    />
  </svg>
);
export default Heart;
