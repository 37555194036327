import { resetPasswordValidation } from "@/utils/valitation/auth";
import { useTranslation } from "next-i18next";
import { Form, Formik } from "formik";
import { useRef, useState } from "react";

import Input from "../Input";
import Eye from "@/components/icons/Eye";
import Eyelock from "@/components/icons/Eyelock";
import CloseIcon from "@/components/icons/CloseIcon";
import { useRouter } from "next/router";
import { resetNewPassword } from "@/services/auth";
import { deleteCookie, getCookie } from "cookies-next";
import toast from "react-hot-toast";
import useOnClickOutside from "@/hooks/useOnClickOutside";
import { event_error } from "@/utils/gtm";

const ResetPassword = ({ Context }) => {
  const { t } = useTranslation("common");
  const { locale } = useRouter();
  const handshake = getCookie("handshake");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const intialValues = {
    password: "",
    confirmPassword: "",
  };
  const ref = useRef();
  useOnClickOutside(ref, () => Context?.dispatch({ type: "resetPassword" }));

  const handleResetPassword = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    const resetPassword = JSON.parse(getCookie("resetPassword"));

    const changedPassword = await resetNewPassword({
      handshake,
      email: resetPassword?.email,
      newPassword: values.password,
      confirmPassword: values.confirmPassword,
      resetToken: resetPassword?.otp,
    });

    if (changedPassword?.status == 200) {
      toast.success(t("PasswordUpdatedSuccessfully"));
      deleteCookie("resetPassword");
      resetForm();
      Context?.dispatch({ type: "resetPassword" });
      Context?.dispatch({ type: "successPassword" });
    } else {
      
      event_error({
        error_message: changedPassword?.error?.error?.message,
        error_type: "Request Error",
        error_url: process.env.NEXT_PUBLIC_WEBSITE_URL
      })

      toast.error(
        changedPassword?.error?.error?.message ===
          "The password token is mismatched. Reset and try again." &&
          locale === "ar"
          ? "رمز التأكيد غير صحيح حاول مرة آخري"
          : changedPassword?.error?.error?.message
      );
      Context?.dispatch({ type: "resetPassword" });
      Context?.dispatch({ type: "otp" });
    }
    setSubmitting(false);
  };
  return (
    <section
      ref={ref}
      className="bg-white w-11/12 mx-auto sm:w-[640px] max-h-[90vh] overflow-y-auto p-4 rounded-md"
    >
      <div className="flex items-center justify-between pb-2 border-b">
        <h2 className="sm:text-lg font-bold">{t("Reset Password")}</h2>
        <button onClick={() => Context?.dispatch({ type: "resetPassword" })}>
          <CloseIcon className="size-5" />
        </button>
      </div>
      <Formik
        onSubmit={handleResetPassword}
        initialValues={intialValues}
        validationSchema={resetPasswordValidation(t)}
      >
        {({ isSubmitting }) => (
          <Form className="grid grid-cols-2 gap-3 mt-2">
            <Input
              name="password"
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder={t("Password")}
              relative
              icon={
                !showPassword ? (
                  <Eye
                    onClick={() => setShowPassword(!showPassword)}
                    className={`absolute -translate-y-1/2 text-zinc-400 size-5 top-1/2 ${
                      locale == "ar" ? "left-2" : "right-2"
                    }`}
                  />
                ) : (
                  <Eyelock
                    onClick={() => setShowPassword(!showPassword)}
                    className={`absolute -translate-y-1/2 text-zinc-400 size-5 top-1/2 ${
                      locale == "ar" ? "left-2" : "right-2"
                    }`}
                  />
                )
              }
              t={t}
            />
            <Input
              name="confirmPassword"
              id="confirm-password"
              type={showConfirm ? "text" : "password"}
              placeholder={t("Confirm Password")}
              relative
              icon={
                !showConfirm ? (
                  <Eye
                    onClick={() => setShowConfirm(!showConfirm)}
                    className={`absolute -translate-y-1/2 text-zinc-400 size-5 top-1/2 ${
                      locale == "ar" ? "left-2" : "right-2"
                    }`}
                  />
                ) : (
                  <Eyelock
                    onClick={() => setShowConfirm(!showConfirm)}
                    className={`absolute -translate-y-1/2 text-zinc-400 size-5 top-1/2 ${
                      locale == "ar" ? "left-2" : "right-2"
                    }`}
                  />
                )
              }
              t={t}
            />
            <button
              disabled={isSubmitting}
              className="max-sm:text-sm w-full p-2 text-white bg-orange-600 rounded-md col-span-full disabled:cursor-not-allowed disabled:opacity-75"
              type="submit"
            >
              {t(isSubmitting ? t("Reseting") : t("Reset Password"))}
            </button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default ResetPassword;
