import { checkoutReducers } from "./reducers";
import store from "store";
const { useReducer, createContext, useEffect } = require("react");
export const CheckoutContext = createContext();

export const CheckoutProvider = ({ children }) => {
  const [checkout, dispatch] = useReducer(
    checkoutReducers,
    store.get("checkout")
      ? store.get("checkout")
      : {
          cart: {},
          items: [],
          totals: [],
          shippingAddress: null,
          active_shipping_method: null,
          shippingMethods: [],
          activeShippingMethod: "",
          pickupStore: {},
          userAddresses: [],
          payments: null,
          regions: {},
          userCity: null,
        }
  );

  useEffect(() => {
    store.set("checkout", checkout);
  }, [checkout]);

  return (
    <CheckoutContext.Provider value={{ checkout, dispatch }}>
      {children}
    </CheckoutContext.Provider>
  );
};
