import UpdatePhone from "@/components/forms/user/UpdatePhone";
import CloseIcon from "@/components/icons/CloseIcon";
import useOnClickOutside from "@/hooks/useOnClickOutside";
import { useTranslation } from "next-i18next";
import { useRef } from "react";

const ChangePhone = ({ Context }) => {
  const { t } = useTranslation("user");
  const ref = useRef();
  useOnClickOutside(ref, () => Context?.dispatch({ type: "changePhone" }));
  return (
    <div
      ref={ref}
      className="bg-white w-11/12 mx-auto sm:w-[640px] p-3 rounded-sm"
    >
      <div className="flex items-center justify-between pb-2 border-b">
        <h2 className="font-bold sm:text-lg">Change Email</h2>
        <CloseIcon
          className="cursor-pointer size-5"
          onClick={() => Context?.dispatch({ type: "changePhone" })}
        />
      </div>
      <UpdatePhone t={t} />
    </div>
  );
};

export default ChangePhone;
