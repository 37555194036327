import creditCardType from "credit-card-type";
import { object, number, string } from "yup";

export const expirationMonths = [
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;

const madaRegex =
  /^(4(0(0861|1757|3024|6136|6996|7(197|395)|9201)|1(2565|0621|0685|7633|9593)|2(0132|1141|281(7|8|9)|689700|8(331|67(1|2|3)))|3(1361|2328|4107|9954)|4(0(533|647|795)|5564|6(393|404|672))|5(5(036|708)|7865|7997|8456)|6(2220|854(0|1|2|3))|7(4491)|8(301(0|1|2)|4783|609(4|5|6)|931(7|8|9))|93428)|5(0(4300|6968|8160)|13213|2(0058|1076|4(130|514)|9(415|741))|3(0(060|906)|1(095|196)|2013|5(825|989)|6023|7767|9931)|4(3(085|357)|9760)|5(4180|7606|8563|8848)|8(5265|8(8(4(5|6|7|8|9)|5(0|1))|98(2|3))|9(005|206)))|6(0(4906|5141)|36120)|9682(0(1|2|3|4|5|6|7|8|9)|1(0|1)))\d{10}$/;
const cardNumber = (t, isMada) =>
  isMada
    ? string()
        .matches(/^(?!.*[\u0660-\u0669])[0-9]{16}$/, t("telephoneError1"))
        .matches(madaRegex, t("notMadaCard"))
        .matches(/^[0-9]{16}$/, t("cardNumberError"))
        .test(
          "card-number",
          t("InvalidCardNumber"),
          (value) => creditCardType(value).length > 0
        )
        .required(t("CardNumberRequired"))
    : string()
        .matches(/^(?!.*[\u0660-\u0669])[0-9]{16}$/, t("telephoneError1"))
        .matches(/^[0-9]{16}$/, t("cardNumberError"))
        .test(
          "card-number",
          t("InvalidCardNumber"),
          (value) => creditCardType(value).length > 0
        )
        .required(t("CardNumberRequired"));

export const validationSchema = (t, isMada) =>
  object({
    cardHolderName: string().required(t("cardNameholder")),
    cardNumber: cardNumber(t, isMada),
    expirationYear: number()
      .min(currentYear, `${t("cardExpYearError")} ${currentYear}`)
      .max(currentYear + 5, `${t("cardExpMonthError")} ${currentYear + 5}`)
      .required(t("cardExpYearError1")),
    expirationMonth: number()
      .when("expirationYear", (year, schema) => {
        return year && year[0] === currentYear
          ? schema.min(
              currentMonth,
              `${t("cardExpMonthError2")} ${
                expirationMonths[currentMonth - 1].label
              }`
            )
          : schema;
      })
      .required(t("cardExpMonthError1")),
    ccv: string()
      .required(t("ccvError1"))
      .matches(/^(?!.*[\u0660-\u0669])[0-9]+$/, t("telephoneError1"))
      .matches(/^[0-9]{3}$|^[0]$/, t("ccvError2")),
  });

export const mobileNumberValidation = (t) =>
  object().shape({
    mobile: string()
      .matches(/^(5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/, t("telephoneError3"))
      .required(t("TelephoneRequired")),
  });

export const addAddressInitialValues = {
  firstname: "",
  lastname: "",
  email: "",
  city: "",
  region: "",
  region_id: "",
  telephone: "",
  street: "",
};

export const editAddressInitialValues = (shippingAddress) => {
  return {
    firstname: shippingAddress?.firstname,
    lastname: shippingAddress?.lastname,
    email: shippingAddress?.email,
    city: shippingAddress?.city,
    region: shippingAddress?.region,
    region_id: shippingAddress?.region_id,
    telephone: shippingAddress?.telephone,
    street: shippingAddress?.street,
  };
};

export const AddressValidation = (t) =>
  object().shape({
    firstname: string().trim().required(t("firstnameRequired")),
    lastname: string().trim().required(t("lastnameRequired")),
    email: string()
      .trim()
      .email(t("notValidEmail"))
      .required(t("emailRequired")),
    region: string().trim().required(t("cityRequired")),
    city: string().when("region", {
      is: (region) => !region,
      then: (schema) => schema.required(t("districtRequired")),
      otherwise: (schema) => schema.required(t("districtRequired")),
    }),

    telephone: string()
      .label("Phone number")
      .matches(/^(?!.*[\u0660-\u0669])[0-9]+$/, t("telephoneError1"))
      .matches(/^(05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/, t("telephoneError2"))
      .min(10, t("ErrorMinLength"))
      .required(t("TelephoneRequired")),
    street: string().trim().required(t("streetRequired")),
  });

  export const AddressValidationStep1 = (t) =>
    object().shape({
      firstname: string().trim().required(t("firstnameRequired")),
      email: string()
      .trim()
      .email(t("notValidEmail"))
      .required(t("emailRequired")),
      telephone: string()
        .label("Phone number")
        .matches(/^(?!.*[\u0660-\u0669])[0-9]+$/, t("telephoneError1"))
        .matches(/^(05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/, t("telephoneError2"))
        .min(10, t("ErrorMinLength"))
        .required(t("TelephoneRequired"))
    });

    export const AddressValidationStep2 = (t) =>
      object().shape({
        region: string().trim().required(t("cityRequired")),
        city: string().when("region", {
          is: (region) => !region,
          then: (schema) => schema.required(t("districtRequired")),
          otherwise: (schema) => schema.required(t("districtRequired")),
        }),
        // street: string().trim().required(t("streetRequired")),
      });

  export const QuaraValidation = (t) =>
    object().shape({
      email: string().email(t("InvalidEmail"))
      .required(t("EmailRequired")),

      mobile: string()
        .matches(/^(5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/, t("telephoneError3"))
        .required(t("TelephoneRequired")),
    });
