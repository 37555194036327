export const HomeCategoryBreakPoints = {
  // 320: {
  //   slidesPerView: 2.3,
  // },
  320: {
    slidesPerView: 3,
  },
  540: {
    slidesPerView: 4,
  },
  769: {
    slidesPerView: 5,
  },
  961: {
    slidesPerView: 7,
  },
  1200: {
    slidesPerView: 9,
  },
};

export const CategoriesBreakPoints = {
  320: { slidesPerView: 2.5 },
  641: { slidesPerView: 3.5 },
  769: { slidesPerView: 5.5 },
  1200: { slidesPerView: 7.5 },
};

export const CategoryBannersBreakPoints = (length) => ({
  320: { slidesPerView: length || 1.5 },
  641: { slidesPerView: length || 2.5 },
  769: { slidesPerView: length || 4.5 },
  1200: { slidesPerView: length },
});
