import MenuIcon from "@/components/icons/MenuIcon";
import CategoriesHeader from "./CategoriesHeader";
import LocationHeader from "./locations/LocationHeader";
import Logo from "@/components/icons/Logo";
import BrandHeader from "./BrandHeader";
import CartHeader from "./CartHeader";
import Search from "./Search";
import Link from "next/link";
import { useGlobalData } from "@/context/global";
import SideMenu from "./SideMenu";
import { useEffect, useRef, useState } from "react";
import useOnClickOutside from "@/hooks/useOnClickOutside";
import MobileLocations from "./locations/MobileLocations";

const Navbar = ({
  inputValue,
  setInputValue,
  t,
  setIsChangeLang,
  ourSearchInput,
}) => {
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const {
    appData: { categoryData },
  } = useGlobalData();

  const modalRef = useRef();

  useOnClickOutside(modalRef, () => setShowSideMenu(false));
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 66) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <nav
      className={`flex flex-col lg:flex-row  gap-3 py-5 mx-auto duration-300 transition-all ${
        isSticky
          ? "fixed bg-white shadow-lg z-[999] start-0 w-full px-6 md:px-10"
          : "w-11/12"
      }`}
    >
      <div className="w-full flex flex-wrap items-center justify-between gap-3">
        <Link href="/">
          <Logo />
        </Link>
        <CategoriesHeader {...{ categoryData, modalRef, t, setShowSideMenu }} />
        <BrandHeader {...{ t, categoryData }} />
        <Search
          {...{
            inputValue,
            setInputValue,
            t,
            ourSearchInput,
            className: "hidden lg:flex",
          }}
        />
        <LocationHeader {...{ t, setIsChangeLang }} />
        <CartHeader />
        <div className="flex-1 flex items-center justify-end gap-2 lg:hidden">
          <MobileLocations t={t} />
          <MenuIcon
            className="cursor-pointer"
            onClick={() => setShowSideMenu((prev) => !prev)}
          />
        </div>
        {showSideMenu ? (
          <SideMenu {...{ t, categoryData, modalRef, setShowSideMenu }} />
        ) : null}
      </div>
      <Search
        {...{
          inputValue,
          setInputValue,
          t,
          className: " flex lg:hidden",
        }}
      />
    </nav>
  );
};

export default Navbar;
