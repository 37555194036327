import { Form, Formik } from "formik";
import Input from "../Input";
import { changeUserPassword } from "@/services/user/account";
import { getCookies } from "cookies-next";
import { useSession } from "next-auth/react";
import { object, ref, string } from "yup";
import toast from "react-hot-toast";

const UpdatePassword = ({ t, Context }) => {
  const {handshake, mage} = getCookies()
  const session = useSession()
  const user = session?.data?.user

  const validationSchema = object({
    oldPassword: string().required(t("PasswordRequired")),
    newPassword    : string().required(t("PasswordRequired")),
    confirmPassword: string().required(t("ConfirmPasswordRequired")).oneOf([ref('newPassword'), null], t("PasswordsMustMatch"))
  })

  async function handleSubmit(values){
    const res = await changeUserPassword({handshake, mage, customerId:user?.id, currentPassword:values?.oldPassword, newPassword:values?.newPassword, confirmPassword: values?.confirmPassword} )
    if(res.status == 200) {
      toast.success(res?.data?.message)
      Context?.dispatch({ type: "changePassword" })
    }else{
      toast.error(res?.error?.error?.message)
    }
  }
  
  return (
    <Formik
      initialValues    = {{ oldPassword: "", newPassword: "", confirmPassword: "" }}
      onSubmit         = {handleSubmit}
      validationSchema = {validationSchema}
    >
      {({ isSubmitting }) => (
      <Form className="mt-3 grid grid-cols-1 gap-3">
        <Input
          id             = "old-password"
          name           = "oldPassword"
          placeholder    = "currentPassword"
          label          = "currentPassword"
          type           = "password"
          labelClassName = "text-zinc-600 font-semibold"
          t              = {t}
        />
        <Input
          id             = "new-password"
          name           = "newPassword"
          placeholder    = "Password"
          label          = "Password"
          type           = "password"
          labelClassName = "text-zinc-600 font-semibold"
          t              = {t}
        />
        <Input
          id             = "confirm-password"
          name           = "confirmPassword"
          placeholder    = "Confirm Password"
          label          = "Confirm Password"
          type           = "password"
          labelClassName = "text-zinc-600 font-semibold"
          t              = {t}
        />
        <button
          type      = "submit"
          className = "bg-orange-600 border flex justify-center items-center gap-2 rounded-md border-orange-600 text-white py-2 px-3 w-full font-semibold"
        >
          {isSubmitting && <div className="w-6 loader"/>}
          <p>{t("Change Password")}</p>
        </button>
      </Form>
       )}
    </Formik>
  );
};

export default UpdatePassword;
