import Voucher from "@/components/forms/cart/Voucher";
import CloseIcon from "@/components/icons/CloseIcon";
import VoucherIcon from "@/components/icons/VoucherIcon";
import React from "react";
import { useTranslation } from "next-i18next";

const AddVoucher = ({ Context }) => {
  const { t } = useTranslation("cart");

  return (
    <div className="bg-white w-11/12 mx-auto sm:w-[640px] max-h-[95vh] overflow-y-auto p-5 rounded-md">
      <div className="flex items-center justify-between pb-5 border-b">
        <h2 className="flex items-center gap-2 font-bold sm:text-lg">
          <VoucherIcon width={24} height={25} />
          <span>{t("Add Voucher")}</span>
        </h2>
        <CloseIcon
          className="cursor-pointer size-5"
          onClick={() => Context?.dispatch({ type: "closeAll" })}
        />
      </div>
      <Voucher t={t} />
    </div>
  );
};

export default AddVoucher;
