import CartIcon from "@/components/icons/CartIcon";
import { useCart } from "@/context/cart";
import useClient from "@/hooks/global/useClient";
import CartPopUp from "./CartPopup";

const CartHeader = () => {
  const {
    cart
  } = useCart();
  const { isClient } = useClient();
  return (
    isClient && (
      <div className="border px-4 py-2.5 rounded-xl hidden lg:flex items-center justify-center cursor-pointer group">
        <div className="relative">
          <CartIcon />
          {cart?.cartItems?.length > 0 && (
            <p className="absolute -top-2 -right-2 bg-orange-600 text-white size-[16px] text-xs rounded-full flex items-center justify-center">
              {cart?.cartItems?.length}
            </p>
          )}
          <CartPopUp
            suppressHydrationWarning={true}
            {...{ cart }}
          />
        </div>
      </div>
    )
  );
};

export default CartHeader;
