import { useEffect, useRef } from "react";
import { deleteCookie, getCookie, getCookies, setCookie } from "cookies-next";
import { getSession, signOut, useSession } from "next-auth/react";
import { useCart } from "@/context/cart";
import { mergeGuestCart } from "@/utils/MergeGuestCart";
import store from "store";
import Header from "./header";
const Footer = dynamic(() => import("./footer"), {
  ssr: false,
});

const FixedBox = dynamic(() => import("./FixedBox"), {
  ssr: false,
});

import { useTranslation } from "next-i18next";
import { useCategoryTree } from "@/hooks/category/useCategoryTree";
import useSearchBox from "@/hooks/search/useSearchBox";
import Body from "@/components/category/Body";
import useClient from "@/hooks/global/useClient";
import Loading from "@/components/global/Loading";
import EmptyComp from "@/components/global/EmptyComp";
import dynamic from "next/dynamic";
import { useCheckout } from "@/context/checkout";
import { useRouter } from "next/router";
import { useGlobalData } from "@/context/global";
import {
  event_global_variables,
  event_payment_failed,
  event_purchase,
} from "@/utils/gtm";
import { getOrderByID } from "@/services/success";
import useGettingCities from "@/hooks/global/useGettingCities";
import Head from "next/head";
// import AcceptCookies from "./AcceptCookies";

const Layout = ({ children }) => {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation("common");
  const { dispatch: dispatchCart } = useCart();
  const {
    appData: { reg, allCities },
  } = useGlobalData();
  const { dispatch: dispatchCheckout } = useCheckout();
  const { data: session } = useSession();
  const { quote, bb: bb_reset, handshake } = getCookies();
  const ourSearchContainer = useRef(null);
  const ourSearchInput = useRef(null);
  const { isClient } = useClient();
  const { reload, pathname, asPath, query } = useRouter();
  const mage = getCookie("mage")
  // console.log('session?.user, session?.mage, mage', session?.user, session?.mage, mage)
  // console.log('session?.user && (!session?.mage || !mage)', session?.user && (!session?.mage || !mage))
  // merge guest cart to user cart
  useEffect(() => {
    const handleCheckAuth = async () => {
      const currSession = await getSession()
      if ((currSession?.user && (!currSession?.mage || !mage)) == true) {
        store.clearAll();
        deleteCookie("quote");
        deleteCookie("mage");
        dispatchCart({ type: "clear" });
        dispatchCheckout({ type: "clear" });
        signOut();
        // //debugger;
      }
    }
    handleCheckAuth()
    quote && quote !== "undefined"
      ? mergeGuestCart(session, dispatchCart)
      : deleteCookie("quote");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [-quote, mage]);

  useEffect(() => {
    const prepareData = () => {
      const pageTypes = {
        "/checkout": "Checkout",
        "/cart": "Cart",
        "/user": "Account",
        "/blog": "Blog",
        "/brands": "Brand Page",
        "/static/contact-us": "Contact Us",
        "/product/[rewrite_url]": "PDP",
        "/": "HP",
        "/[...category]":
          pathname == "/[...category]"
            ? asPath.split("/").length == 2
              ? "Category"
              : "PLP"
            : "",
      };

      const data = {
        session,
        store_language: locale,
        ...(session?.user?.id ? { user_id: session?.user?.id } : {}),
        ...(session?.user?.email ? { email: session?.user?.email } : {}),
        ...(session?.user?.custom_attributes?.find(
          (item) => item?.attribute_code == "mobilenumber"
        )
          ? {
              phone: session?.user?.custom_attributes?.find(
                (item) => item?.attribute_code == "mobilenumber"
              )?.value
                ? `+${
                    session?.user?.custom_attributes?.find(
                      (item) => item?.attribute_code == "mobilenumber"
                    )?.value
                  }`
                : null,
            }
          : {}),
        ...((session?.user?.city && session?.user?.city) ||
        (reg && reg?.id && allCities?.data && allCities?.data?.length)
          ? {
              city: allCities?.data
                ?.slice(1, allCities?.data?.length)
                ?.find((item) => item?.value == reg?.id)?.label,
            }
          : {}),
        page_type: pageTypes[pathname] ? pageTypes[pathname] : "Others",
      };

      console.log("data in useEffect///////////", data);
      event_global_variables(data);
    };

    session == null ||
      (Object.keys(session)?.length && setTimeout(() => prepareData(), 1000));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath, session?.user]); // Empty dependency array to ensure this effect runs only once

  useEffect(() => {
    if (bb_reset !== "bb_new") {
      setCookie("bb", "bb_new", { maxAge: 5 * 24 * 60 * 60 });
      console.log("bb", bb_reset);
      store.clearAll();
      deleteCookie("handshake");
      deleteCookie("mage");
      deleteCookie("quote");
      dispatchCart({ type: "clear" });
      dispatchCheckout({ type: "clear" });
      return reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useCategoryTree();
  useGettingCities();

  const {
    inputValue,
    setInputValue,
    loadingSearch,
    allProducts,
    handleSorting,
    totalProducts,
    selectedFilterKeys,
    setSelectedFilterKeys,
    handleFilter,
    handleResetFilter,
    allFilters,
    suggestedProducts,
  } = useSearchBox([ourSearchContainer, ourSearchInput], reg?.id);

  const eventPaymentFailedRef = useRef(false);

  useEffect(() => {
    if (
      !eventPaymentFailedRef.current &&
      (query?.success == "false" || query?.success == false) &&
      query?.reason &&
      query?.order_id
    ) {
      const handle_event_payment_failed = async (handshake, order_id) => {
        const res = await getOrderByID(handshake, order_id);

        const order = res?.data?.data;

        event_payment_failed(
          Array.isArray(order?.payment?.additional_information)
            ? order?.payment?.additional_information?.[0]
            : order?.payment?.additional_information,
          query?.reason
        );
      };
      handle_event_payment_failed(handshake, query?.order_id);
      eventPaymentFailedRef.current = true; // Mark as called after first execution
    }
  }, [handshake, query?.order_id, query?.reason, query?.success]);

  const eventEventPurchaseRef = useRef(false);

  useEffect(() => {
    if (
      !eventEventPurchaseRef.current &&
      (query?.success == "true" || query?.success == true) &&
      query?.order_id
    ) {
      const handle_event_purchase = async (handshake, order_id) => {
        const res = await getOrderByID(handshake, order_id);

        const order = res?.data?.data;

        event_purchase(
          order?.increment_id,
          order?.items,
          order?.base_tax_amount,
          order?.grand_total,
          order?.base_shipping_amount,
          order?.qty_ordered,
          order?.payment?.additional_information?.at(0),
          getCookie("coupon_code") || order?.coupon_code
        );
      };
      handle_event_purchase(handshake, query?.order_id);

      eventEventPurchaseRef.current = true; // Mark as called after first execution
    }
  }, [handshake, query?.order_id, query?.success]);

  const urlWithoutParams = asPath.includes("?")
    ? asPath?.slice(0, asPath.indexOf("?"))
    : asPath;

  return (
    <>
      <Head>
        <link
          rel="canonical"
          href={`${process.env.NEXT_PUBLIC_WEBSITE_URL}${
            locale === "ar" ? "" : "/en"
          }${urlWithoutParams === "/" ? "" : urlWithoutParams}`}
        />
      </Head>
      <main className="flex flex-col min-h-screen">
        <Header
          {...{
            inputValue,
            setInputValue,
            t,
            ourSearchInput,
          }}
        />
        <div >  {/*className="flex-grow"  min-h-[50vh] */}
          {isClient && (
            <div className="max-w-[1920px] mx-auto" ref={ourSearchContainer}>
              {inputValue ? (
                loadingSearch ? (
                  <Loading />
                ) : allProducts?.length ? (
                  <Body
                    {...{
                      setInputValue,
                      fromCategory: false,
                      language: locale,
                      t,
                      allProducts,
                      handleSorting,
                      totalProducts,
                      selectedFilterKeys,
                      setSelectedFilterKeys,
                      handleFilter,
                      handleResetFilter,
                      allFilters,
                      suggestedProducts,
                    }}
                  />
                ) : (
                  <EmptyComp
                    {...{
                      title: t("empty search results!"),
                      description: t("try another keyword!"),
                    }}
                  />
                )
              ) : (
                children
              )}
            </div>
          )}
        </div>
        <Footer />
        <FixedBox />
        {/* <AcceptCookies /> */}
      </main>
    </>
  );
};

export default Layout;
