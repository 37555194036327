export function getCategory(data, targetId) {
  for (const item of data) {
    // Check if the current item has the target value
    if (item.id === targetId) {
      return item;
    }

    // Check if the current item has children, then recursively search in children
    if (item.children_data && item.children_data.length > 0) {
      const result = getCategory(item.children_data, targetId);
      if (result) {
        return result;
      }
    }
  }
  // If the target value is not found in the current level or its children, return null
  return null;
}

export const modifyMegaMenuTreeData = (array) => {
  return array?.filter(
    (cat) =>
      cat.is_active &&
      cat.include_in_mega_menu !== null &&
      cat.include_in_menu === "1" &&
      cat.product_count > 0
  );
};

export const modifyMenuTreeData = (array) => {
  return (array || [])?.filter(
    (cat) => cat?.include_in_menu && cat?.product_count > 0 && cat?.is_active
  );
};
