import ProductCard from "@/components/cards/ProductCard";
import Arrow from "@/components/icons/Arrow";
import { Swiper, SwiperSlide } from "swiper/react";

const CartModalSwiper = ({ products }) => {
  return (
    <div className="relative">
      <Swiper
        spaceBetween={5}
        breakpoints={{
          320: { slidesPerView: 2 },
          421: { slidesPerView: 2.5 },
          641: { slidesPerView: 2 },
        }}
      >
        {products?.map((proudct, i) => (
          <SwiperSlide key={i}>
            <ProductCard {...{proudct, categoryName: "related products", categoryID: "related products"}} />
          </SwiperSlide>
        ))}
      </Swiper>
      {products?.length > 2 && (
        <>
          <button className="absolute z-50 flex items-center justify-center bg-white border rounded-full -left-3 top-1/2 size-8">
            <Arrow className="rotate-180" />
          </button>
          <button className="absolute z-50 flex items-center justify-center bg-white border rounded-full -right-3 top-1/2 size-8">
            <Arrow />
          </button>
        </>
      )}
    </div>
  );
};

export default CartModalSwiper;
