import { searchInfo } from '@/utils/header';
import { useEffect, useState } from 'react'

const useSearchMenu = (data) => {
  const [allData, setAllData] = useState([]);
  const [query, setQuery] = useState("");

  useEffect(() => setAllData(data || []), [data]);

  useEffect(() => {
    if (query !== "") {
      setAllData(
        searchInfo((data || []), query)
      );
    } else {
      setAllData(searchInfo((data || []), ""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return {setQuery, allData}
}

export default useSearchMenu