import { useCheckout } from "@/context/checkout";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { useRouter } from "next/router";
import { useEffect } from "react";

function Map({
  data = [],
  setMap,
  fromModal = false,
  branchInView = null,
  setBranchInView = () => {},
}) {
  const checkoutContext = useCheckout();
  const { asPath, locale } = useRouter();
  const inBranchesPage = asPath === "/branches";
  let storeInView =
    data?.find((s) => s?.id == checkoutContext?.checkout?.pickupStore?.id) ||
    branchInView ||
    data?.at(0);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_TOKEN,
  });

  useEffect(() => {
    fromModal && setBranchInView(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [locale, setBranchInView]);

  console.log("branchInView", branchInView)

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        width: "100%",
        height: "80vh",
        borderRadius: "16px",
      }}
      center={{
        lat: parseFloat(storeInView?.lat) || 24.6865814,
        lng: parseFloat(storeInView?.lng) || 46.66773,
      }}
      zoom={14}
      onLoad={(map) => setMap(map)}
    >
      {data?.map((info) => (
        <Marker
          key={info.id}
          position={{
            lat: parseFloat(info?.lat),
            lng: parseFloat(info?.lng),
          }}
          onClick={() => {
            inBranchesPage && setBranchInView(info);
          }}
          options={{
            zIndex: storeInView?.id == info.id ? 1 : 0,
          }}
        />
      ))}
      {branchInView && (
        <InfoWindow
          position={{
            lat: parseFloat(branchInView?.lat) + 0.0029,
            lng: parseFloat(branchInView?.lng),
          }}
          onCloseClick={() => setBranchInView(null)}
        >
          <div className="flex flex-col items-center justify-center gap-1 text-center max-w-60">
            <h2 className="text-lg font-bold text-orange-600 ">
              {branchInView.name}
            </h2>
            <p className="text-sm font-medium">{branchInView.address}</p>
            <p className="text-sm font-medium">
              {branchInView.short_description}
            </p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default Map;
