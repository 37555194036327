const Notfound = (props) => (
  <svg
    width={505}
    height={513}
    viewBox="0 0 505 513"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M458.3 89.1505C450.984 79.1121 388.344 -3.9006 286.81 0.14293C281.736 0.344935 182.712 5.58672 146.188 70.6585C119.169 118.794 147.774 161.502 114.813 194.298C86.0938 222.874 50.7988 203.96 33.15 227.068C6.2662 262.268 49.0793 357.335 114.859 410.353C182.781 465.097 312.723 505.26 413.719 432.22C527.109 350.218 525.958 181.973 458.3 89.1505Z"
      fill="#F7F7F8"
    />
    <mask
      id="mask0_137_5738"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={154}
      width={503}
      height={283}
    >
      <path d="M502.356 154.618H0V436.597H502.356V154.618Z" fill="white" />
    </mask>
    <g mask="url(#mask0_137_5738)">
      <mask
        id="mask1_137_5738"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={154}
        width={503}
        height={283}
      >
        <path
          d="M65.057 436.597H13.5731C-0.202946 422.286 -3.88794 399.481 4.34106 381.401C12.1851 364.167 29.0261 352.781 39.2301 336.83C61.0071 302.789 50.481 252.076 80.074 224.558C94.866 210.802 116.137 206.823 136.272 205.215C156.408 203.606 177.219 203.651 195.84 195.825C218.803 186.173 236.113 165.443 259.766 157.632C286.325 148.861 318.095 159.806 333.616 183.074C343.328 197.633 346.992 215.925 358.477 229.131C377.047 250.485 409.765 252.142 432.926 268.403C448.812 279.556 459.537 297.77 461.583 317.072C464.057 340.377 455.208 366.848 469.183 385.663C475.996 394.838 487.044 400.01 494.387 408.767C501.702 417.494 503.763 425.445 501.461 436.598H65.057V436.597Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_137_5738)">
        <path
          d="M-22.8604 435.34L-1.67136 115.388L522.735 150.117L501.546 470.07L-22.8604 435.34Z"
          fill="#FD6800"
        />
      </g>
    </g>
    <mask
      id="mask2_137_5738"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={2}
      y={182}
      width={195}
      height={79}
    >
      <path
        d="M85.4044 185.736C79.8334 188.726 75.0734 193.206 71.7344 198.576C66.3634 207.206 64.3234 218.405 56.1134 224.396C46.9534 231.086 34.1534 228.426 23.2144 231.416C10.7034 234.836 0.76339 247.735 2.66339 260.436L3.1234 260.376H194.583L196.703 260.306C196.573 257.636 195.883 255.086 193.743 253.656C191.964 252.466 189.673 252.446 187.654 251.736C183.443 250.226 180.953 245.906 179.433 241.696C177.913 237.496 176.933 232.956 174.193 229.416C168.363 221.896 157.123 221.746 147.753 223.396C144.893 223.906 141.933 224.506 139.143 223.736C136.113 222.905 133.734 220.576 131.844 218.076C123.363 206.886 120.933 190.086 108.254 184.066C105.397 182.713 102.295 182.109 99.1544 182.109C94.4214 182.109 89.5994 183.482 85.4044 185.736Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask2_137_5738)">
      <path
        d="M196.704 182.109H0.763672V260.436H196.704V182.109Z"
        fill="url(#paint0_linear_137_5738)"
      />
    </g>
    <mask
      id="mask3_137_5738"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={279}
      y={205}
      width={226}
      height={80}
    >
      <path
        d="M325.557 208.753C322.277 210.273 319.148 212.173 316.448 214.573C309.628 220.623 305.847 229.553 304.568 238.572C303.478 246.173 303.768 254.622 298.839 260.513C296.269 263.583 292.618 265.483 289.189 267.553C285.758 269.613 282.319 272.093 280.669 275.743C279.398 278.523 279.617 282.142 281.398 284.483H504.668C503.358 277.113 500.888 269.953 497.388 263.342C495.288 259.383 492.658 255.473 488.729 253.323C483.854 250.66 477.962 251.148 472.151 251.637C465.481 252.198 458.916 252.759 454.118 248.553C450.618 245.483 449.279 240.703 447.519 236.383C443.308 226.013 434.729 216.493 423.628 215.053C414.189 213.842 404.888 218.692 397.928 225.182C393.029 229.753 387.479 235.673 380.948 234.172C378.669 233.642 376.699 232.233 374.979 230.653C367.689 224.003 363.588 214.033 355.229 208.793C351.09 206.198 346.315 205.076 341.44 205.076C336.021 205.077 330.48 206.463 325.557 208.753Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask3_137_5738)">
      <path
        d="M274.263 284.149L280.344 190.51L509.802 205.41L503.722 299.049L274.263 284.149Z"
        fill="#FAF9F9"
      />
    </g>
    <mask
      id="mask4_137_5738"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={79}
      y={290}
      width={114}
      height={147}
    >
      <path
        d="M129.436 354.88C132.295 350.425 134.857 345.637 137.119 340.514H138.216C138.082 340.781 137.899 343.622 137.667 349.045C137.433 354.466 137.317 358.771 137.317 361.964V378.924H113.275L129.436 354.88ZM139.812 290.731L79.1543 379.423V408.355H137.317V436.589H176.226V408.355H192.289V378.924H176.226V290.731H139.812Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask4_137_5738)">
      <path
        d="M192.289 290.731H79.1543V436.589H192.289V290.731Z"
        fill="#99271F"
      />
    </g>
    <mask
      id="mask5_137_5738"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={68}
      y={290}
      width={114}
      height={147}
    >
      <path
        d="M118.811 354.889C121.669 350.434 124.231 345.645 126.493 340.523H127.589C127.456 340.789 127.274 343.632 127.041 349.053C126.807 354.475 126.692 358.78 126.692 361.972V378.933H102.649L118.811 354.889ZM129.186 290.74L68.5293 379.432V408.364H126.692V436.597H165.601V408.364H181.663V378.933H165.601V290.74H129.186Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask5_137_5738)">
      <path
        d="M68.5719 438.815L65.6699 290.796L181.62 288.522L184.522 436.54L68.5719 438.815Z"
        fill="url(#paint1_linear_137_5738)"
      />
    </g>
    <mask
      id="mask6_137_5738"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={329}
      y={296}
      width={118}
      height={141}
    >
      <path
        d="M372.43 373.252L374.329 368.762C376.288 363.843 377.89 358.653 379.14 353.193L380.22 352.992C380.14 353.273 380.499 356.103 381.3 361.472C382.1 366.832 382.801 371.082 383.409 374.222L386.629 390.871L376.199 392.892L363.028 395.433L372.43 373.252ZM372.33 303.813L329.62 402.403L335.11 430.813L392.22 419.762L395.47 436.592H435.1L430.42 412.382L435.76 411.353L446.19 409.332L440.6 380.433L430.169 382.453L424.829 383.483L408.091 296.893L372.33 303.813Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask6_137_5738)">
      <path
        d="M303.577 301.93L441.987 275.161L472.234 431.555L333.823 458.324L303.577 301.93Z"
        fill="#99271F"
      />
    </g>
    <mask
      id="mask7_137_5738"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={319}
      y={298}
      width={117}
      height={139}
    >
      <path
        d="M363.9 370.783C365.86 365.872 367.459 360.683 368.709 355.222L369.789 355.012C369.709 355.303 370.07 358.132 370.869 363.491C371.44 367.312 371.959 370.572 372.431 373.252C372.62 374.342 372.811 375.333 372.981 376.242L376.201 392.892L352.592 397.461L363.9 370.783ZM372.33 303.813L361.9 305.832L319.189 404.434L324.68 432.832L335.11 430.812L381.79 421.792L384.65 436.591H424.28L419.99 414.402L430.421 412.381L435.761 411.352L430.17 382.462L414.4 385.511L397.65 298.922L372.33 303.813Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask7_137_5738)">
      <path
        d="M319.234 438.876L316.491 298.976L435.715 296.638L438.459 436.54L319.234 438.876Z"
        fill="url(#paint2_linear_137_5738)"
      />
    </g>
    <mask
      id="mask8_137_5738"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={112}
      y={221}
      width={86}
      height={142}
    >
      <path
        d="M112.04 223.843C137.07 228.303 158.873 252.121 158.873 252.121L173.965 362.611L197.72 362.613L189.61 222.733L135.88 221.273L112.04 223.843Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask8_137_5738)">
      <path
        d="M77.8008 230.122L192.356 200.517L231.959 353.765L117.404 383.369L77.8008 230.122Z"
        fill="#99271F"
      />
    </g>
    <path
      d="M103.967 301.872C103.967 301.872 90.4689 321.627 96.4619 338.589L121.573 301.872L118.312 297.492L103.967 301.872Z"
      fill="#111023"
    />
    <path
      d="M173.965 362.612L173.156 367.714C173.156 367.714 166 368.716 165.6 378.934H184.212L194.324 369.062L195.268 362.613H173.965V362.612Z"
      fill="#111023"
    />
    <mask
      id="mask9_137_5738"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={87}
      y={223}
      width={72}
      height={81}
    >
      <path
        d="M112.03 223.843L103.16 224.803C92.2003 225.992 85.0903 236.963 88.4903 247.453L100.121 303.752L120.298 296.523L115.46 250.932L145.23 252.043L158.873 252.121C158.873 252.121 137.07 228.303 112.04 223.843H112.03Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask9_137_5738)">
      <path
        d="M158.873 223.843H85.0898V303.752H158.873V223.843Z"
        fill="#99271F"
      />
    </g>
    <mask
      id="mask10_137_5738"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={38}
      y={111}
      width={53}
      height={22}
    >
      <path
        d="M42.5161 122.074C42.5161 127.574 40.5592 132.1 38.0352 132.695L38.0531 132.767L76.9491 127.835L81.3391 127.276C81.2311 125.77 81.1412 122.588 82.3042 121.911C83.8462 121.018 85.3432 122.48 87.4982 124.337C87.9402 124.724 88.3811 125.436 88.7961 126.329L90.4102 126.122V124.796V118.422V117.367L38.1431 111.471C40.6131 112.174 42.5161 116.646 42.5161 122.074Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask10_137_5738)">
      <path
        d="M90.4102 111.471H38.0352V132.767H90.4102V111.471Z"
        fill="url(#paint3_linear_137_5738)"
      />
    </g>
    <mask
      id="mask11_137_5738"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={32}
      y={111}
      width={9}
      height={10}
    >
      <path
        d="M32.4717 120.748C33.0397 120.487 33.6797 120.36 34.3017 120.279C35.2387 120.153 36.1947 120.099 37.0697 119.756C37.8987 119.431 38.6297 118.864 39.1347 118.125C39.8287 117.133 40.0907 115.897 40.6487 114.815C40.7387 114.644 40.8377 114.482 40.9457 114.319C40.1977 112.805 39.2247 111.777 38.1427 111.471L37.3307 111.38C34.8157 111.516 32.7687 115.555 32.4717 120.748Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask11_137_5738)">
      <path
        d="M40.9457 111.38H32.4717V120.748H40.9457V111.38Z"
        fill="url(#paint4_linear_137_5738)"
      />
    </g>
    <mask
      id="mask12_137_5738"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={32}
      y={114}
      width={11}
      height={14}
    >
      <path
        d="M40.6488 114.815C40.0908 115.897 39.8288 117.133 39.1348 118.125C38.6298 118.864 37.8988 119.431 37.0698 119.756C36.1948 120.099 35.2388 120.153 34.3018 120.279C33.6798 120.36 33.0398 120.487 32.4718 120.748C32.4348 121.181 32.4258 121.623 32.4258 122.074C32.4258 124.246 32.7328 126.266 33.2558 127.952C33.5708 127.457 33.9948 127.042 34.5178 126.771C35.5448 126.257 36.7628 126.393 37.8898 126.14C39.1078 125.86 40.2158 125.103 40.9098 124.066C41.5058 123.183 41.8118 122.11 42.4528 121.253C42.4708 121.235 42.4788 121.217 42.4978 121.199C42.3978 118.494 41.8208 116.087 40.9458 114.319C40.8378 114.482 40.7388 114.644 40.6488 114.815Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask12_137_5738)">
      <path
        d="M42.4978 114.319H32.4258V127.952H42.4978V114.319Z"
        fill="url(#paint5_linear_137_5738)"
      />
    </g>
    <mask
      id="mask13_137_5738"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={33}
      y={121}
      width={10}
      height={12}
    >
      <path
        d="M42.4529 121.253C41.8119 122.11 41.5059 123.183 40.9099 124.066C40.2159 125.103 39.1079 125.86 37.8899 126.14C36.7629 126.393 35.5449 126.257 34.5179 126.771C33.9949 127.042 33.5709 127.457 33.2559 127.952C34.1579 130.855 35.7079 132.767 37.4659 132.767C37.6559 132.767 37.8539 132.74 38.0349 132.695C40.5589 132.1 42.5159 127.574 42.5159 122.074C42.5159 121.776 42.5059 121.488 42.4979 121.199C42.4789 121.217 42.4709 121.235 42.4529 121.253Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask13_137_5738)">
      <path
        d="M42.5159 121.199H33.2559V132.767H42.5159V121.199Z"
        fill="url(#paint6_linear_137_5738)"
      />
    </g>
    <path
      d="M121.209 119.459H136.933C135.292 120.117 133.895 120.865 133.264 121.46C132.768 121.92 132.984 122.759 133.597 123.832H121.209V119.459Z"
      fill="#111023"
    />
    <path
      d="M156.057 122.092V123.832H147.536C146.716 121.939 146.103 120.343 145.896 119.459H156.057V122.092Z"
      fill="#111023"
    />
    <mask
      id="mask14_137_5738"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={90}
      y={118}
      width={32}
      height={7}
    >
      <path
        d="M90.4102 118.422V124.796H121.209V123.832V119.459V118.422H90.4102Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask14_137_5738)">
      <path
        d="M121.209 118.422H90.4102V124.796H121.209V118.422Z"
        fill="#99271F"
      />
    </g>
    <mask
      id="mask15_137_5738"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={76}
      y={121}
      width={16}
      height={20}
    >
      <path
        d="M82.3044 121.911C81.1414 122.588 81.2313 125.77 81.3393 127.276L76.9493 127.835C76.6153 132.217 84.8474 140.845 84.8474 140.845L91.6274 135.688C91.6274 135.688 90.3823 129.728 88.7963 126.329C88.3813 125.436 87.9404 124.724 87.4984 124.337C85.7904 122.865 84.4954 121.642 83.2644 121.642C82.9424 121.642 82.6244 121.726 82.3044 121.911Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask15_137_5738)">
      <path
        d="M91.6272 121.642H76.6152V140.845H91.6272V121.642Z"
        fill="url(#paint7_linear_137_5738)"
      />
    </g>
    <path
      d="M46.0027 111.51C45.5497 110.518 44.0847 111.365 44.5337 112.348C47.3217 118.45 46.9637 125.162 43.6227 130.966C43.0797 131.908 44.5437 132.756 45.0867 131.811C48.7377 125.469 49.0397 118.157 46.0027 111.51Z"
      fill="#5B2B89"
    />
    <mask
      id="mask16_137_5738"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={160}
      y={141}
      width={7}
      height={7}
    >
      <path
        d="M166.759 147.725V142.496L160.502 141.81L166.759 147.725Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask16_137_5738)">
      <path
        d="M166.922 141.814L166.754 147.897L160.339 147.721L160.507 141.638L166.922 141.814Z"
        fill="url(#paint8_linear_137_5738)"
      />
    </g>
    <mask
      id="mask17_137_5738"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={144}
      y={137}
      width={63}
      height={44}
    >
      <path
        d="M154.345 138.285L147.474 143.091L144.39 145.245L170.564 180.751C170.564 180.751 194.476 174.954 199.904 173.935C205.331 172.926 205.168 166.686 206.061 161.051C206.89 155.821 204.006 150.367 199.29 149.699C198.939 149.645 198.578 149.618 198.199 149.627C192.853 149.708 179.076 159.356 179.076 159.356L173.062 153.675L166.759 147.725L160.502 141.81L158.753 140.16L155.732 137.311L154.345 138.285Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask17_137_5738)">
      <path
        d="M208.262 137.354L206.828 182.726L143.018 180.707L144.453 135.337L208.262 137.354Z"
        fill="#E6625A"
      />
    </g>
    <mask
      id="mask18_137_5738"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={173}
      y={143}
      width={27}
      height={17}
    >
      <path
        d="M173.062 153.675L179.076 159.356C179.076 159.356 192.853 149.708 198.199 149.627C198.578 149.618 198.939 149.645 199.29 149.699L183.656 143.587L173.062 153.675Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask18_137_5738)">
      <path
        d="M199.724 143.599L199.27 160.078L172.628 159.344L173.082 142.866L199.724 143.599Z"
        fill="url(#paint9_linear_137_5738)"
      />
    </g>
    <mask
      id="mask19_137_5738"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={81}
      y={135}
      width={119}
      height={90}
    >
      <path
        d="M84.8474 140.846L81.8984 143.091L104.025 171.672L139.278 167.777L142.199 191.363L135.879 221.271C152.099 227.375 178.039 224.417 186.694 223.182C188.543 222.921 189.607 222.731 189.607 222.731L190.823 198.658L199.904 173.935C194.476 174.954 170.564 180.751 170.564 180.751L144.39 145.245L147.474 143.091C147.474 143.091 146.446 142.667 145.255 141.81L112.536 156.11L91.6274 135.688L84.8474 140.846Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask19_137_5738)">
      <path
        d="M199.904 135.688H81.8984V227.375H199.904V135.688Z"
        fill="#E0483E"
      />
    </g>
    <mask
      id="mask20_137_5738"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={132}
      y={117}
      width={23}
      height={27}
    >
      <path
        d="M136.933 119.459C135.293 120.117 133.896 120.865 133.264 121.46C132.768 121.921 132.985 122.759 133.598 123.832C135.509 127.195 141.351 132.83 141.649 136.292C141.874 138.871 143.705 140.701 145.255 141.81C146.446 142.667 147.474 143.091 147.474 143.091L154.345 138.285C154.345 138.285 150.025 129.638 147.537 123.832C146.716 121.939 146.103 120.342 145.896 119.459C145.878 119.396 145.86 119.332 145.85 119.278C145.663 118.324 144.679 117.953 143.33 117.953C141.509 117.953 139.021 118.63 136.933 119.459Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask20_137_5738)">
      <path
        d="M154.346 117.953H132.769V143.091H154.346V117.953Z"
        fill="url(#paint10_linear_137_5738)"
      />
    </g>
    <mask
      id="mask21_137_5738"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={157}
      y={116}
      width={29}
      height={38}
    >
      <path
        d="M157.815 118.098L157.761 118.422C157.599 119.26 157.536 120.55 157.536 122.092C157.536 128.773 158.753 140.16 158.753 140.16L160.502 141.81L166.759 142.496L177.209 141.81L173.062 153.675L183.656 143.587L182.799 129.854C182.799 129.854 186.27 126.14 185.477 123.048C185.134 121.695 184.278 121.523 183.431 121.758C182.331 122.056 181.221 123.048 181.221 123.048C181.221 123.048 180.617 130.819 178.67 130.585C176.723 130.341 175.297 116.475 175.297 116.475C175.297 116.475 165.136 119.332 157.815 118.098Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask21_137_5738)">
      <path
        d="M186.27 116.475H157.536V153.675H186.27V116.475Z"
        fill="url(#paint11_linear_137_5738)"
      />
    </g>
    <path
      d="M183.512 111.678L183.431 121.759C182.331 122.056 181.222 123.048 181.222 123.048C181.222 123.048 180.618 130.82 178.67 130.585C176.723 130.342 175.298 116.475 175.298 116.475C175.298 116.475 165.137 119.333 157.816 118.098V118.089C155.516 117.71 153.506 116.917 152.172 115.51C146.573 109.614 148.52 106.268 156.544 108.09C164.569 109.911 181.204 100.255 183.512 111.678Z"
      fill="#111023"
    />
    <path
      d="M177.209 141.81L173.063 153.676L166.759 147.725V142.496L177.209 141.81Z"
      fill="#F79244"
    />
    <path
      d="M157.117 119.927C158.727 119.118 160.403 119.022 162.097 119.633C163.133 120.005 162.353 121.528 161.33 121.159C160.264 120.776 159.058 120.852 158.047 121.361C157.074 121.849 156.134 120.421 157.117 119.927Z"
      fill="#111023"
    />
    <path
      d="M173.897 123.917C173.95 124.923 173.443 125.768 172.765 125.803C172.087 125.838 171.495 125.051 171.442 124.045C171.39 123.039 171.897 122.195 172.575 122.159C173.252 122.124 173.844 122.911 173.897 123.917Z"
      fill="#111023"
    />
    <path
      d="M174.814 119.518C173.127 118.882 171.451 118.961 169.829 119.745C168.838 120.223 169.773 121.656 170.752 121.184C171.772 120.69 172.979 120.641 174.038 121.041C175.056 121.424 175.843 119.907 174.814 119.518Z"
      fill="#111023"
    />
    <path
      d="M162.949 129.623C163.495 127.039 164.831 124.408 165.734 121.925C165.922 121.408 166.683 121.798 166.497 122.309C165.928 123.874 165.359 125.44 164.79 127.007C164.593 127.549 164.113 128.447 164.058 129.02C163.872 130.97 164.764 130.818 165.774 131.288C166.274 131.52 165.888 132.283 165.39 132.051C164.149 131.475 162.574 131.396 162.949 129.623Z"
      fill="#F79244"
    />
    <path
      d="M162.006 124.196C161.207 123.552 160.299 123.129 159.276 122.998C158.647 122.918 158.385 123.884 159.024 123.966C159.54 124.032 160.028 124.18 160.485 124.4C159.952 124.573 159.419 124.747 158.885 124.92C158.275 125.118 158.53 126.085 159.144 125.886C160.023 125.6 160.901 125.315 161.779 125.03C162.1 124.926 162.319 124.448 162.006 124.196Z"
      fill="#111023"
    />
    <path
      d="M168.364 137.249C168.364 139.023 167.368 140.462 166.139 140.462C164.911 140.462 163.915 139.023 163.915 137.249C163.915 135.474 164.911 134.035 166.139 134.035C167.368 134.035 168.364 135.474 168.364 137.249Z"
      fill="white"
    />
    <g opacity={0.5}>
      <mask
        id="mask22_137_5738"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x={64}
        y={436}
        width={409}
        height={77}
      >
        <path
          d="M472.649 436.597H64.2871V512.318H472.649V436.597Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask22_137_5738)">
        <mask
          id="mask23_137_5738"
          style={{
            maskType: "luminance",
          }}
          maskUnits="userSpaceOnUse"
          x={64}
          y={436}
          width={409}
          height={77}
        >
          <path
            d="M472.649 436.597H71.3898C71.3898 436.597 49.7998 450.93 81.8978 453.358C113.997 455.785 164.063 458.077 162.828 468.998C161.593 479.92 132.604 477.763 132.604 497.043C132.604 516.324 266.305 511.875 266.305 511.875C266.305 511.875 341.833 512.863 352.59 500.1C363.345 487.336 322.141 475.651 329.619 470.617C337.098 465.584 391.39 480.684 399.479 468.46C407.568 456.236 397.861 448.505 412.783 446.887C427.705 445.268 470.132 451.118 472.649 436.597Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask23_137_5738)">
          <path
            d="M48.6445 516.307L49.8885 430.47L473.804 436.614L472.56 522.451L48.6445 516.307Z"
            fill="#FD6800"
          />
        </g>
      </g>
    </g>
    <mask
      id="mask24_137_5738"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={411}
      y={453}
      width={62}
      height={18}
    >
      <path
        d="M411.975 461.987C411.975 466.753 425.557 470.616 442.312 470.616C459.067 470.616 472.649 466.753 472.649 461.987C472.649 457.221 459.067 453.358 442.312 453.358C425.557 453.358 411.975 457.221 411.975 461.987Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask24_137_5738)">
      <path
        d="M472.649 453.358H411.975V470.616H472.649V453.358Z"
        fill="black"
      />
    </g>
    <mask
      id="mask25_137_5738"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={92}
      y={462}
      width={54}
      height={15}
    >
      <path
        d="M92.7842 469.448C92.7842 473.072 104.656 476.011 119.3 476.011C133.945 476.011 145.817 473.073 145.817 469.448C145.817 465.823 133.945 462.885 119.3 462.885C104.656 462.885 92.7842 465.823 92.7842 469.448Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask25_137_5738)">
      <path d="M145.817 462.885H92.7842V476.01H145.817V462.885Z" fill="black" />
    </g>
    <path
      d="M301.413 495.02C301.413 498.222 287.93 500.818 271.296 500.818C254.662 500.818 241.179 498.222 241.179 495.02C241.179 491.819 254.662 489.223 271.296 489.223C287.93 489.223 301.413 491.819 301.413 495.02Z"
      fill="#E9FDFF"
    />
    <mask
      id="mask26_137_5738"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={206}
      y={331}
      width={111}
      height={106}
    >
      <path
        d="M233.82 342.873C222.92 352.992 214.879 370.763 209.679 396.184C206.5 411.753 205.61 425.223 207.02 436.593H244.18C243.708 429.953 245.02 419.093 248.1 404.033C251.43 387.743 254.73 376.742 257.998 371.023C259.899 367.703 262.05 365.512 264.449 364.453C266.179 363.683 268.051 363.503 270.039 363.913C274.801 364.893 277.538 368.763 278.26 375.523C278.98 382.294 277.72 393.592 274.489 409.422C272.059 421.292 269.618 430.342 267.179 436.592H307.919C309.809 430.741 311.469 424.302 312.898 417.273C313.848 412.623 314.598 408.162 315.138 403.893C317.468 385.693 316.127 370.872 311.127 359.434C304.947 345.303 293.357 336.502 276.357 333.033C274.507 332.653 272.697 332.353 270.928 332.133C268.327 331.804 265.808 331.639 263.365 331.639C251.722 331.638 241.872 335.383 233.82 342.873Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask26_137_5738)">
      <path
        d="M210.095 309.693L338.06 335.846L312.985 458.538L185.02 432.383L210.095 309.693Z"
        fill="#99271F"
      />
    </g>
    <mask
      id="mask27_137_5738"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={195}
      y={329}
      width={111}
      height={108}
    >
      <path
        d="M223.4 340.752C212.51 350.883 204.46 368.652 199.27 394.063C198.21 399.233 197.41 404.183 196.86 408.893C195.65 419.243 195.66 428.483 196.9 436.592H234.01C233.96 436.353 233.93 436.113 233.9 435.862C233.12 429.393 234.38 418.073 237.68 401.913C241.01 385.623 244.31 374.623 247.59 368.912C250.86 363.193 254.868 360.823 259.629 361.803C261.579 362.203 263.19 363.083 264.449 364.453C266.3 366.433 267.42 369.413 267.849 373.402C268.569 380.173 267.309 391.472 264.078 407.302C261.358 420.582 258.628 430.342 255.889 436.592H296.819C298.979 430.192 300.869 423.052 302.489 415.161C307.479 390.722 306.889 371.442 300.72 357.312C295.168 344.621 285.239 336.222 270.93 332.131C269.319 331.671 267.659 331.262 265.939 330.911C261.39 329.98 257.062 329.515 252.958 329.515C241.312 329.517 231.46 333.262 223.4 340.752Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask27_137_5738)">
      <path
        d="M195.693 438.784L193.551 329.558L307.437 327.325L309.579 436.551L195.693 438.784Z"
        fill="url(#paint12_linear_137_5738)"
      />
    </g>
    <path
      d="M329.086 262.014L328.836 262.204L302.706 282.544L286.396 295.234L272.815 332.814L269.046 331.454L283.036 292.784L326.636 258.854L329.086 262.014Z"
      fill="#2A2A2A"
    />
    <mask
      id="mask28_137_5738"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={302}
      y={262}
      width={44}
      height={93}
    >
      <path
        d="M302.706 282.544C305.576 283.734 307.797 286.524 308.087 289.644C308.508 294.113 305.416 298.554 306.576 302.894C307.377 305.884 310.076 308.035 312.946 309.194C316.156 310.474 320.206 311.204 321.548 314.384C322.147 315.794 322.035 317.394 321.718 318.894C320.916 322.634 318.865 326.224 319.307 330.024C319.647 332.913 321.415 335.465 323.507 337.493C325.598 339.524 328.036 341.154 330.227 343.085C331.517 344.233 332.777 345.595 333.107 347.305C333.577 349.815 331.917 352.175 330.347 354.185L330.617 354.655C334.589 352.306 338.638 349.886 341.607 346.355C344.577 342.825 346.316 337.915 344.916 333.515C343.897 330.336 341.349 327.695 340.848 324.385C340.068 319.204 344.536 314.533 344.599 309.295C344.658 304.625 341.268 300.695 337.898 297.465C336.268 295.895 334.498 294.215 334.088 291.995C333.826 290.545 334.188 289.065 334.608 287.645C335.668 284.125 337.158 280.625 337.138 276.945C337.088 271.136 333.268 266.045 328.839 262.205L302.706 282.544Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask28_137_5738)">
      <path
        d="M346.315 262.204H302.706V354.654H346.315V262.204Z"
        fill="black"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_137_5738"
        x1={98.7451}
        y1={261.11}
        x2={98.7451}
        y2={218.504}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9E6D9" />
        <stop offset={0.1211} stopColor="#FCF1EA" />
        <stop offset={0.27} stopColor="#FEF9F6" />
        <stop offset={0.4754} stopColor="#FFFEFD" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_137_5738"
        x1={126.772}
        y1={446.221}
        x2={125.693}
        y2={391.21}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9E6D9" />
        <stop offset={0.1211} stopColor="#FCF1EA" />
        <stop offset={0.27} stopColor="#FEF9F6" />
        <stop offset={0.4754} stopColor="#FFFEFD" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_137_5738"
        x1={378.963}
        y1={441.249}
        x2={377.884}
        y2={386.238}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9E6D9" />
        <stop offset={0.1211} stopColor="#FCF1EA" />
        <stop offset={0.27} stopColor="#FEF9F6" />
        <stop offset={0.4754} stopColor="#FFFEFD" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_137_5738"
        x1={38.0628}
        y1={122.1}
        x2={90.4388}
        y2={122.1}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#51ADC6" />
        <stop offset={0.006} stopColor="#55AFC4" />
        <stop offset={0.0664} stopColor="#74BFB5" />
        <stop offset={0.1334} stopColor="#8FCDA7" />
        <stop offset={0.2104} stopColor="#A5D89D" />
        <stop offset={0.3005} stopColor="#B5E094" />
        <stop offset={0.4117} stopColor="#C1E78E" />
        <stop offset={0.5678} stopColor="#C8EA8B" />
        <stop offset={1} stopColor="#CAEB8A" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_137_5738"
        x1={40.9514}
        y1={116.072}
        x2={32.4763}
        y2={116.072}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8AEBD6" />
        <stop offset={0.4595} stopColor="#89E9D7" />
        <stop offset={0.6261} stopColor="#84E2DA" />
        <stop offset={0.745} stopColor="#7DD6DE" />
        <stop offset={0.8413} stopColor="#72C6E5" />
        <stop offset={0.9228} stopColor="#64B0EE" />
        <stop offset={0.9948} stopColor="#5295F9" />
        <stop offset={1} stopColor="#5193FA" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_137_5738"
        x1={42.4999}
        y1={121.112}
        x2={32.4237}
        y2={121.112}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#31D1D6" />
        <stop offset={0.3727} stopColor="#32CED8" />
        <stop offset={0.6172} stopColor="#36C6DF" />
        <stop offset={0.8241} stopColor="#3CB7EB" />
        <stop offset={1} stopColor="#45A3FA" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_137_5738"
        x1={42.5188}
        y1={126.982}
        x2={33.2591}
        y2={126.982}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8AEBD6" />
        <stop offset={0.4595} stopColor="#89E9D7" />
        <stop offset={0.6261} stopColor="#84E2DA" />
        <stop offset={0.745} stopColor="#7DD6DE" />
        <stop offset={0.8413} stopColor="#72C6E5" />
        <stop offset={0.9228} stopColor="#64B0EE" />
        <stop offset={0.9948} stopColor="#5295F9" />
        <stop offset={1} stopColor="#5193FA" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_137_5738"
        x1={76.9376}
        y1={131.261}
        x2={91.6259}
        y2={131.261}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F7BE86" />
        <stop offset={0.3288} stopColor="#F7BC83" />
        <stop offset={0.5444} stopColor="#F7B67B" />
        <stop offset={0.7275} stopColor="#F7AC6C" />
        <stop offset={0.8914} stopColor="#F79E57" />
        <stop offset={1} stopColor="#F79244" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_137_5738"
        x1={163.714}
        y1={142.482}
        x2={163.228}
        y2={160.149}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7B6ED6" />
        <stop offset={0.4803} stopColor="#796DD5" />
        <stop offset={0.6534} stopColor="#7268D0" />
        <stop offset={0.7768} stopColor="#6760C9" />
        <stop offset={0.8765} stopColor="#5655BE" />
        <stop offset={0.961} stopColor="#4047B0" />
        <stop offset={1} stopColor="#333EA8" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_137_5738"
        x1={186.415}
        y1={143.106}
        x2={185.929}
        y2={160.774}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7B6ED6" />
        <stop offset={0.4803} stopColor="#796DD5" />
        <stop offset={0.6534} stopColor="#7268D0" />
        <stop offset={0.7768} stopColor="#6760C9" />
        <stop offset={0.8765} stopColor="#5655BE" />
        <stop offset={0.961} stopColor="#4047B0" />
        <stop offset={1} stopColor="#333EA8" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_137_5738"
        x1={132.985}
        y1={130.498}
        x2={154.339}
        y2={130.498}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F7BE86" />
        <stop offset={0.3288} stopColor="#F7BC83" />
        <stop offset={0.5444} stopColor="#F7B67B" />
        <stop offset={0.7275} stopColor="#F7AC6C" />
        <stop offset={0.8914} stopColor="#F79E57" />
        <stop offset={1} stopColor="#F79244" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_137_5738"
        x1={157.54}
        y1={135.089}
        x2={185.6}
        y2={135.089}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F7BE86" />
        <stop offset={0.3288} stopColor="#F7BC83" />
        <stop offset={0.5444} stopColor="#F7B67B" />
        <stop offset={0.7275} stopColor="#F7AC6C" />
        <stop offset={0.8914} stopColor="#F79E57" />
        <stop offset={1} stopColor="#F79244" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_137_5738"
        x1={252.818}
        y1={443.743}
        x2={251.739}
        y2={388.731}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9E6D9" />
        <stop offset={0.1211} stopColor="#FCF1EA" />
        <stop offset={0.27} stopColor="#FEF9F6" />
        <stop offset={0.4754} stopColor="#FFFEFD" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
);
export default Notfound;
