import { emailValidation } from "@/utils/valitation/auth";
import { getCookie, setCookie } from "cookies-next";
import { forgetSendOtp } from "@/services/auth";
import { useTranslation } from "next-i18next";
import { Form, Formik } from "formik";
import { useRef } from "react";

import Input from "../Input";
import CloseIcon from "@/components/icons/CloseIcon";
import toast from "react-hot-toast";
import useOnClickOutside from "@/hooks/useOnClickOutside";
import { event_error } from "@/utils/gtm";

const Forgot = ({ Context }) => {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation("common");
  const handshake = getCookie("handshake");
  const ref = useRef();
  useOnClickOutside(ref, () => Context?.dispatch({ type: "forgot" }));
  const handleForgotApi = async (
    { email },
    { setSubmitting, setFieldError }
  ) => {
    setSubmitting(true);
    const res = await forgetSendOtp(handshake, email);
    if (res?.data?.data?.status) {
      setCookie("resetPassword", JSON.stringify({ email }));
      Context?.dispatch({ type: "forgot" });
      Context?.dispatch({ type: "otp" });
      toast.success(
        locale === "ar"
          ? "تم ارسال الرمز علي بريدك الالكتروني"
          : "Otp has beed send to your email address"
      );
    } else {
      
      event_error({
        error_message: res?.data?.data?.message,
        error_type: "Request Error",
        error_url: process.env.NEXT_PUBLIC_WEBSITE_URL
      })

      toast.error(res?.data?.data?.message);
      setFieldError("email", res?.data?.data?.message);
    }
    setSubmitting(false);
  };

  return (
    <section
      ref={ref}
      className="bg-white w-11/12 mx-auto sm:w-[640px] p-4 rounded-md"
    >
      <div className="flex items-center justify-between pb-2 border-b">
        <h2 className="sm:text-lg font-bold">{t("Forgot Password?")}</h2>
        <button onClick={() => Context?.dispatch({ type: "forgot" })}>
          <CloseIcon className="size-5" />
        </button>
      </div>
      <p className="max-sm:text-sm my-2 text-center">
        <span className="font-semibold text-orange-600">
          {t("Enter your email address")}{" "}
        </span>
        <span className="font-semibold text-zinc-500">
          {t("and we will send you a verification code to your email address.")}
        </span>
      </p>
      <Formik
        onSubmit={handleForgotApi}
        initialValues={{ email: "" }}
        validationSchema={emailValidation(t)}
      >
        {({ isSubmitting }) => (
          <Form className="grid grid-cols-1 gap-2">
            <Input
              name="email"
              id="email"
              type="email"
              placeholder={t("Email")}
              t={t}
            />
            <button
              disabled={isSubmitting}
              className="max-sm:text-sm w-full p-2 text-white bg-orange-600 rounded-md disabled:cursor-not-allowed disabled:opacity-75"
              type="submit"
            >
              {isSubmitting ? t("Loading...") : t("Send Email")}
            </button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default Forgot;
