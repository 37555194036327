import { Form, Formik } from "formik";

import Input from "../Input";

const Voucher = ({ t }) => {
  return (
    <Formik
      initialValues={{
        voucher: "",
        phone: "",
      }}
    >
      <Form className="mt-3 grid gap-3">
        <Input
          id="voucher"
          label={t("Enter code, to get the discount")}
          name="voucher"
          cols="col-span-1"
          placeholder={t("Voucher Code")}
          labelClassName="text-zinc-600 font-semibold text-[15px]"
          t={t}
        />
        <Input
          id="phone-number"
          label={t("Phone Number")}
          name="phone"
          cols="col-span-1"
          type="tel"
          placeholder=""
          labelClassName="text-zinc-600 font-semibold text-[15px]"
          t={t}
        />
        <button
          type="submit"
          className="col-span-full bg-orange-600 border rounded-md border-orange-600 text-white py-2 px-3 w-full font-semibold"
        >
          {t("Send Code")}
        </button>
      </Form>
    </Formik>
  );
};

export default Voucher;
