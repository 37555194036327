import LocaleSwitcher from "@/components/global/buttons/LocaleSwitcher";
import ProfileCircle from "@/components/icons/ProfileCircle";
import C_Service from "@/components/icons/C_Service";
import Lang from "@/components/icons/Lang";
import Link from "next/link";

import { useContext, useMemo } from "react";
import { useRouter } from "next/router";
import { ModalContext } from "@/pages/_app";
import { useSession } from "next-auth/react";
import Heart from "@/components/icons/Heart";
import Location from "@/components/icons/Location";
import { useWishlist } from "@/context/wishlist";
import TrackOrder from "@/components/icons/TrackOrder";

const Menu = ({ setIsChangeLang, t }) => {
  const Context = useContext(ModalContext);
  const { push } = useRouter();
  const { data } = useSession();
  const { wishlist } = useWishlist();
  const MenuData = useMemo(
    () => [
      {
        icon: <Heart className="size-6" />,
        name: t("Wishlist"),
        url: "/user/wishlist",
      },
      // {
      //   icon: <TrackOrder className="size-6" />,
      //   name: t("Track Order"),
      //   // url: "/user/orders",
      // },
      // {
      //   icon: <BBService className="size-5" />,
      //   name: t("BlackBox Service"),
      // },
      // {
      //   icon: <Help className="size-5" />,
      //   name: t("Help"),
      // },
      {
        icon: <Location className="size-5" />,
        name: t("Our Branches"),
        url: "/branches",
      },
    ],
    [t]
  );

  return (
    <header className="py-3 border-b bg-zinc-100">
      <div className="items-center justify-between block w-11/12 mx-auto lg:flex">
        <div className="items-center hidden lg:flex gap-7">
          <div
            onClick={() =>
              !data ? Context?.dispatch({ type: "auth" }) : push("/user")
            }
            className={`flex items-center gap-2 ${
              !!data && data?.user ? "text-orange-600" : "text-black"
            }`}
          >
            <ProfileCircle className="size-6" />
            <p className="font-semibold cursor-pointer">
              {!data
                ? t("Login / Register")
                : `${t("Welcome ,")} ${data?.user?.firstname} ${
                    data?.user?.lastname
                  }`}
            </p>
          </div>
          {false && (
            <div
              onClick={() => Context?.dispatch({ type: "trackOrder" })}
              className={`flex items-center gap-2 ${
                !!data && data?.user ? "text-orange-600" : "text-black"
              }`}
            >
              <TrackOrder className="size-6" />
              <p className="font-semibold cursor-pointer">{t("Track Order")}</p>
            </div>
          )}
          {MenuData.map((item, i) => (
            <div key={i} className="flex items-center gap-2 cursor-pointer">
              {i !== 2 || data?.user ? (
                <>
                  {item?.url == "/user/wishlist" ? (
                    <Link
                      href={item?.url ? item?.url : "/"}
                      className="relative flex gap-1"
                    >
                      {item.icon}
                      {/* <Heart /> */}
                      {Object.keys(wishlist)?.length > 0 && (
                        <span className="absolute z-40 grid text-sm text-white bg-red-600 rounded-full -right-2 -top-2 size-5 place-items-center">
                          {Object.keys(wishlist)?.length}
                        </span>
                      )}
                      {item.name}
                    </Link>
                  ) : (
                    <>
                      {item.icon}
                      <Link
                        href={item?.url ? item?.url : "/"}
                        className="font-semibold "
                      >
                        {item.name}
                      </Link>
                    </>
                  )}
                </>
              ) : (
                <span
                  className="font-semibold"
                  // onClick={() => Context?.dispatch({ type: "auth" })}
                  onClick={() => push(item?.url)}
                >
                  {item.name}
                </span>
              )}
            </div>
          ))}
        </div>
        <div className="flex items-center justify-between gap-5 max-sm:text-sm lg:justify-normal">
          <div className="flex items-center gap-2">
            <C_Service />
            <div className="flex flex-col sm:text-[15px]">
              <p className="font-bold text-orange-600">8003022200</p>
              <p className="-mt-1">24 / 7 {t("Customer Services")}</p>
            </div>
          </div>
          <div className="flex items-center gap-1.5">
            <p className="font-semibold">
              <LocaleSwitcher setIsChangeLang={setIsChangeLang} />
            </p>
            <Lang className="size-5" />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Menu;
