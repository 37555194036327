// components
import ProductsList from "@/components/category/ProductsList";
// import BreadCrumb from "@/components/global/BreadCrumb";
import SideMenu from "@/components/category/SideMenu";
import SortByIcon from "@/components/icons/SortBy";
import FilterIcon from "@/components/icons/Filter";
import Category from "@/components/home/Category";
import Filter from "@/components/modals/category/Filter";

// constants
import { sortKeys } from "@/constants/Category";

// services
import SortBy from "@/components/modals/category/SortBy";
import { useContext, useEffect, useMemo, useState } from "react";
import CompareContext from "@/context/compare/compare-context";
import { useGlobalData } from "@/context/global";
import { findObjectByIdV2 } from "@/utils/global";
import BreadCrumb from "./CategoryBreadcrumb";
import Seo from "../seo";
import BannersGrid from "./CategoryBannersGrid";
import { event_view_item_list } from "@/utils/gtm";

const Body = ({
  language,
  t,
  setInputValue,
  allProducts,
  handleSorting,
  totalProducts,
  selectedFilterKeys,
  setSelectedFilterKeys,
  handleFilter,
  handleResetFilter,
  allFilters,
  fromCategory = true,
  categoryID = null,
  suggestedProducts,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const compareCtx = useContext(CompareContext);

  const {
    appData: { categoryData },
  } = useGlobalData();

  const currentCategory = useMemo(() => {
    return categoryID
      ? findObjectByIdV2(categoryData?.children_data, "id", categoryID)
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryData?.children_data, categoryID]);
  console.log("categoryData", { categoryData, currentCategory, categoryID });

  const suggestedWords = useMemo(() => {
    const words = allProducts
      ?.slice(0, 5)
      ?.map((item) => item?.suggest)
      ?.flat(1)
      ?.filter((item) => item?.length >= 3)
      ?.slice(0, 15);

    return [...new Set(words || [])];
  }, [allProducts]);
  
  useEffect(() => {
    event_view_item_list(allProducts, fromCategory? false: "search results");
  }, [allProducts, fromCategory]);

  return (
    <>
      <Seo
        title={currentCategory?.meta_title || currentCategory?.name}
        description={currentCategory?.meta_description || currentCategory?.name}
        keywords={currentCategory?.meta_keywords}
        image={
          currentCategory?.image?.startsWith("http")
            ? currentCategory?.image
            : `${process.env.NEXT_PUBLIC_IMG_BASEURL}/${currentCategory?.image}`
        }
      />
      <BreadCrumb current={!fromCategory ? t("Search") : null} />
      {currentCategory?.category_banners?.length > 0 && (
        <BannersGrid
          {...{ data: currentCategory?.category_banners, language }}
        />
      )}
      {currentCategory?.category_header && (
        <p
          className="w-11/12 mx-auto p-4 my-4"
          dangerouslySetInnerHTML={{ __html: currentCategory?.category_header }}
        />
      )}
      {currentCategory?.show_sub_categories_slider && (
        <Category
          language={language}
          fromCategory
          data={currentCategory?.children_data?.filter(
            (cat) => cat?.include_in_menu
          )}
        />
      )}
      <section className="grid w-11/12 grid-cols-12 gap-5 mx-auto my-7">
        <SideMenu
          {...{
            filterList: allFilters,
            t,
            selectedFilterKeys,
            handleFilter,
            handleResetFilter,
            suggestedProducts,
            suggestedWords: fromCategory ? [] : suggestedWords,
            setInputValue,
          }}
        />
        <ProductsList
          {...{
            allProducts,
            totalProducts,
            sortKeys,
            handleSorting,
            t,
            fromCategory,
            categoryName: currentCategory ? currentCategory?.name : "search results",
            categoryID: currentCategory ? categoryID : "search results",
          }}
        />
      </section>
      <div
        className={`fixed z-50 grid w-11/12 grid-cols-2 gap-2 -translate-x-1/2 max-sm:text-sm ${
          compareCtx?.compareProducts.length === 0 ? "bottom-20" : "bottom-40"
        } left-1/2 lg:hidden sm:gap-5`}
      >
        <button
          onClick={() => setShowFilter((prev) => !prev)}
          className="flex items-center justify-center gap-2 p-2 text-white bg-black rounded-md font-bold"
        >
          <FilterIcon />
          <span>{t("Filter")}</span>
        </button>
        <button
          onClick={() => setShowSort((prev) => !prev)}
          className="flex items-center justify-center gap-2 p-2 text-white bg-black rounded-md font-bold"
        >
          <SortByIcon />
          <span>{t("Sort By")}</span>
        </button>
      </div>
      {showFilter ? (
        <div className="fixed top-0 left-0 flex items-center justify-center w-screen h-screen bg-black bg-opacity-60 z-modal">
          <Filter
            {...{
              setShowFilter,
              filterList: allFilters,
              selectedFilterKeys,
              handleFilter,
              handleResetFilter,
              setSelectedFilterKeys,
              t,
              language,
            }}
          />
        </div>
      ) : null}
      {showSort ? (
        <div className="fixed top-0 left-0 flex items-center justify-center w-screen h-screen bg-black bg-opacity-60 z-modal">
          <SortBy {...{ setShowSort, handleSorting, t }} />
        </div>
      ) : null}
      {currentCategory?.category_footer && (
        <p
          className="w-11/12 mx-auto p-4 my-4"
          dangerouslySetInnerHTML={{ __html: currentCategory?.category_footer }}
        />
      )}
    </>
  );
};

export default Body;
