import Link from "next/link";
import Brands from "./filters/Brands";

const SideMenu = ({
  filterList,
  selectedFilterKeys,
  handleFilter,
  handleResetFilter,
  suggestedProducts,
  suggestedWords,
  setInputValue,
  // fromSearch = null,
  t,
}) => {
  return (
    <div className="hidden lg:block lg:col-span-3 *:mb-5">
      {suggestedWords?.length > 0 && (
        <>
          <div className="font-semibold sm:text-lg">
            {t("recommondedWords")}
          </div>
          <div className="flex flex-wrap gap-2 p-2 rounded-md border">
            {suggestedWords?.map((word, key) => (
              <button
                key={key}
                onClick={() => setInputValue(word)}
                className="text-sm border px-2 py-1 rounded-md hover:bg-slate-200"
              >
                {word}
              </button>
            ))}
          </div>
        </>
      )}
      {suggestedProducts?.length &&
        suggestedProducts?.at(0)?.options?.length > 1 && (
          <>
            <div className="font-semibold sm:text-lg">
              {t("recommondedProducts")}
            </div>
            <div className="flex flex-col gap-2 p-2 rounded-md border">
              {(suggestedProducts?.at(0)?.options || [])?.map(
                ({ _source: item }) => (
                  <Link
                    className="border-b pb-2 last:border-none hover:text-slate-700"
                    key={item.entity_id}
                    href={`/product/${item?.rewrite_url}`}
                  >
                    {item?.name || ""}
                  </Link>
                )
              )}
            </div>
          </>
        )}
      <button
        disabled={
          // minMaxPrice == "" && activeSortedKey == "Recommended"  && filterSettings == ""
          selectedFilterKeys?.length == 0
        }
        onClick={handleResetFilter}
        className={`bg-primary text-white font-bold py-2 px-4 rounded mb-3 ${
          // minMaxPrice == "" && activeSortedKey == "Recommended"  && filterSettings == "" ? "cursor-not-allowed opacity-50" : null
          selectedFilterKeys?.length == 0
            ? "cursor-not-allowed opacity-50"
            : null
        }`}
      >
        {t("Reset Filter")}
      </button>
      {filterList?.map((filterSection, key) => {
        const title = filterSection?.label || "";
        const options = filterSection?.filterable_values || [];
        const code = filterSection?.filter_key || "";
        return options?.length ? (
          <div key={key} className="p-3 border rounded-md">
            <div className="mb-2 font-semibold sm:text-lg">{title}</div>
            <Brands
              {...{ options, selectedFilterKeys, code, handleFilter, t }}
            />
          </div>
        ) : null;
      })}
      {/* <div className="p-3 border rounded-md">
        <div className="mb-2 text-xl font-bold">Prices</div>
        <Prices />
      </div>
      <div className="p-3 border rounded-md">
        <div className="mb-2 text-xl font-bold">Colors</div>
        <Colors />
      </div>
      <div className="p-3 border rounded-md">
        <div className="mb-2 text-xl font-bold">Ratings</div>
        <Rating />
      </div> */}
      {/* <div className="relative w-full overflow-hidden h-96">
        <Image
          loading="lazy"
          src="/imgs/category/summer-special.png"
          className="object-cover rounded-md"
          fill={true}
          alt=""
        />
      </div> */}
    </div>
  );
};

export default SideMenu;
