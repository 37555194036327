import { useEffect, useRef, useState } from "react";
import { useTranslation } from "next-i18next";
import { useBodyScrollLock } from "@/hooks/global/useBodyScrollLock";
import useOnClickOutside from "@/hooks/useOnClickOutside";
import { useGlobalData } from "@/context/global";
import CloseIcon from "../icons/CloseIcon";

const SelectCity = ({ Context }) => {
  const {
    appData: { allCities },
    dispatch,
  } = useGlobalData();

  const cities = allCities?.data?.filter((reg) => reg?.label && reg?.value);
  const [filteredCities, setFilteredCities] = useState(cities || []);
  const {
    t,
    i18n: { language: lang },
  } = useTranslation("common");
  const modalRef = useRef();
  const btnRef = Context?.state?.payload?.ref;
  const SearchRef = Context?.state?.payload?.SearchRef;

  useBodyScrollLock();
  useOnClickOutside(modalRef, () => Context?.dispatch({ type: "select_city" }));

  useEffect(() => {
    if (Context.state.select_city) {
      modalRef.current?.focus();
    }
  }, [Context.state.select_city]);

  const handleCitySearch = (e) => {
    const searchWord = e.target.value.toLowerCase();
    const results = cities?.filter((reg) =>
      reg?.label?.toLowerCase().includes(searchWord)
    );
    setFilteredCities(searchWord.length ? results : cities);
  };

  const handleCitySelect = (item) => {
    Context.dispatch({ type: "select_city" });
    dispatch({
      type: "set_region",
      payload: { id: item.value, name: item.label },
    });

    // Ensure the button click happens after state update
    setTimeout(() => {
      if (btnRef?.current) {
        btnRef.current.click();
      }
      if (SearchRef?.current) {
        SearchRef?.current?.focus();
      }
    }, 0);
  };

  if (!Context.state.select_city) return null;

  return (
    <section
      ref={modalRef}
      className="w-11/12 sm:w-[500px] bg-white rounded-md"
    >
      <div className="flex items-center justify-between gap-3 p-3 border-b sm:p-4">
        <div className="font-semibold md:text-xl md:font-bold">
          {lang === "ar" ? "اختيار مدينة" : "Select city"}
        </div>
        <button onClick={() => Context.dispatch({ type: "select_city" })}>
          <CloseIcon className="size-5" stroke="#005471" strokeWidth="2" />
        </button>
      </div>
      <div className="w-full min-w-52 border shadow-lg rounded-md bg-white p-2 z-50 text-[15px] text-black">
        <input
          type="text"
          placeholder={t("Search...")}
          className="w-full px-2 py-1.5 bg-gray-50 border border-gray-200 rounded-md focus-visible:outline-none"
          onChange={handleCitySearch}
        />
        <ul className="my-1 overflow-y-auto max-h-80 orange-scrollbar">
          {filteredCities?.length > 0 ? (
            filteredCities.map((item, key) => (
              <li
                key={key}
                className={`hover:text-secondary-700 border-b p-2 cursor-pointer hover:bg-gray-300 w-full ${
                  filteredCities.length - 1 === key ? "mb-0" : "mb-1.5"
                }`}
                onClick={() => handleCitySelect(item)}
              >
                {item.label || item.title}
              </li>
            ))
          ) : (
            <div className="">
              {lang === "ar"
                ? "الرجاء إعادة تحميل الصفحة لاظهار المدن"
                : "Please reload the page to show cities"}
            </div>
          )}
        </ul>
      </div>
    </section>
  );
};

export default SelectCity;
