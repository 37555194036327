import useClient from "@/hooks/global/useClient";

import { useEffect, useRef, useState } from "react";
import { useGlobalData } from "@/context/global";
import { useRouter } from "next/router";
import useOnClickOutsideV2 from "@/hooks/global/useOnClickOutsideV2";

const LocationsMenu = ({ t, show, setShow }) => {
  const {
    appData: { allCities },
    dispatch,
  } = useGlobalData();
  const { isClient } = useClient();
  const { locale } = useRouter();
  const ref = useRef();

  const [cityQuery, setCityQuery] = useState("");
  const [citySearchPool, setCitySearchPool] = useState([]);

  useOnClickOutsideV2([ref], () => setShow(false));

  useEffect(() => {
    const cities = allCities?.data?.slice(1, allCities?.data?.length);
    console.log('cities', cities)
    if (cityQuery) {
      setCitySearchPool(
        cities?.filter((city) =>
          city?.label?.toLowerCase()?.includes(cityQuery?.toLowerCase())
        )
      );
    } else {
      setCitySearchPool(cities);
    }
  }, [cityQuery, allCities, locale]);
  return (
    isClient && (
      <div
        ref={ref}
        className={`${
          !show ? "hidden" : "block"
        }  absolute top-full left-0 w-full min-w-52 border shadow-lg rounded-md bg-white p-2 z-50 *:text-[15px] *:text-black`}
      >
        <input
          type="text"
          placeholder={t("Search...")}
          className="w-full px-2 py-1.5 bg-gray-50 border border-gray-200 rounded-md focus-visible:outline-none"
          value={cityQuery}
          onChange={(e) => setCityQuery(e.target.value)}
        />
        <ul className="my-1 overflow-y-auto max-h-80 orange-scrollbar">
          {citySearchPool?.map((item, key) => (
            <li
              key={key}
              onClick={() => {
                setShow(!show);
                dispatch({
                  type: "set_region",
                  payload: { id: item.value, name: item?.label },
                });
                setCityQuery("")
              }}
              className={`hover:text-secondary-700 text-xs border-b p-2 *:cursor-pointer hover:text-primary w-full ${
                allCities?.data?.length - 1 == key ? "mb-0" : "mb-1.5"
              } `}
            >
              {item?.label ? item?.label : item?.title}
            </li>
          ))}
        </ul>
      </div>
    )
  );
};

export default LocationsMenu;
