const RemoveIcon = (props) => (
  <svg
    width={25}
    height={22}
    viewBox="0 0 25 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.69955 6.16894C3.71955 6.16894 3.74955 6.16894 3.77955 6.16894C9.06955 5.68311 14.3495 5.49977 19.5795 5.98561L21.6195 6.16894C22.0395 6.20561 22.4095 5.93061 22.4495 5.54561C22.4895 5.16061 22.1895 4.83061 21.7795 4.79394L19.7395 4.61061C14.4195 4.11561 9.02955 4.30811 3.62955 4.79394C3.21955 4.83061 2.91955 5.16977 2.95955 5.54561C2.98955 5.90311 3.31955 6.16894 3.69955 6.16894Z"
      fill="currentColor"
    />
    <path
      d="M16.1995 5.2435C16.2395 5.2435 16.2795 5.2435 16.3295 5.23433C16.7295 5.17016 17.0095 4.81266 16.9395 4.446L16.7195 3.24516C16.5595 2.36516 16.3395 1.146 14.0095 1.146H11.3895C9.04945 1.146 8.82945 2.411 8.67945 3.25433L8.45945 4.446C8.38945 4.82183 8.66945 5.17933 9.06945 5.23433C9.47945 5.2985 9.86945 5.04183 9.92945 4.67516L10.1495 3.4835C10.2895 2.686 10.3195 2.53016 11.3795 2.53016H13.9995C15.0595 2.53016 15.0795 2.6585 15.2295 3.47433L15.4595 4.666C15.5195 5.00516 15.8395 5.2435 16.1995 5.2435Z"
      fill="currentColor"
    />
    <path
      d="M9.48883 20.8541H15.9088C19.3988 20.8541 19.5388 19.0849 19.6488 17.6549L20.2988 8.42411C20.3288 8.04828 20.0088 7.71828 19.5988 7.69078C19.1788 7.67245 18.8288 7.95661 18.7988 8.33245L18.1488 17.5633C18.0388 18.9566 17.9988 19.4791 15.9088 19.4791H9.48883C7.38883 19.4791 7.34883 18.9566 7.24883 17.5633L6.59883 8.33245C6.56883 7.95661 6.20883 7.67245 5.79883 7.69078C5.38883 7.71828 5.06883 8.03911 5.09883 8.42411L5.74883 17.6549C5.85883 19.0849 5.99883 20.8541 9.48883 20.8541Z"
      fill="currentColor"
    />
    <path
      d="M11.0391 15.8125H14.3691C14.7791 15.8125 15.1191 15.5008 15.1191 15.125C15.1191 14.7492 14.7791 14.4375 14.3691 14.4375H11.0391C10.6291 14.4375 10.2891 14.7492 10.2891 15.125C10.2891 15.5008 10.6291 15.8125 11.0391 15.8125Z"
      fill="currentColor"
    />
    <path
      d="M10.1992 12.146H15.1992C15.6092 12.146 15.9492 11.8343 15.9492 11.4585C15.9492 11.0827 15.6092 10.771 15.1992 10.771H10.1992C9.78922 10.771 9.44922 11.0827 9.44922 11.4585C9.44922 11.8343 9.78922 12.146 10.1992 12.146Z"
      fill="currentColor"
    />
  </svg>
);
export default RemoveIcon;
