import React from "react";
const SarIcon = (props) => (
  <svg
    viewBox="0 0 1182 1182" width="1182" height="1182"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path transform="translate(557,139)" d="m0 0h2v442l27-6 74-15h3v-301l6-8 9-10 8-8 11-9 13-11 18-13 18-12 5-2 1 354 243-51h3v26l-3 22-5 19-6 16-8 15-5 6-216 45h-4v98l97-20 132-27 14-3h6v19l-2 21-4 19-6 18-8 16-6 10-32 7-268 55-10 1-2-194-101 20h-2v130l-8 16-9 16-13 19-14 19-14 18-12 16-4 6-11 4-286 60h-6v-20l3-21 7-24 9-21 9-16 27-6 234-49 1-127-243 51h-2v-26l4-22 8-24 12-25 4-6 36-8 180-38 1-388 8-10 11-12 15-14 14-11 16-12 18-12z" fill="currentColor"/>
    <path transform="translate(994,881)" d="m0 0h6v19l-3 21-5 20-7 20-11 23-38 8-267 55h-5v-12l4-24 7-25 9-24 9-20 88-18 149-30z" fill="currentColor"/>
  </svg>
);
export default SarIcon;
