const SearchIcon = (props) => (
  <svg
    width={18}
    height={16}
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.85563 14.5002C4.76769 14.5002 1.43945 11.4335 1.43945 7.66683C1.43945 3.90016 4.76769 0.833496 8.85563 0.833496C12.9436 0.833496 16.2718 3.90016 16.2718 7.66683C16.2718 11.4335 12.9436 14.5002 8.85563 14.5002ZM8.85563 1.8335C5.36098 1.8335 2.52475 4.4535 2.52475 7.66683C2.52475 10.8802 5.36098 13.5002 8.85563 13.5002C12.3503 13.5002 15.1865 10.8802 15.1865 7.66683C15.1865 4.4535 12.3503 1.8335 8.85563 1.8335Z"
      fill="currentColor"
    />
    <path
      d="M16.4527 15.1666C16.3153 15.1666 16.1778 15.12 16.0693 15.02L14.6222 13.6866C14.4124 13.4933 14.4124 13.1733 14.6222 12.98C14.832 12.7866 15.1793 12.7866 15.3892 12.98L16.8362 14.3133C17.046 14.5066 17.046 14.8266 16.8362 15.02C16.7277 15.12 16.5902 15.1666 16.4527 15.1666Z"
      fill="currentColor"
    />
  </svg>
);
export default SearchIcon;
