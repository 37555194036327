const FilterIcon = (props) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.25 3.0625H8.16669C7.92752 3.0625 7.72919 2.86417 7.72919 2.625C7.72919 2.38583 7.92752 2.1875 8.16669 2.1875H12.25C12.4892 2.1875 12.6875 2.38583 12.6875 2.625C12.6875 2.86417 12.4892 3.0625 12.25 3.0625Z"
      fill="currentColor"
    />
    <path
      d="M12.25 5.97925H8.16669C7.92752 5.97925 7.72919 5.78091 7.72919 5.54175C7.72919 5.30258 7.92752 5.10425 8.16669 5.10425H12.25C12.4892 5.10425 12.6875 5.30258 12.6875 5.54175C12.6875 5.78091 12.4892 5.97925 12.25 5.97925Z"
      fill="currentColor"
    />
    <path
      d="M12.25 8.89575H1.75C1.51083 8.89575 1.3125 8.69742 1.3125 8.45825C1.3125 8.21909 1.51083 8.02075 1.75 8.02075H12.25C12.4892 8.02075 12.6875 8.21909 12.6875 8.45825C12.6875 8.69742 12.4892 8.89575 12.25 8.89575Z"
      fill="currentColor"
    />
    <path
      d="M12.25 11.8125H1.75C1.51083 11.8125 1.3125 11.6142 1.3125 11.375C1.3125 11.1358 1.51083 10.9375 1.75 10.9375H12.25C12.4892 10.9375 12.6875 11.1358 12.6875 11.375C12.6875 11.6142 12.4892 11.8125 12.25 11.8125Z"
      fill="currentColor"
    />
    <path
      d="M4.62002 6.27075H2.96335C2.06502 6.27075 1.60419 5.81575 1.60419 4.91159V3.25492C1.60419 2.35659 2.05919 1.89575 2.96335 1.89575H4.62585C5.52419 1.89575 5.98502 2.35075 5.98502 3.25492V4.91742C5.97919 5.81575 5.52419 6.27075 4.62002 6.27075ZM2.96335 2.77075C2.54919 2.77075 2.47919 2.84075 2.47919 3.25492V4.91742C2.47919 5.33159 2.54919 5.40159 2.96335 5.40159H4.62585C5.04002 5.40159 5.11002 5.33159 5.11002 4.91742V3.25492C5.11002 2.84075 5.04002 2.77075 4.62585 2.77075H2.96335Z"
      fill="currentColor"
    />
  </svg>
);
export default FilterIcon;
