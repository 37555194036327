import axios from "axios";
import * as Sentry from "@sentry/react";

const axiosRequest = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASEURL,
  maxBodyLength: Infinity,
  headers: {
    "Content-Type": "application/json",
  },
});
axiosRequest.interceptors.response.use(
  (res) => {
    return Promise.resolve({ data: res.data, error: null, status: res.status });
  },
  (error) => {
    if (error.response) {
      Sentry.captureException(error, {
        tags: {
            type: 'Response_ERROR'
    }
      });

      return Promise.reject({
        data: null,
        error: error.response?.data,
        status: error.response.status,
      });
    } else if (error.request) {
      // Handle network errors
      // console.log('=====> network error', error)
    //   Sentry.captureException(error, {
    //     tags: {
    //         type: 'Request_ERROR'
    // }
    //   });
      return Promise.reject({
        data: null,
        error: error.request?.data,
        status: error.request.status,
      });
    } else {
      Sentry.captureException(error, {
        tags: {
            type: 'Other_ERROR'
    }
      });
      // Handle other errors
      // console.log('=====> error', error)
      return Promise.reject({
        data: null,
        error: error,
        status: 500,
      });
    }
  }
);

export default axiosRequest;
