export const getProductValueFromProductAttributes = (product, key, locale) => {
  const attribute = product?.product_attributes?.find(
    (attribute) => attribute?.["1"]?.label === key
  );
  if (attribute) {
    return locale === "en" ? attribute?.["1"]?.value : attribute?.["5"]?.value;
  }
  return "";
};

function formatPrice(price) {
  if (isNaN(price)) return null;
  const fixedPrice = price?.toFixed(2);
  const [IntPrice, floatPrice] = String(fixedPrice)?.split(".");
  return floatPrice === "00" ? IntPrice : fixedPrice;
}
export const validatePrice = (prices) => {
  const now = new Date();
  const startDate = new Date(prices?.discounted_price_from);
  startDate.setHours(0, 0, 0, 0);
  const endDate = new Date(
    prices?.discounted_price_to?.replace("00:00:00", "23:59:59")
  );

  let originalPrice = prices?.original_price;
  let specialPrice = null;
  let discount = null;

  if (endDate && now >= startDate && now <= endDate) {
    specialPrice = prices?.price;
    discount = ((originalPrice - specialPrice) / originalPrice) * 100;
  }

  return {
    originalPrice,
    specialPrice: originalPrice === specialPrice ? null : specialPrice,
    discount: specialPrice === originalPrice ? null : formatPrice(discount),
  };
};
