import Arrow from "@/components/icons/Arrow";
import useClient from "@/hooks/global/useClient";
import { findObjectByIdV2 } from "@/utils/global";
import Link from "next/link";
import { useEffect, useState } from "react";
const BrandHeader = ({ t, categoryData }) => {
  const { isClient } = useClient();

  const id = process.env.NEXT_PUBLIC_BRANDS_ID;
  const brandsData = findObjectByIdV2(categoryData?.children_data, "id", id);
  const getBrandsData = brandsData
    ? Array.isArray(brandsData?.children_data) && {
        data: brandsData?.children_data?.length
          ? brandsData?.children_data?.filter(
              (item) => item?.is_active && item?.product_count > 0
            )
          : [],
        url_key: brandsData?.url_key || null,
      }
    : {};

  const [brands, setBrands] = useState(getBrandsData?.data);
  useEffect(() => {
    setBrands(getBrandsData?.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryData]);

  return (
    isClient && (
      <div className="group border relative w-40 p-2.5 rounded-xl hidden lg:flex items-center justify-center gap-3 cursor-pointer">
        <div className="font-extrabold text-blue-dark text-[15px]">
          {t("Brands")}
        </div>
        <Arrow className="size-2.5 rotate-90 mt-0.5 text-blue-dark" />
        <div className="hidden group-hover:block absolute z-50 top-full left-0 w-full p-2.5 bg-white border rounded-md">
          <input
            type="text"
            placeholder={t("Search...")}
            className="w-full mb-3 px-2 py-1.5 bg-gray-50 border border-gray-200 rounded-md focus-visible:outline-none"
            onChange={(e) => {
              const { value } = e.target;
              const searchRes = getBrandsData?.data.filter((brand) =>
                brand?.name.toLowerCase()?.includes(value.toLowerCase())
              );
              setBrands(value?.length > 0 ? searchRes : getBrandsData?.data);
            }}
          />
          {brands?.length > 0 ? (
            <ul className="max-h-80 overflow-y-auto orange-scrollbar my-1">
              {brands?.map((brand, i) => (
                <Link
                  key={i}
                  href={
                    brands?.url_key
                      ? `/${brands?.url_key}/${brand?.url_key}`
                      : `/${brand.url_key}`
                  }
                >
                  <li className="hover:text-secondary-700 text-xs border-b p-2 last:border-none cursor-pointer hover:text-primary w-full flex items-center gap-2">
                    {brand?.name}
                  </li>
                </Link>
              ))}
            </ul>
          ) : (
            <span className="text-center w-full my-5">
              {t("There Are No Results")}
            </span>
          )}
        </div>
      </div>
    )
  );
};

export default BrandHeader;
