import LocationCheck from "@/components/icons/LocationCheck";
import React, { useState } from "react";
import LocationsMenu from "./LocationsMenu";

const MobileLocations = ({ t }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="relative">
      <LocationCheck
        onClick={() => setShow((prev) => !prev)}
        className={` size-6 cursor-pointer hover:text-primary ${
          show ? "text-primary" : ""
        }`}
      />
      <LocationsMenu {...{ t, setShow, show }} />
    </div>
  );
};

export default MobileLocations;
