const { useReducer, useEffect, createContext } = require("react");
import store from "store";
import { appReducer } from "./reducers";
export const AppDataContext = createContext();

export const AppProvider = ({ children }) => {
  const savedSData = store.get("app_data") || {
    categoryData: [],
    allCities: [],
    reg: null,
  };
  const [appData, dispatch] = useReducer(appReducer, { ...savedSData });
  useEffect(() => {
    store.set("app_data", appData);
  }, [appData]);

  return (
    <AppDataContext.Provider value={{ appData, dispatch }}>
      {children}
    </AppDataContext.Provider>
  );
};
