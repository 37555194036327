const InfoIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.62713 19.0538C4.31654 19.0538 0 14.7822 0 9.52688C0 4.27159 4.31654 0 9.62713 0C14.9377 0 19.2543 4.27159 19.2543 9.52688C19.2543 14.7822 14.9377 19.0538 9.62713 19.0538ZM9.62713 1.32933C5.05984 1.32933 1.34332 5.00715 1.34332 9.52688C1.34332 14.0466 5.05984 17.7244 9.62713 17.7244C14.1944 17.7244 17.9109 14.0466 17.9109 9.52688C17.9109 5.00715 14.1944 1.32933 9.62713 1.32933Z"
      fill="currentColor"
    />
    <path
      d="M9.62674 11.0778C9.25956 11.0778 8.95508 10.7765 8.95508 10.4132V5.98205C8.95508 5.6187 9.25956 5.31738 9.62674 5.31738C9.99391 5.31738 10.2984 5.6187 10.2984 5.98205V10.4132C10.2984 10.7765 9.99391 11.0778 9.62674 11.0778Z"
      fill="currentColor"
    />
    <path
      d="M9.62699 13.9575C9.51057 13.9575 9.39415 13.9309 9.28668 13.8866C9.17922 13.8423 9.08071 13.7802 8.99115 13.7005C8.91055 13.6118 8.84787 13.5232 8.80309 13.408C8.75831 13.3017 8.73145 13.1865 8.73145 13.0712C8.73145 12.956 8.75831 12.8408 8.80309 12.7345C8.84787 12.6281 8.91055 12.5306 8.99115 12.442C9.08071 12.3623 9.17922 12.3002 9.28668 12.2559C9.50162 12.1673 9.75237 12.1673 9.9673 12.2559C10.0748 12.3002 10.1733 12.3623 10.2628 12.442C10.3434 12.5306 10.4061 12.6281 10.4509 12.7345C10.4957 12.8408 10.5225 12.956 10.5225 13.0712C10.5225 13.1865 10.4957 13.3017 10.4509 13.408C10.4061 13.5232 10.3434 13.6118 10.2628 13.7005C10.1733 13.7802 10.0748 13.8423 9.9673 13.8866C9.85983 13.9309 9.74341 13.9575 9.62699 13.9575Z"
      fill="currentColor"
    />
  </svg>
);
export default InfoIcon;
