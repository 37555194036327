import Image from "@/components/Image";
import RemoveIcon from "@/components/icons/RemoveIcon";
import { useCart } from "@/context/cart";
import { deleteCartItem, updateCartItem } from "@/services/cart";
import { getCookie, getCookies, setCookie } from "cookies-next";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { event_add_to_cart, event_remove_from_cart } from "@/utils/gtm";
import { handleProductForGTMEvents } from "@/utils/global";
import SarIcon from "@/components/icons/Sar";

const CartItemPopup = ({ product }) => {

  const [delLoading, setDelLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [qty, setQty] = useState(1);

  const { handshake, mage, quote } = getCookies();
  const { dispatch } = useCart();
  const { locale } = useRouter();

  useEffect(() => {
    setQty(product?.qty);
  }, [product]);

  const handleRemoveItem = async (product) => {
    setDelLoading(true);
    const res = await deleteCartItem(
      handshake,
      mage,
      quote,
      product?.item_id || product?.data?.item_id
    );
    if (res?.data?.quoteId !== undefined && res?.data?.quoteId != quote) {
      setCookie("quote", res?.data?.quoteId);

      dispatch({ type: "clear" });
    }
    if (res?.status === 200) {
      event_remove_from_cart(handleProductForGTMEvents(product, +qty), +qty, getCookie("coupon_code") || product?.coupon_code)
      console.log("///////////", product)
      dispatch({
        type: "remove",
        payload: { item_id: product?.item_id || product?.data?.item_id },
      });
      toast.success(
        locale === "en"
          ? "Item Deleted From Cart"
          : "تم إزالة المنتج من عربة التسوق"
      );
    } else {
      toast.error(
        locale === "en"
          ? "Error Occured, please try again"
          : "حذث خطأ, برجاء إعادة المحاولة"
      );
    }
    setDelLoading(false);
  };

  const handleUpdateItem = async (qty) => {
    setUpdateLoading(true);
    const res = await updateCartItem(
      handshake,
      mage,
      quote,
      product?.item_id || product?.data?.item_id,
      qty
    );
    if (res?.data?.quoteId !== undefined && res?.data?.quoteId != quote) {
      setCookie("quote", res?.data?.quoteId);
      dispatch({ type: "clear" });
    }
    if (res?.status === 200) {
      let quantity = qty
      if (+product?.qty < qty) {
        quantity = qty - +product?.qty
        event_add_to_cart(handleProductForGTMEvents(product, quantity), quantity, getCookie("coupon_code") || product?.coupon_code)
      } else {
        quantity = +product?.qty - qty
        console.log('quantity, +product?.qty, qty', quantity, +product?.qty, qty)
        event_remove_from_cart(handleProductForGTMEvents(product, quantity), quantity, getCookie("coupon_code") || product?.coupon_code);
      }
      
      setQty(qty);
      toast.success(
        locale === "en"
          ? "Item Updated Successfully"
          : "تم تعديل كمية المنتج بنجاح"
      );
      dispatch({ type: "add_item", payload: { ...product, qty } });
    } else {
      toast.error(
        locale === "en"
          ? "Error Occured, please try again"
          : "حذث خطأ, برجاء إعادة المحاولة"
      );
    }
    setUpdateLoading(false);
  };

  return (
    <div className="flex items-center justify-between pb-2 last:pb-0 border-b last:border-b-0 mb-2 last:mb-0">
      <div className="flex items-center">
        <div className="relative h-20 w-20 flex-shrink-0">
          <Image
            src={`${process.env.NEXT_PUBLIC_IMG_BASEURL}/catalog/product/${
              product?.extension_attributes
                ? product?.extension_attributes?.product_image
                : product?.image?.[0]
            }`}
            alt={product?.title}
            title={product?.title}
            fill={true}
            className="object-contain size-full"
          />
        </div>
        <div className="mx-2">
          <Link
            Link
            href={`/product/${
              product?.extension_attributes
                ? product?.extension_attributes?.rewrite_url
                : product?.rewrite_url
            }`}
            className="text-gray-700 mb-1 line-clamp-1"
          >
            {product?.name || ""}
          </Link>
          <div className="flex items-center gap-2">
            <select
              className="border px-1 outline-none cursor-pointer rounded-md disabled:cursor-not-allowed"
              id=""
              defaultValue={product?.qty}
              value={qty}
              disabled={updateLoading}
              onChange={(e) => {
                setQty(e.target.value);
                handleUpdateItem(e.target.value);
              }}
            >
              {[...Array(10).keys()].map((i) => (
                <option value={i + 1} key={i}>
                  {i + 1}
                </option>
              ))}
            </select>
            <h3 className="font-extrabold text-gray-700 text-sm">
            {(product?.base_price_incl_tax
  ? Number(product?.base_price_incl_tax)
  : Number(product?.prices_with_tax?.price)
)?.toFixed(2) || ""}
               <SarIcon className=" relative inline-block h-6 w-6"/>
            </h3>
            {updateLoading && (
              <span className="border-t border-r border-black rounded-full animate-spin size-3" />
            )}
          </div>
        </div>
      </div>
      <button
        onClick={() => handleRemoveItem(product)}
        className="text-orange-600 hover:text-orange-400 flex items-center justify-center"
      >
        {delLoading ? (
          <span className="border-t border-r border-black rounded-full animate-spin size-3" />
        ) : (
          <RemoveIcon className="size-4" />
        )}
      </button>
    </div>
  );
};

export default CartItemPopup;
