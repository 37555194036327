import Link from "next/link";
import Arrow from "../icons/Arrow";

import { useRouter } from "next/router";
import { findObjectByIdV2 } from "@/utils/global";
import { useGlobalData } from "@/context/global";
import { useEffect, useState } from "react";

const BreadCrumb = ({current = null}) => {
  const [bc, setBc] = useState([]);
  
  const {
    appData: { categoryData },
  } = useGlobalData();

  const { locale, query } = useRouter();
  console.log("CategoryQuery", query)
  const ids = Array.isArray(query?.category)
  ? query.category.map((url_key) =>
      url_key.slice(url_key.lastIndexOf("-c-") + 3)
    )
  : query?.category
  ? [query.category.slice(query.category.lastIndexOf("-c-") + 3)]
  : [];

  useEffect(() => {
    const bc_data = (ids || [])
      .map((id) => findObjectByIdV2(categoryData?.children_data, "id", id))
      ?.filter((item) => item?.id);
    setBc(bc_data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, categoryData, locale]);

  return (
    <section className="py-3 sm:py-4 bg-zinc-100">
      <div className="flex items-center w-11/12 gap-2 mx-auto">
        <Link href="/" className="font-bold text-orange-600 max-sm:text-xs">
          {locale === "en" ? "Home" : "الرئيسية"}
        </Link>
        <Arrow
          className={`size-3 mt-0.5 ${locale === "ar" ? "rotate-180" : ""}`}
        />
        {!current ? 
        bc?.length > 0
          ? bc.map((cat, idx) => {
              const link = bc
                ?.slice(0, idx + 1)
                .map((item) => item?.url_key)
                ?.join("/");
              return (
                <div key={idx} className="flex justify-center items-center">
                  {bc?.length - 1 > idx ? (
                    <Link
                      href={`/${link}`}
                      className="duration-200 hover:text-primary font-bold max-sm:text-xs"
                    >
                      {cat?.name}
                    </Link>
                  ) : (
                    <div className="font-bold max-sm:text-xs">{cat?.name}</div>
                  )}

                  {bc.length - 1 > idx && (
                    <Arrow
                      size="20px"
                      className={`mx-2 ${
                        locale === "en" ? "rotate-0" : "rotate-180"
                      }`}
                    />
                  )}
                </div>
              );
            })
          : null
          : <div className="font-bold max-sm:text-xs">{current}</div>}
        <span className="font-bold max-sm:text-xs">{""}</span>
      </div>
    </section>
  );
};

export default BreadCrumb;
