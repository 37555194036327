import Link from "next/link";
import Image from "@/components/Image";
import { useRouter } from "next/router";
import { event_select_promotion } from "@/utils/gtm";
import React from "react";

const CategoryCard = React.forwardRef(({ data, img, name, url = "/", fromCategory, id }, ref) => {
  const { query } = useRouter();
if(ref!=null) console.log(ref)
  return (
    <Link
      onClick={() => {
        event_select_promotion({
          promotionImage: img,
          key: data?.key,
          position: data?.position,
          id: id,
          name: name,
        });
      }}
      href={
        fromCategory ? Object.values(query)?.join("/").concat(`/${url}`) : url
      }
      className={`flex flex-col justify-between h-full gap-2 ${
        !fromCategory ? "p-3" : "p-1 mx-px"
      } border rounded-md shadow-sm cursor-pointer min-h-32 hover:bg-orange-50`}
    >
      <Image
        loading="eager"
        src={img}
        // ref={ref} // ✅ Now, ref will work correctly
        className="object-contain mx-auto"
        height={56}
        width={56}
        alt={name}
        title={name}
      />
      <span
        title={name}
        className={`text-center line-clamp-2 min-h-9 sm:min-h-12 ${
          fromCategory
            ? "font-bold text-xs"
            : "font-semibold text-xs sm:text-base"
        }`}
      >
        {name}
      </span>
    </Link>
  );
});

// ✅ Add a display name to help with debugging
CategoryCard.displayName = "CategoryCard";

export default CategoryCard;
