import { useCheckout } from "@/context/checkout";
import { ModalContext } from "@/pages/_app";
import { customStyles } from "@/styles/select.style";
import { AddressValidationStep1, AddressValidationStep2 } from "@/utils/valitation/checkout";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useContext, useRef, useState } from "react";
import { useTranslation } from "next-i18next";
import ReactSelect, { components } from "react-select";
import { handleShippingAddress } from "@/utils/checkout/shippingAddress";
import toast from "react-hot-toast";
import { compareObjects } from "@/utils/generic";
import { useGlobalData } from "@/context/global";

const AddEditCheckoutAddress = ({ isPopup = false, editedAddress }) => {
  const cityRef = useRef();
  const { t } = useTranslation(["checkout", "common", "user"]);
  const [step, setStep] = useState(1);
  const Context = useContext(ModalContext);
  const {
    checkout: { regions, activeShippingMethod, userAddresses },
    dispatch,
  } = useCheckout();
  const { dispatch: dispatchRegion } = useGlobalData();
  // const setActive = Context?.state?.payload;
  const [cities, setCities] = useState(
    editedAddress ? regions[editedAddress?.region_id]?.areas : null
  );

  const handleSubmit = async (values, { setSubmitting }) => {

    setSubmitting(true);
    dispatch({ type: "user_city", payload: values.region_id });
    dispatch({ type: "lastname", payload: values.firstname });
    values.street = values.street ? values.street : "...";
    dispatchRegion({
      type: "set_region",
      payload: { id: values?.region_id, name: values?.region },
    });
    if (!activeShippingMethod) {
      editedAddress === undefined
        ? dispatch({
            type: "user_addresses",
            payload: [...userAddresses, values],
          })
        : dispatch({
            type: "user_addresses",
            payload: userAddresses?.map((ad) =>
              compareObjects(ad, editedAddress) ? { ...ad, ...values } : ad
            ),
          });

      dispatch({
        type: "shipping_address",
        payload: { ...values, newAddress: true },
      });
      isPopup && Context?.dispatch({ type: "addNewAddress" });
      toast.success(t("Address has been successfully added!"));
    }
    const res =
      activeShippingMethod &&
      (await handleShippingAddress(
        { ...values, newAddress: true },
        dispatch,
        activeShippingMethod
      ));
    
    dispatch({
      type: "shipping_address",
      payload: { ...values, newAddress: true },
    });
    setSubmitting(false);
    res &&
      isPopup &&
      !editedAddress &&
      Context?.dispatch({ type: "addNewAddress" });

    res &&
      isPopup &&
      editedAddress &&
      Context?.dispatch({ type: "editAddress" });
    // setActive("");
  };

  const initialValues = {
    firstname: editedAddress?.firstname || "",
    lastname: "customer" || "",
    email: editedAddress?.email || "",
    city: editedAddress?.city || "",
    city_id: editedAddress?.extension_attributes?.city_id || "",
    region: editedAddress?.region || "",
    region_id: editedAddress?.region_id || "",
    telephone: editedAddress?.telephone
      ? "0" + editedAddress?.telephone.slice(-9)
      : "",
      street: editedAddress?.street
      ? typeof editedAddress?.street === "object"
        ? editedAddress?.street[0]
        : editedAddress?.street
      : "...",
  };

  // console.log(initialValues);

  const currentRegion = Object.values(regions || {})?.findIndex(
    (item) => item.value == editedAddress?.region_id
  );
  const currentCity = regions[editedAddress?.region_id]?.areas?.findIndex(
    (item) => item.label == editedAddress?.city
  );

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">
          {t("You must select region first")}
        </span>
      </components.NoOptionsMessage>
    );
  };

  const regOptions = Object.values(regions)?.filter(
    (reg) => reg?.title && reg?.value
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={step===1?AddressValidationStep1(t) : AddressValidationStep2(t)}
      onSubmit={(values, actions) => {
        if (step === 1) {
          setStep(2); // Move to step 2
          actions.setSubmitting(false);
        } else {
          handleSubmit(values, actions);
        }
      }}
      enableReinitialize
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form className="grid grid-cols-1 gap-4 sm:grid-cols-2">
           {step === 1 ? (
            <>
          <div className="relative">
            <div>
              <div className="flex items-center gap-1">
                <label
                  htmlFor="firstname"
                  className="block mb-1 w-fit text-zinc-500"
                >
                  {t("First name")}
                </label>
                <span className="text-lg text-red-600">*</span>
              </div>
              <Field
                id="firstname"
                name="firstname"
                placeholder={t("First name")}
                className="block w-full p-2 border rounded-md outline-none border-slate-300 focus:border-primary-400"
              />
            </div>
            <ErrorMessage
              name="firstname"
              component="div"
              className="text-sm text-red-500"
            />
          </div>
          <div className="relative hidden">
            <div>
              <div className="flex items-center gap-1">
                <label
                  htmlFor="lastname"
                  className="block mb-1 w-fit text-zinc-500"
                >
                  {t("Surname")}
                </label>
                <span className="text-lg text-red-600">*</span>
              </div>
              <Field
                id="lastname"
                name="lastname"
                placeholder={t("Surname")}
                className="block w-full p-2 border rounded-md outline-none border-slate-300 focus:border-primary-400"
                value={editedAddress?.firstname}
              />
            </div>
            <ErrorMessage
              name="lastname"
              component="div"
              className="text-sm text-red-500"
            />
          </div>
          
          <div className="relative">
            <div>
              <div className="flex items-center gap-1">
                <label
                  htmlFor="telephone"
                  className="block mb-1 w-fit text-zinc-500"
                >
                  {t("Mobile number")}
                </label>
                <span className="text-lg text-red-600">*</span>
                <sub className=" text-primary font-bold">{t("PhoneSub")}</sub>
              </div>
              <Field
                dir="ltr"
                id="telephone"
                name="telephone"
                placeholder="05xxxxxxxx"
                maxLength="10"
                className="block w-full p-2 border rounded-md outline-none border-slate-300 focus:border-primary-400"
              />
            </div>
            <ErrorMessage
              name="telephone"
              component="div"
              className="text-sm text-red-500"
            />
          </div>
          <div className="relative col-span-full">
            <div>
              <div className="flex items-center gap-1">
                <label
                  htmlFor="email"
                  className="block mb-1 w-fit text-zinc-500"
                >
                  {t("Email address")}
                </label>
                <span className="text-lg text-red-600">*</span>
              </div>
              <Field
                id="email"
                name="email"
                placeholder="name@example.com"
                className="block w-full p-2 border rounded-md outline-none border-slate-300 focus:border-primary-400"
              />
            </div>
            <ErrorMessage
              name="email"
              component="div"
              className="text-sm text-red-500"
            />
          </div>
          </>):(
            <>
          <div className="relative">
            <div>
              <div className="flex items-center gap-1">
                <label
                  htmlFor="region"
                  className="block mb-1 w-fit text-zinc-500"
                >
                  {t("Select city")}
                </label>
                <span className="text-lg text-red-600">*</span>
              </div>
              <ReactSelect
                component={ReactSelect}
                name="region"
                id="region"
                placeholder={t("Please select a region, state or province.")}
                onChange={(e) => {
                  console.log("first", e);
                  setFieldValue("region", e.label);
                  setFieldValue("region_id", e.value);
                  setFieldValue("country_id", e.country_id);
                  setFieldValue("city", "");
                  cityRef.current.clearValue();
                  setCities(e.areas);
                }}
                options={regOptions}
                defaultValue={
                  editedAddress && Object.values(regions)?.at(currentRegion)
                }
                styles={customStyles}
              />
            </div>
            <ErrorMessage
              name="region"
              component="div"
              className="text-sm text-red-500"
            />
          </div>
          <div className="relative">
            <div>
              <div className="flex items-center gap-1">
                <label
                  htmlFor="city"
                  className="block mb-1 w-fit text-zinc-500"
                >
                  {t("Select district")}
                </label>
                <span className="text-lg text-red-600">*</span>
              </div>
              <ReactSelect
                ref={cityRef}
                component={ReactSelect}
                name="city"
                id="city"
                placeholder={t("Please select a city.")}
                onChange={(e) => {
                  setFieldValue("city", e?.label);
                  setFieldValue("city_id", e?.value);
                }}
                options={cities || []}
                defaultValue={editedAddress ? cities?.at(currentCity) : null}
                styles={{
                  NoOptionsMessage: (base) => ({ ...base, ...customStyles }),
                }}
                components={{ NoOptionsMessage }}
              />
            </div>
            <ErrorMessage
              name="city"
              component="div"
              className="text-sm text-red-500"
            />
          </div>
          <div className="relative col-span-full">
            <div>
              <div className="flex items-center gap-1">
                <label
                  htmlFor="street"
                  className="block mb-1 w-fit text-zinc-500"
                >
                  {t("Street")}
                </label>
              </div>
              <Field
                component="textarea"
                id="street"
                name="street"
                placeholder={t("Address details")}
                className="block w-full p-2 border rounded-md outline-none resize-none border-slate-300 focus:border-primary-400"
              />
            </div>
            <ErrorMessage
              name="street"
              component="div"
              className="text-sm text-red-500"
            />
          </div>
          </>
          )}
          <div className="w-full flex justify-center col-span-full gap-2">
          {step === 2 ? (
              <>
              {/* <button type="button" onClick={() => setStep(1)} className="flex items-center justify-center p-2 w-full border-primary border font-semibold text-primary uppercase rounded-md col-span-full bg-white">
                  {t("Back")}
                </button> */}
                <button type="submit" disabled={isSubmitting} className="flex items-center justify-center p-2 w-full font-semibold text-white uppercase rounded-md col-span-full bg-primary">
                  {isSubmitting ? t("Saving")+"..." : t("Save Address")}
                </button>
              </>
            ):(
              <>
               <button type="submit" disabled={isSubmitting} className="flex items-center justify-center w-full p-2 font-semibold text-white uppercase rounded-md col-span-full bg-primary">
                  { t("Next")}
                </button>
               
                </>
            )}
              </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddEditCheckoutAddress;
