import React from "react";
import Image from "@/components/Image";
// import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
// import { BrandBreakPoints } from "@/constants/Home";
import { Autoplay } from "swiper/modules";
import { useRouter } from "next/router";
import { CategoryBannersBreakPoints } from "@/constants/BreakPoints";

const BannersGrid = ({ data, language }) => {
  console.log("data", data);
  const { push } = useRouter();

  return (
    data?.length > 0 && (
      <section className="relative w-11/12 mx-auto mt-7">
        <Swiper
          key={language}
          modules={[Autoplay]}
          // watchOverflow
          autoplay={{ delay: 2500, disableOnInteraction: false }}
          dir={language === "en" ? "ltr" : "rtl"}
          breakpoints={CategoryBannersBreakPoints(
            data?.length > 6 ? 6 : data?.length
          )}
          spaceBetween={"10"}
          // onInit={(e) => set_swiper(e)}
          loop={data?.length > 6 ? true : false}
        >
          {data?.map((banner, idx) => (
            <SwiperSlide key={idx} className="w-full">
              <Image
                loading="eager"
                src={`${process.env.NEXT_PUBLIC_IMG_BASEURL}/${banner?.banner_image}`}
                className="w-auto h-full object-contain cursor-pointer rounded-lg 2xl:w-full"
                onClick={() => push(banner?.url)}
                alt={"Test" || banner[`title-${language}`]}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    )
  );
};

export default BannersGrid;
