import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import useSortProducts from "../category/useSortProducts";
import useInfiniteProducts from "../category/useInfiniteProducts";
import useFilterProducts from "../category/useFilterProducts";
import useSWRMutation from "swr/mutation";
import useOnClickOutsideV2 from "../global/useOnClickOutsideV2";
import { productsSearch } from "@/services/search";
import { getCookie } from "cookies-next";
import { event_error, event_product_searched } from "@/utils/gtm";

const useSearchBox = (refSearchBox, regionId) => {
  const [inputValue, setInputValue] = useState("");
  const [suggestedProducts, setSuggestedProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(true);
  const [totalProducts, setTotalProducts] = useState(0);
  const { events, asPath } = useRouter();
  const handshake = getCookie("handshake");

  const res = useSWRMutation(
    "/search/products/searchQuery",
    async (_, { arg }) => {
      const { pageSize, pageNo, sortKeys, filterKeys } = arg;
      const { data: productsData, error: productsError } = await productsSearch(
        handshake,
        inputValue,
        pageSize,
        pageNo,
        sortKeys,
        filterKeys,
        false,
        regionId
      );

      if (productsError) {
        event_error({
          error_message: productsError,
          error_type: "Request Error",
          error_url: process.env.NEXT_PUBLIC_WEBSITE_URL + asPath,
        });
      }

      let handleProducts = {
        products: productsData?.data?.products,
        aggregate: productsData?.data?.aggregate,
        suggestion: productsData?.data?.suggestion,
        pages: productsData?.data?.pages,
        currentPage: productsData?.data?.currentPage,
        totalProducts: productsData?.data?.totalProducts,
        pageParam: +pageNo,
        error: productsError,
      };
      return handleProducts;
    },
    {
      revalidateOnMount: 30 * 60 * 1000, // 30 minutes in milliseconds
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      populateCache: true,
    }
  );

  const {
    allProducts,
    setAllProducts,
    allFilterKeys,
    setAllFilterKeys,
    allSortingKeys,
    setAllSortingKeys,
    setPageIndex,
  } = useInfiniteProducts({
    products,
    word: inputValue,
    fromCategory: inputValue ? false : true,
  });
  const {
    selectedFilterKeys,
    setSelectedFilterKeys,
    handleFilter,
    handleResetFilter,
    allFilters,
    setAllFilters,
  } = useFilterProducts({
    setAllFilterKeys,
    allFilterKeys,
    setAllProducts,
    products,
    fromCategory: inputValue ? false : true,
    allSortingKeys,
    filters: "filterList",
    setPageIndex,
    word: inputValue,
  });
  // console.log('allProducts in useSearchBox', allProducts);
  // console.log('allFilters in useSearchBox', allFilters);
  const { handleSorting } = useSortProducts({
    setAllSortingKeys,
    setAllProducts,
    fromCategory: inputValue ? false : true,
    allSortingKeys,
    allFilterKeys,
    setPageIndex,
    word: inputValue,
  });

  useEffect(() => {
    events.on("routeChangeComplete", () => {
      setAllFilters([]);
      setAllProducts([]);
      setTotalProducts(0);
      setLoadingSearch(true);
      setInputValue("");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, setInputValue]);

  useOnClickOutsideV2(refSearchBox, () => {
    setAllFilters([]);
    setAllProducts([]);
    setTotalProducts(0);
    setLoadingSearch(true);
    setInputValue("");
  });
  console.log("inputValue////////////", inputValue);
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      const fetchProductsBySearchWord = async () => {
        setLoadingSearch(true);

        if (inputValue && inputValue != undefined) {
          try {
            let result = await res?.trigger({
              pageNo: 0,
              pageSize: 30,
              filterKeys: allFilterKeys,
              sortKeys: allSortingKeys,
              word: inputValue,
            });
            event_product_searched(inputValue, allProducts?.length);
            // console.log("fetchProductsBySearchWord", {
            //     pageNo: 0,
            //     pageSize: 30,
            //     filterKeys: allFilterKeys,
            //     sortKeys: allSortingKeys,
            //     word: inputValue,
            // });
            console.log("result in search//////////", result);
            setSuggestedProducts(
              result?.suggestion &&
                result?.suggestion?.["product_suggestions"] !== undefined
                ? result?.suggestion?.["product_suggestions"]
                : []
            );

            setAllFilters(
              result?.aggregate?.length && result?.aggregate != undefined
                ? result?.aggregate
                : []
            );
            setAllProducts(
              result?.products?.length && result?.products != undefined
                ? result?.products
                : []
            );
            setProducts(
              result?.products?.length && result?.products != undefined
                ? result?.products
                : []
            );
            setTotalProducts(
              result?.totalProducts != undefined ? result?.totalProducts : 0
            );
          } catch (error) {
            if (error) {
              event_error({
                error_message: error,
                error_type: "Request Error",
                error_url: process.env.NEXT_PUBLIC_WEBSITE_URL + asPath,
              });
            }
          }
          setLoadingSearch(false);
        } else {
          setAllFilters([]);
          setAllProducts([]);
          setTotalProducts(0);
          setLoadingSearch(true);
        }
      };
      fetchProductsBySearchWord();
    }, 500);

    return () => clearTimeout(debounceTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFilterKeys, allSortingKeys, inputValue]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if the pressed key is the Esc key (key code 27)
      if (event.key === "Escape" || event.keyCode === 27) {
        setAllFilters([]);
        setAllProducts([]);
        setTotalProducts(0);
        setLoadingSearch(false);
        setInputValue("");
      }
    };

    // Add event listener when component mounts
    document.addEventListener("keydown", handleKeyDown);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    inputValue,
    setInputValue,
    loadingSearch,
    allProducts,
    handleSorting,
    totalProducts,
    selectedFilterKeys,
    setSelectedFilterKeys,
    handleFilter,
    handleResetFilter,
    allFilters,
    suggestedProducts,
  };
};

export default useSearchBox;
