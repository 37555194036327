import { isMaskCart } from "@/helpers/common/IsMaskCart";
import axios from "@/utils/axios-config";
import * as Sentry from "@sentry/react";

/*
===============================|> All Checkout APis <|===============================
01 |--> get Regions,
02 |--> get User addresses,
03 |--> get available shipping methods,
04 |--> add bundle products to cart,
05 |--> get available payments,
===============================|> All Checkout APis <|===============================
*/

// 01- ============= get regions data
const getRegions = async (handshake) => {
  let config = {
    method: "get",
    url: "/customer/getCities",
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

// 02- ============= get regions data
const getUserAddresses = async (handshake, mage) => {
  let config = {
    method: "get",
    url: "/customer/address",
    headers: {
      Authorization: `Bearer ${handshake}`,
      mage: "Bearer " + mage,
    },
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

// 03- ============= get Shipping Methods,
const getShippingMethods = async (handshake, mage, quote, region_id) => {
  let config = {
    method: "GET",
    url: `/mstore/available-shipping-methods/${quote}/${region_id}/${
      mage ? "0" : "1"
    }`,
    headers: {
      Authorization: `Bearer ${handshake}`,
      
    },
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

// 04- ============= get Shipping Address
const setShippingAddress = async (
  handshake,
  mage,
  quote,
  address,
  shipping_method,
  am_pickup
) => {
  const city_id = address?.city_id || null;
  "city_id" in address ? delete address?.city_id : null;
  const isnewAddress = address?.newAddress === true;
  "newAddress" in address ? delete address?.newAddress : null;
  "default_shipping" in address ? delete address?.default_shipping : null;
  "default_billing" in address ? delete address?.default_billing : null;
  "id" in address ? delete address?.id : null;

  const new_address = {
    ...address,
    ...(city_id ? { extension_attributes: { city_id } } : {}),
    street: Array.isArray(address?.street)
      ? [address?.street?.at(0)?.replace(/\n/g, ", ")]
      : [address?.street?.replace(/\n/g, ", ")],
    country_id: "SA",
    postcode: "10577",
    telephone: address?.telephone?.startsWith("966")? address?.telephone :"966".concat(address?.telephone?.slice(1)),
  };
  let data = {
    addressInformation: {
      shipping_address: new_address,
      billing_address: {
        ...new_address,
        ...(mage && isnewAddress ? { save_in_address_book: 1 } : {}),
      },
      ...(am_pickup !== null && shipping_method === "amstorepickup"
        ? {
            extension_attributes: {
              am_pickup,
            },
          }
        : {}),
      shipping_carrier_code: shipping_method,
      shipping_method_code: shipping_method,
    },
  };

  // if(am_pickup!== null && shipping_method === 'amstorepickup'){
  //   data.addressInformation.extension_attributes = {}
  //   data.addressInformation.extension_attributes.am_pickup = am_pickup
  // }

  let config = {
    method: "post",
    url: "/cart/shippingaddress",
    headers: {
      Authorization: `Bearer ${handshake}`,
      quote: "Bearer " + quote,
      ...(mage ? { mage: `Bearer ${mage}` } : {}),
    },
    data: JSON.stringify(data),
  };

  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

// 05- get availanle payment methods
const getAvailablePayments = async (handshake, mage, quote) => {
  const checkQuote = mage ? "0" : "1";
  let config = {
    method: "get",
    url: `/mstore/avilable-payment-methods/${quote}/${checkQuote}`,
    headers: {
      Authorization: `Bearer ${handshake}`,
      admin: "Bearer " + process.env.NEXT_PUBLIC_ADMIN_TOKEN,
    },
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

const placeOrder = async (
  handshake,
  mage,
  quote,
  method,
  email = null,
  additional_data = null
) => {
  let data = {
    ...(email ? { email } : {}),
    paymentMethod: {
      method,
    },
  };

  if (additional_data) {
    data.paymentMethod.additional_data = { ...additional_data };
  }

  let config = {
    method: "post",
    url: "/cart/createOrder",
    maxBodyLength: Infinity,
    headers: {
      Authorization: "Bearer " + handshake,
      quote: "Bearer " + quote,
    },
    data: JSON.stringify(data),
  };
  if (mage) {
    config.headers.mage = "Bearer " + mage;
  }
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

const cardPin = async (handshake, cartId, cardPin) => {
  const isMask = isMaskCart(cartId);
  let data = {
    cardPin,
    cartId,
    isMask: isMask ? "1" : "0",
  };

  let config = {
    method: "post",
    url: "/mstore/card-pin",
    headers: {
      Authorization: "Bearer " + handshake,
    },
    data: JSON.stringify(data),
  };
  try {
    const req = await axios(config);
    return req.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

export {
  getRegions,
  getUserAddresses,
  getShippingMethods,
  setShippingAddress,
  getAvailablePayments,
  placeOrder,
  cardPin,
};
