import { Form, Formik } from "formik";

import Input from "../Input";
import { applyCoupon } from "@/services/cart";
import { getCookies, setCookie } from "cookies-next";
import toast from "react-hot-toast";
import { useRouter } from "next/router";
import { couponValidation } from "@/utils/valitation/cart";
import { event_coupon_redemption } from "@/utils/gtm";

const Coupon = ({ t, refetchCart, closeAll }) => {
  const { locale } = useRouter();
  const { handshake, mage, quote } = getCookies();
  return (
    <Formik
      validationSchema={couponValidation(t)}
      initialValues={{
        // coupon: "",
      }}
      onSubmit={async (values) => {
        const res = await applyCoupon(handshake, mage, quote, values.coupon);
        if (res.status == 200) {
          await refetchCart();
          setCookie('coupon_code', values.coupon)
          event_coupon_redemption(values.coupon, res?.data?.message)
          toast.success(res?.data?.message);
          closeAll();
        } else {
          event_coupon_redemption(values.coupon, res?.error?.error?.message)
          toast.error(
            res?.error?.error?.message ===
              "The coupon code isn't valid. Verify the code and try again."
              ? locale === "ar"
                ? "كود الخصم غير صالح.تحقق من الكود وحاول مرة أخرى."
                : res?.error?.error?.message
              : res?.error?.error?.message
          );
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form className="mt-3 grid gap-3">
          <Input
            id="coupon"
            label={t("Enter code, to get the discount")}
            name="coupon"
            cols="col-span-1"
            placeholder={t("Coupon Code")}
            labelClassName="text-zinc-600 font-semibold text-[15px]"
            t={t}
          />
          <button
            disabled={isSubmitting}
            type="submit"
            className="col-span-full bg-orange-600 border flex justify-center items-center gap-1 rounded-md border-orange-600 text-white py-2 px-3 w-full font-semibold"
          >
            {isSubmitting && <div className="w-6 loader" />}
            {t("Send Code")}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default Coupon;
