const Eye = (props) => (
  <svg
    width={33}
    height={22}
    viewBox="0 0 33 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.9297 10.1635C28.9083 4.10151 22.9266 0 16.0774 0C9.22826 0 3.24492 4.10438 0.225111 10.1641C0.0976563 10.4234 0.03125 10.7098 0.03125 11.0003C0.03125 11.2908 0.0976563 11.5772 0.225111 11.8365C3.24659 17.8985 9.22826 22 16.0774 22C22.9266 22 28.9099 17.8956 31.9297 11.8359C32.0572 11.5766 32.1236 11.2902 32.1236 10.9997C32.1236 10.7092 32.0572 10.4228 31.9297 10.1635ZM16.0774 19.25C14.4906 19.25 12.9394 18.7661 11.62 17.8596C10.3006 16.9531 9.27229 15.6646 8.66504 14.1571C8.0578 12.6496 7.89891 10.9908 8.20848 9.3905C8.51806 7.79016 9.28218 6.32015 10.4042 5.16637C11.5263 4.01259 12.9559 3.22685 14.5122 2.90852C16.0685 2.59019 17.6817 2.75357 19.1477 3.37799C20.6138 4.00242 21.8668 5.05984 22.7484 6.41655C23.63 7.77325 24.1005 9.3683 24.1005 11C24.101 12.0836 23.8939 13.1566 23.4909 14.1578C23.0878 15.1589 22.4969 16.0686 21.7518 16.8348C21.0066 17.601 20.122 18.2087 19.1483 18.6231C18.1747 19.0375 17.1312 19.2505 16.0774 19.25ZM16.0774 5.5C15.6 5.50686 15.1257 5.5799 14.6673 5.71713C15.0451 6.24518 15.2265 6.89499 15.1784 7.54872C15.1303 8.20245 14.8559 8.81681 14.4051 9.28039C13.9543 9.74397 13.3568 10.0261 12.7211 10.0755C12.0853 10.125 11.4534 9.93851 10.9399 9.54995C10.6474 10.6577 10.7002 11.8319 11.0908 12.9072C11.4813 13.9826 12.19 14.9049 13.117 15.5444C14.044 16.1838 15.1427 16.5083 16.2585 16.472C17.3742 16.4358 18.4508 16.0407 19.3367 15.3423C20.2227 14.6439 20.8733 13.6775 21.1971 12.579C21.5208 11.4804 21.5014 10.3052 21.1416 9.21856C20.7818 8.13196 20.0996 7.18875 19.1912 6.52169C18.2827 5.85463 17.1937 5.4973 16.0774 5.5Z"
      fill="currentColor"
    />
  </svg>
);
export default Eye;
