import { validatePrice } from "@/helpers/product";

const gtmEvent = (event, data) => {
  if (typeof window != undefined) {
    window.dataLayer = window.dataLayer || [];
    setTimeout(() => {
      window?.dataLayer?.push({ ecommerce: null });
      window?.dataLayer?.push({
        event,
        ecommerce: data,
      });
    }, 1000);
  }
};

const customGtmEvent = (options = {}) => {
  if (typeof window != undefined) {
    window.dataLayer = window.dataLayer || [];

    setTimeout(() => {
      window?.dataLayer?.push({ ecommerce: null });
      window?.dataLayer?.push({
        ...options,
      });
    }, 1000);
  }
};

const handleValidatePrice = (info) =>
  validatePrice({
    discounted_price_from: info?.extension_attributes?.special_price_from || "",
    discounted_price_to: info?.extension_attributes?.special_price_to || "",
    original_price: info?.extension_attributes?.original_price || 0,
    price: info?.extension_attributes?.special_price || 0,
  });

const event_coupon_redemption = (coupon, result) =>
  customGtmEvent({
    event: "coupon_redemption",
    code: coupon, // The coupon code.
    result,
  });

const event_global_variables = ({
  session,
  store_language,
  user_id,
  email,
  phone,
  city,
  page_type,
}) => {
  if (typeof window != undefined) {
    window.dataLayer = window.dataLayer || [];
    setTimeout(() => {
      window?.dataLayer?.push({
        event: "global_variables",
        ...(user_id ? { user_id } : {}),
        user_type: session ? "Customer" : "Guest",
        event_source: "Website",
        ...(page_type ? { page_type } : {}),
        ...(city ? { city } : {}),
        ...(store_language ? { store_language } : {}),
        ...(phone ? { phone: phone?.slice(-12) } : {}),
        ...(email ? { email } : {}),
      });
    }, 1000);
  }
};

const event_category_clicks = (data) =>
  customGtmEvent({
    event: "category_click",
    ...data,
  });

const event_error = (data) =>
  customGtmEvent({
    event: "error",
    ...data,
  });

const event_login = (
  method,
  status,
  user_id,
  firstname,
  lastname,
  email,
  phone,
  account_creation_date,
  gender,
  city,
  last_purchase_date
) =>
  customGtmEvent({
    event: "login",
    ...(method ? { method } : {}),
    ...(status ? { status } : {}),
    ...(user_id ? { user_id } : {}),
    ...(firstname ? { firstname } : {}),
    ...(lastname ? { lastname } : {}),
    ...(email ? { email } : {}),
    ...(account_creation_date ? { account_creation_date } : {}),
    ...(gender ? { gender } : {}),
    ...(city ? { city } : {}),
    ...(phone ? { phone } : {}),
    referral_url: "https://blackbox.com.sa/en",
    total_purchases: 0,
    total_revenue: 0,
    last_purchase_date: last_purchase_date,
  });
const event_sign_up = ({
  method,
  status,
  user_id,
  firstname,
  lastname,
  email,
  phone,
  account_creation_date,
  gender,
  city,
  user_type = "Guest",
}) =>
  customGtmEvent({
    event: "sign_up",
    ...(method ? { method } : {}),
    ...(status ? { status } : {}),
    user_type,
    ...(user_id ? { user_id } : {}),
    ...(firstname ? { firstname } : {}),
    ...(lastname ? { lastname } : {}),
    ...(email ? { email } : {}),
    ...(phone ? { phone } : {}),
    ...(city ? { city } : {}),
    ...(account_creation_date ? { account_creation_date } : {}),
    ...(gender ? { gender } : {}),
  });

const event_otp_verification = (result) =>
  customGtmEvent({
    event: "otp_verification",
    result,
  });

const event_view_promotion = (promotions) =>
  gtmEvent("view_promotion", {
    items: promotions?.map((promotion) => ({
      creative_name: promotion?.promotionImage, //Carousel/etc… image URL
      creative_slot: promotion?.key, //Carousel/etc… slot index
      location_id: promotion?.position, //Carousel/etc… location on page
      promotion_id: promotion?.id, //Carousel/etc… unique ID
      promotion_name: promotion?.name, //Banner name
    })),
  });

const event_view_products_list = (productsList) =>
  gtmEvent("view_item_list", {
    currency: "SAR",
    items: productsList?.map((product, key) => ({
      item_list_id: product?.category?.at(1)?.category_id || null, //Last clicked product list
      item_list_name: product?.category?.at(1)?.name || null, //Last clicked product list
      item_id: product?.sku,
      item_name: product?.name?.at(0),
      item_link: `${process.env.NEXT_PUBLIC_WEBSITE_URL}/product/${product?.rewrite_url}`,
      original_price: product?.prices_with_tax?.original_price,
      // coupon: "",
      discount:
        product?.prices_with_tax?.original_price -
        product?.prices_with_tax?.price,
      index: key,
      ...(product?.option_text_brand?.at(0) ||
      product?.option_text_a_brand?.at(0)
        ? {
            item_brand:
              product?.option_text_brand?.at(0) ||
              product?.option_text_a_brand?.at(0),
          }
        : {}),
      item_category: product?.main_category|| null,
      item_category2: product?.category?.at(2)?.name || null,
      item_category3: product?.category?.at(3)?.name || null,
      price: product?.prices_with_tax?.price,
      // variant: null,
      item_availability:
        product?.stock?.qty > 0 || product?.stock?.is_in_stock == true
          ? "In Stock"
          : "Out of stock and will be available",
      // promotion_id: null, //Last clicked promotion
      // promotion_name: null, //Last clicked promotion
    })),
  });

const event_select_promotion = (promotion) =>
  gtmEvent("select_promotion", {
    items: [
      {
        creative_name: promotion?.promotionImage, //Carousel/etc… image URL
        creative_slot: promotion?.key, //Carousel/etc… slot index
        location_id: promotion?.position, //Carousel/etc… location on page
        promotion_id: promotion?.id, //Carousel/etc… unique ID
        promotion_name: promotion?.name, //Banner name
      },
    ],
  });

const event_page_viewed = (
  currency,
  language,
  is_login,
  user_id,
  email,
  phone
) =>
  gtmEvent("page_viewed", {
    currency,
    language,
    is_login,
    user_id,
    email,
    phone,
  });

const event_sub_category_viewed = (
  subcategoryname,
  subcategoryid,
  productcount,
  categoryname,
  categoryid,
  type
) =>
  gtmEvent("sub_category_viewed", {
    subcategoryname,
    subcategoryid: subcategoryid?.toString(),
    productcount,
    categoryname,
    categoryid: categoryid?.toString(),
    type,
  });

const event_select_item = (product) =>
  gtmEvent("select_item", {
    currency: "SAR",
    items: [
      {
        item_list_id: product?.category?.at(1)?.category_id || null, //Last clicked product list
        item_list_name: product?.category?.at(1)?.name || null, //Last clicked product list
        item_id: product?.sku,
        item_name: product?.name?.at(0),
        item_link: `${process.env.NEXT_PUBLIC_WEBSITE_URL}/product/${product?.rewrite_url}`,
        item_image_link: product?._media_?.image?.at(0)?.image,
        original_price: product?.prices_with_tax?.original_price,
        // coupon: "",
        discount:
          product?.prices_with_tax?.original_price -
          product?.prices_with_tax?.price,
        index: 0,
        ...(product?.option_text_brand?.at(0) ||
        product?.option_text_a_brand?.at(0)
          ? {
              item_brand:
                product?.option_text_brand?.at(0) ||
                product?.option_text_a_brand?.at(0),
            }
          : {}),
        item_category: product?.main_category || null,
        item_category2: product?.category?.at(2)?.name || null,
        item_category3: product?.category?.at(3)?.name || null,
        price: product?.prices_with_tax?.price,
        // variant: null,
        item_availability:
          product?.stock?.qty > 0 || product?.stock?.is_in_stock == true
            ? "In Stock"
            : "Out of stock and will be available",
        // promotion_id: null, //Last clicked promotion
        // promotion_name: null, //Last clicked promotion
      },
    ],
  });

const event_view_item = (product) =>
  gtmEvent("view_item", {
    currency: "SAR",
    value: product?.prices_with_tax?.price,
    items: [
      {
        item_list_id: product?.category?.at(1)?.category_id || null, //Last clicked product list
        item_list_name: product?.category?.at(1)?.name || null, //Last clicked product list
        item_id: product?.sku,
        item_name: product?.name?.at(0),
        item_link: `${process.env.NEXT_PUBLIC_WEBSITE_URL}/product/${product?.rewrite_url}`,
        item_image_link: product?._media_?.image?.at(0)?.image,
        original_price: product?.prices_with_tax?.original_price,
        // coupon: "",
        discount:
          product?.prices_with_tax?.original_price -
          product?.prices_with_tax?.price,
        index: 0,
        ...(product?.option_text_brand?.at(0) ||
        product?.option_text_a_brand?.at(0)
          ? {
              item_brand:
                product?.option_text_brand?.at(0) ||
                product?.option_text_a_brand?.at(0),
            }
          : {}),
        item_category: product?.main_category || null,
        item_category2: product?.category?.at(2)?.name || null,
        item_category3: product?.category?.at(3)?.name || null,
        price: product?.prices_with_tax?.price,
        // variant: null,
        item_availability:
          product?.stock?.qty > 0 || product?.stock?.is_in_stock == true
            ? "In Stock"
            : "Out of stock and will be available",
        // promotion_id: null, //Last clicked promotion
        // promotion_name: null, //Last clicked promotion
      },
    ],
  });

const event_add_to_cart = (product, qty, coupon_code) =>
  gtmEvent("add_to_cart", {
    currency: "SAR",
    value:
      qty == 1
        ? product?.prices_with_tax?.price ||
          product?.prices_with_tax?.original_price
        : product?.prices_with_tax?.price * qty ||
          product?.prices_with_tax?.original_price * qty,
    items: [
      {
        item_list_id:
          product?.categoryID ||
          product?.categoryName ||
          +product?.category?.at(0)?.category_id ||
          null, //Last clicked product list
        item_list_name:
          product?.categoryName || product?.category?.at(0)?.name || null, //Last clicked product list
        item_id: +product?.sku,
        item_name: product?.name?.at(0),
        item_link: `${process.env.NEXT_PUBLIC_WEBSITE_URL}/product/${product?.rewrite_url}`,
        item_image_link: product?._media_?.image?.at(0)?.image,
        quantity: qty,
        original_price: product?.prices_with_tax?.original_price,
        ...(coupon_code ? { coupon: coupon_code } : {}),
        discount:
          +product?.prices_with_tax?.original_price -
          +(
            product?.prices_with_tax?.price ||
            product?.prices_with_tax?.original_price
          ),
        index: 0,
        ...(product?.option_text_brand?.at(0) ||
        product?.option_text_a_brand?.at(0)
          ? {
              item_brand:
                product?.option_text_brand?.at(0) ||
                product?.option_text_a_brand?.at(0),
            }
          : {}),
        item_category: product?.main_category || null,
        item_category2: product?.category?.at(2)?.name || null,
        item_category3: product?.category?.at(3)?.name || null,
        price:
          product?.prices_with_tax?.price ||
          product?.prices_with_tax?.original_price,
        // variant: null,
        item_availability:
          product?.stock?.qty > 0 || product?.stock?.is_in_stock == true
            ? "In Stock"
            : "Out of stock and will be available",
      },
    ],
  });

const event_remove_from_cart = (product, qty, coupon_code) =>
  gtmEvent("remove_from_cart", {
    currency: "SAR",
    value:
      qty == 1
        ? product?.prices_with_tax?.price ||
          product?.prices_with_tax?.original_price
        : product?.prices_with_tax?.price * qty ||
          product?.prices_with_tax?.original_price * qty,
    items: [
      {
        item_list_id: +product?.category?.at(0)?.category_id || null, //Last clicked product list
        item_list_name: product?.category?.at(0)?.name || null, //Last clicked product list
        item_id: +product?.sku,
        item_name: product?.name?.at(0),
        item_link: `${process.env.NEXT_PUBLIC_WEBSITE_URL}/product/${product?.rewrite_url}`,
        item_image_link: product?._media_?.image?.at(0)?.image,
        quantity: qty, //Number of items Added to Cart
        original_price: product?.prices_with_tax?.original_price,
        price:
          product?.prices_with_tax?.price ||
          product?.prices_with_tax?.original_price,
        ...(coupon_code ? { coupon: coupon_code } : {}),
        discount:
          +product?.prices_with_tax?.original_price -
          +(
            product?.prices_with_tax?.price ||
            product?.prices_with_tax?.original_price
          ),
        ...(product?.option_text_brand?.at(0) ||
        product?.option_text_a_brand?.at(0)
          ? {
              item_brand:
                product?.option_text_brand?.at(0) ||
                product?.option_text_a_brand?.at(0),
            }
          : {}),
        item_category: product?.main_category || null,
        item_category2: product?.category?.at(2)?.name || null,
        item_category3: product?.category?.at(3)?.name || null,
        // variant: null,
        item_availability:
          product?.stock?.qty > 0 || product?.stock?.is_in_stock == true
            ? "In Stock"
            : "Out of stock and will be available",
      },
    ],
  });

const event_cart_viewed = (cartList, total) => {
  return gtmEvent("view_cart", {
    currency: "SAR",
    value: total,
    items: cartList?.map((product, key) => ({
      // item_list_id: product?.category?.at(1)?.category_id
      //   ? Number(product?.category?.at(1)?.category_id)
      //   : null, //Last clicked product list
      ...(product?.category?.at(1)?.category_id && {
        item_list_id: Number(product?.category?.at(1)?.category_id),
      }),
      // item_list_name: product?.category?.at(1)?.name || null, //Last clicked product list
      ...(product?.category?.at(1)?.name && {
        item_list_name: product?.category?.at(1)?.name,
      }),
      item_id: +product?.sku,
      item_name: product?.name?.at(0),
      item_link: `${process.env.NEXT_PUBLIC_WEBSITE_URL}/product/${product?.rewrite_url}`,
      item_image_link: product?._media_?.image?.at(0)?.image,
      quantity: +product?.stock?.qty, //Number of items Added to Cart
      original_price: product?.prices_with_tax?.original_price,
      price:
        product?.prices_with_tax?.price ||
        product?.prices_with_tax?.original_price,
      // coupon: product?.coupon || null,
      ...(product?.coupon && { coupon: product?.coupon }),
      discount:
        product?.prices_with_tax?.original_price ===
          product?.prices_with_tax?.price ||
        product?.prices_with_tax?.price === null
          ? 0
          : product?.prices_with_tax?.original_price -
            product?.prices_with_tax?.price,
      index: key,
      ...(product?.option_text_brand?.at(0) ||
      product?.option_text_a_brand?.at(0)
        ? {
            item_brand:
              product?.option_text_brand?.at(0) ||
              product?.option_text_a_brand?.at(0),
          }
        : {}),
      // item_category: product?.category?.at(1)?.name || null,
      ...(product?.main_category && {
        item_category: product?.main_category,
      }),
      // item_category2: product?.category?.at(2)?.name || null,
      ...(product?.category?.at(2)?.name && {
        item_category2: product?.category?.at(2)?.name,
      }),
      // item_category3: product?.category?.at(3)?.name || null,
      ...(product?.category?.at(3)?.name && {
        item_category3: product?.category?.at(3)?.name,
      }),
      // variant: null,
      item_availability:
        product?.stock?.qty > 0 || product?.stock?.is_in_stock == true
          ? "In Stock"
          : "Out of stock and will be available",
    })),
  });
};
const event_view_item_list = (products, fromSearch = false) =>
  gtmEvent("view_item_list", {
    currency: "SAR",
    items: products?.map((product, key) => ({
      item_list_id: fromSearch
        ? fromSearch
        : product?.category?.at(1)?.category_id || null, //Last clicked product list
      item_list_name: fromSearch
        ? fromSearch
        : product?.category?.at(1)?.name || null, //Last clicked product list
      item_id: product?.sku,
      item_name: product?.name?.at(0),
      item_link: `${process.env.NEXT_PUBLIC_WEBSITE_URL}/product/${product?.rewrite_url}`,
      item_image_link: product?._media_?.image?.at(0)?.image,
      original_price: product?.prices_with_tax?.original_price,
      price: product?.prices_with_tax?.price,
      coupon: "",
      discount:
        product?.prices_with_tax?.original_price -
        (product?.prices_with_tax?.price ||
          product?.prices_with_tax?.original_price),
      index: key,
      item_brand:
        product?.option_text_brand?.at(0) ||
        product?.option_text_a_brand?.at(0) ||
        product?.option_text_a_brand?.at(0),
      ...(product?.option_text_brand?.at(0) ||
      product?.option_text_a_brand?.at(0)
        ? {
            item_brand:
              product?.option_text_brand?.at(0) ||
              product?.option_text_a_brand?.at(0),
          }
        : {}),
      item_category: product?.main_category || null,
      item_category2: product?.category?.at(2)?.name || null,
      item_category3: product?.category?.at(3)?.name || null,
      // variant          : null,
      item_availability:
        product?.stock?.qty > 0 || product?.stock?.is_in_stock == true
          ? "In Stock"
          : "Out of stock and will be available",
    })),
  });
const event_checkout_started = (cartList, total, qty, coupon_code) =>
  gtmEvent("begin_checkout", {
    currency: "SAR",
    value: total,
    ...(coupon_code ? { coupon: coupon_code } : {}),
    items: cartList?.map((product, key) => ({
      item_list_id: +product?.category?.at(0)?.category_id || null, //Last clicked product list
      item_list_name: product?.category?.at(0)?.name || null, //Last clicked product list
      item_id: +product?.sku,
      item_name: product?.name?.at(0),
      item_link: `${process.env.NEXT_PUBLIC_WEBSITE_URL}/product/${product?.rewrite_url}`,
      item_image_link: product?._media_?.image?.at(0)?.image,
      quantity: +product?.qty, //Number of items Added to Cart
      original_price: product?.prices_with_tax?.original_price,
      price:
        product?.prices_with_tax?.price ||
        product?.prices_with_tax?.original_price,
      ...(coupon_code ? { coupon: coupon_code } : {}),
      discount:
        product?.prices_with_tax?.original_price -
        (product?.prices_with_tax?.price ||
          product?.prices_with_tax?.original_price),
      index: key,
      ...(product?.option_text_brand?.at(0) ||
      product?.option_text_a_brand?.at(0)
        ? {
            item_brand:
              product?.option_text_brand?.at(0) ||
              product?.option_text_a_brand?.at(0),
          }
        : {}),
      item_category: product?.main_category || null,
      item_category2: product?.category?.at(2)?.name || null,
      item_category3: product?.category?.at(3)?.name || null,
      item_availability:
        product?.stock?.qty > 0 || product?.stock?.is_in_stock == true
          ? "In Stock"
          : "Out of stock and will be available",
    })),
  });

const event_product_searched = (keyword, total_items) => {
  if (typeof window != undefined) {
    window.dataLayer = window.dataLayer || [];
    setTimeout(() => {
      window?.dataLayer?.push({
        event: "search",
        searched_term: keyword,
        search_results_count: total_items,
      });
    }, 1000);
  }
};

const event_payment_failed = (payment_type, payment_failed_reason) =>
  gtmEvent("payment_failed", { payment_failed_reason, payment_type });

const event_checkout_address_saved = (
  lineitems,
  total,
  shipping_tier,
  coupon_code
) =>
  gtmEvent("add_shipping_info", {
    currency: "SAR",
    value: total,
    shipping_tier,
    ...(coupon_code ? { coupon: coupon_code } : {}),
    items: lineitems?.map((item) => ({
      item_id: +item?.sku || +item?.extension_attributes?.product_sku,
      item_name:
        typeof item?.name != "string" ? item?.name?.at(0) : item?.name || null,
      ...(coupon_code ? { coupon: coupon_code } : {}),
      item_link: `${process.env.NEXT_PUBLIC_WEBSITE_URL}/product/${item?.rewrite_url}`,
      item_image_link: `${process.env.NEXT_PUBLIC_IMG_BASEURL}/catalog/product/${item?.extension_attributes?.product_image}`,
      discount: handleValidatePrice(item)?.discount || 0,
      ...(item?.option_text_brand?.at(0) ||
      item?.extension_attributes?.brand_name
        ? {
            item_brand:
              item?.option_text_brand?.at(0) ||
              item?.extension_attributes?.brand_name,
          }
        : {}),
      item_category:
        item?.main_category ||
        item?.extension_attributes?.category_names?.at(0)?.name ||
        null,
      item_category2:
        item?.category?.at(2)?.name ||
        item?.extension_attributes?.category_names?.at(1)?.name ||
        null,
      item_category3:
        item?.category?.at(3)?.name ||
        item?.extension_attributes?.category_names?.at(2)?.name ||
        null,
      ...(+item?.category?.at(0)?.category_id ||
      +item?.extension_attributes?.category_names?.at(0)?.id
        ? {
            item_list_id:
              +item?.category?.at(0)?.category_id ||
              +item?.extension_attributes?.category_names?.at(0)?.id,
          }
        : {}),
      ...(item?.category?.at(0)?.name ||
      item?.extension_attributes?.category_names?.at(0)?.name
        ? {
            item_list_name:
              item?.category?.at(0)?.name ||
              item?.extension_attributes?.category_names?.at(0)?.name,
          }
        : {}),
      ...(item?.variant ? { item_variant: item?.variant } : {}),
      price:
        handleValidatePrice(item)?.specialPrice ||
        handleValidatePrice(item)?.originalPrice,
      original_price: handleValidatePrice(item)?.originalPrice,
      quantity: +item?.qty,
      item_availability: "In Stock",
    })),
  });

const event_payment_select = (
  lineitems,
  total,
  payment_type,
  coupon_code = null
) => {
  console.log("lineitems", lineitems);
  return gtmEvent("add_payment_info", {
    currency: "SAR",
    value: total,
    payment_type,
    ...(coupon_code ? { coupon: coupon_code } : {}),
    items: lineitems?.map((item) => ({
      item_id: +item?.sku || +item?.extension_attributes?.product_sku,
      item_name:
        typeof item?.name != "string" ? item?.name?.at(0) : item?.name || null,
      ...(coupon_code ? { coupon: coupon_code } : {}),
      discount: handleValidatePrice(item)?.discount || 0,
      item_link: `${process.env.NEXT_PUBLIC_WEBSITE_URL}/product/${item?.rewrite_url}`,
      item_image_link: `${process.env.NEXT_PUBLIC_IMG_BASEURL}/catalog/product/${item?.extension_attributes?.product_image}`,
      ...(item?.option_text_brand?.at(0) ||
      item?.extension_attributes?.brand_name
        ? {
            item_brand:
              item?.option_text_brand?.at(0) ||
              item?.extension_attributes?.brand_name,
          }
        : {}),
      item_category:
        item?.main_category ||
        item?.extension_attributes?.category_names?.at(0)?.name ||
        null,
      item_category2:
        item?.category?.at(2)?.name ||
        item?.extension_attributes?.category_names?.at(1)?.name ||
        null,
      item_category3:
        item?.category?.at(3)?.name ||
        item?.extension_attributes?.category_names?.at(2)?.name ||
        null,
      ...(+item?.category?.at(0)?.category_id ||
      +item?.extension_attributes?.category_names?.at(0)?.id
        ? {
            item_list_id:
              +item?.category?.at(0)?.category_id ||
              +item?.extension_attributes?.category_names?.at(0)?.id,
          }
        : {}),
      ...(item?.category?.at(0)?.name ||
      item?.extension_attributes?.category_names?.at(0)?.name
        ? {
            item_list_name:
              item?.category?.at(0)?.name ||
              item?.extension_attributes?.category_names?.at(0)?.name,
          }
        : {}),
      ...(item?.variant ? { item_variant: item?.variant } : {}),
      price:
        handleValidatePrice(item)?.specialPrice ||
        handleValidatePrice(item)?.originalPrice,
      original_price: handleValidatePrice(item)?.originalPrice,
      quantity: +item?.qty,
      item_availability: "In Stock",
    })),
  });
};

const event_purchase = (
  orderid,
  lineitems,
  tax,
  total,
  shippingcharges,
  coupon
) =>
  gtmEvent("purchase", {
    transaction_id: orderid,
    tax, // The VAT value of the order
    shipping: shippingcharges, //Shipping Cost
    currency: "SAR",
    value: total,
    ...(coupon ? { coupon } : {}),
    items: lineitems?.map((item) => ({
      item_id: +item?.sku || +item?.extension_attributes?.product_sku,
      item_name:
        typeof item?.name != "string" ? item?.name?.at(0) : item?.name || null,
      ...(item?.coupon ? { coupon: item?.coupon } : {}),
      discount:
        item?.base_original_price -
        (item?.base_price || item?.base_original_price),
      ...(item?.option_text_brand?.at(0) ||
      item?.extension_attributes?.brand_name
        ? {
            item_brand:
              item?.option_text_brand?.at(0) ||
              item?.extension_attributes?.brand_name,
          }
        : {}),
      ...(item?.category?.at(1)?.name ||
      item?.extension_attributes?.category_names?.at(1)?.name
        ? {
            item_category:
              item?.main_category ||
              item?.extension_attributes?.category_names?.at(1)?.name,
          }
        : {}),
      ...(item?.category?.at(2)?.name ||
      item?.extension_attributes?.category_names?.at(2)?.name
        ? {
            item_category2:
              item?.category?.at(2)?.name ||
              item?.extension_attributes?.category_names?.at(2)?.name,
          }
        : {}),
      ...(item?.category?.at(3)?.name ||
      item?.extension_attributes?.category_names?.at(3)?.name
        ? {
            item_category3:
              item?.category?.at(3)?.name ||
              item?.extension_attributes?.category_names?.at(3)?.name,
          }
        : {}),
      ...(item?.category?.at(4)?.name ||
      item?.extension_attributes?.category_names?.at(4)?.name
        ? {
            item_category4:
              item?.category?.at(4)?.name ||
              item?.extension_attributes?.category_names?.at(4)?.name,
          }
        : {}),
      ...(item?.category?.at(5)?.name ||
      item?.extension_attributes?.category_names?.at(5)?.name
        ? {
            item_category5:
              item?.category?.at(5)?.name ||
              item?.extension_attributes?.category_names?.at(5)?.name,
          }
        : {}),

      ...(item?.category?.at(0)?.category_id ||
      item?.extension_attributes?.category_names?.at(0)?.category_id
        ? {
            item_list_id:
              item?.category?.at(0)?.category_id ||
              item?.extension_attributes?.category_names?.at(0)?.category_id,
          }
        : {}),
      ...(item?.category?.at(0)?.name ||
      item?.extension_attributes?.category_names?.at(0)?.name
        ? {
            item_list_name:
              item?.category?.at(0)?.name ||
              item?.extension_attributes?.category_names?.at(0)?.name,
          }
        : {}),
      ...(item?.variant ? { item_variant: item?.variant } : {}),
      price: item?.base_price || item?.base_original_price,
      original_price: item?.base_original_price,
      quantity: +item?.qty_ordered,
      item_availability: "In Stock",
    })),
  });

const event_refunds = (orderid, lineitems, total) =>
  gtmEvent("refund", {
    transaction_id: orderid,
    currency: "SAR",
    value: total,
    items: lineitems?.map((item) => ({
      item_id: +item?.sku,
      item_name: Array.isArray(item?.name)
        ? item?.name?.at(0)
        : item?.name || null,
      ...(item?.prices_with_tax?.price ||
      item?.prices_with_tax?.original_price ||
      item?.extension_attributes?.special_price ||
      item?.extension_attributes?.original_price
        ? {
            price:
              item?.prices_with_tax?.price ||
              item?.prices_with_tax?.original_price ||
              item?.extension_attributes?.special_price ||
              item?.extension_attributes?.original_price,
          }
        : {}),
      quantity: +item?.qty_ordered,
    })),
  });

const event_mobile_number = (mobilenumber) =>
  gtmEvent("mobile_number", { mobilenumber });

const event_user_login = (
  firstname,
  lastname,
  mobilenumber,
  email,
  totalordercount,
  Totalltv
) =>
  gtmEvent("user_login", {
    firstname,
    lastname,
    mobilenumber,
    email,
    totalordercount,
    Totalltv,
  });
const event_user_logged_in = (
  firstname,
  lastname,
  mobilenumber,
  email,
  totalordercount,
  Totalltv
) =>
  gtmEvent("user_logged_in", {
    firstname,
    lastname,
    mobilenumber,
    email,
    totalordercount,
    Totalltv,
  });

const event_user_signup = (
  firstname,
  lastname,
  mobilenumber,
  email,
  totalordercount,
  Totalltv
) =>
  gtmEvent("user_signup", {
    firstname,
    lastname,
    mobilenumber,
    email,
    totalordercount,
    Totalltv,
  });
const event_user_logout = (firstname, lastname, email, userid) =>
  gtmEvent("user_logout", {
    firstname,
    lastname,
    email,
    userid,
  });

const event_newsletter_subscribed = (email) =>
  gtmEvent("newsletter_subscribed", {
    email,
  });

// =========================================| NEW TEAM REQUIREMENTS |=========================================
const event_page_view = (language, user) => {
  gtmEvent("page_view", {
    currency: "SAR",
    language,
    is_login: user?.id ? "yes" : "no",
    user_id: user?.id,
    email: user?.email,
    phone: user?.telephone,
  });
};
export {
  event_coupon_redemption,
  event_global_variables,
  event_login,
  event_category_clicks,
  event_error,
  event_sign_up,
  event_otp_verification,
  event_view_item_list,
  event_view_promotion,
  event_select_promotion,
  event_view_products_list,
  event_sub_category_viewed,
  event_view_item,
  event_select_item,
  event_add_to_cart,
  event_remove_from_cart,
  event_cart_viewed,
  event_product_searched,
  event_checkout_started,
  event_checkout_address_saved,
  event_payment_failed,
  event_payment_select,
  event_purchase,
  event_refunds,
  event_mobile_number,
  event_user_login,
  event_user_signup,
  event_page_viewed,
  event_newsletter_subscribed,
  event_user_logout,
  event_user_logged_in,
  event_page_view,
};
