/*
===============================|> All stcpay APis <|===============================
01 |--> get stcpay otp
02 |--> verify stcpay otp
===============================|> All stcpay APis <|===============================
*/

import axios from "@/utils/axios-config";
import { isMaskCart } from "@/helpers/common/IsMaskCart";
import * as Sentry from "@sentry/react";

// 01- =============> set payfort payment method by quote
const getStcPayOtp = async (handshake, quote, mobile) => {
  const checkQuote = isMaskCart(quote);
  let data = JSON.stringify({
    mobile: mobile,
    quoteId: quote,
    isMask: checkQuote,
  });
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "/stcpay/send",
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
    data: data,
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

// 02- =============> stc-pay verify otp
const verifyStcPayOtp = async (
  handshake,
  quote,
  otp,
  paymentReference,
  OtpReference,
  mobile
) => {
  const checkQuote = isMaskCart(quote);
  let data = {
    otp: otp,
    otpReference: OtpReference,
    paymentReference: paymentReference,
    mobile: mobile,
    quoteId: quote,
    isMask: checkQuote,
  };

  let config = {
    method: "post",
    url: "/stcpay/verfiy",
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
    data: JSON.stringify(data),
  };
  try {
    console.log("stcdata", data);
    const req = await axios(config);
    return req.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

export { getStcPayOtp, verifyStcPayOtp };
