import { useGlobalData } from "@/context/global";
import { useEffect, useRef } from "react";
import useSWR from "swr";
import store from "store";
import { useRouter } from "next/router";
import { decodeJwt } from "@/utils/global";
import { getCookie } from "cookies-next";
import axiosRequest from "@/utils/axios-config";
import { event_error } from "@/utils/gtm";

export function useCategoryTree() {
  const { locale } = useRouter();
  const { dispatch } = useGlobalData();
  const handshake = getCookie("handshake");
  const decodedToken = decodeJwt(handshake);
  const { mutate, error } = useSWR(
    () => "/mstore/category/tree",
    async (url) => {
      const { data, error, status } = await axiosRequest({
        method: "GET",
        url,
        headers: {
          Authorization: `Bearer ${handshake}`,
          "Content-Type": "application/json",
        },
      });

      return { data, error, status };
    },
    {
      revalidateOnMount: false, // 30 minutes in milliseconds
      revalidateOnFocus: false,
      onSuccess: (data) => {
        data?.error == null &&
          data?.status == 200 &&
          dispatch({
            type: "get_ctree",
            payload: {
              ...(data?.data?.data || {}),
              cacheTime: Date.now() + 30 * 60 * 1000,
              locale,
            },
          });
      },
    }
  );
  
  const eventErrorRef = useRef(false);
  
  useEffect(() => {
    if (!eventErrorRef.current && error) {
      event_error({
        error_message: error,
        error_type: "Request Error",
        error_url: process.env.NEXT_PUBLIC_WEBSITE_URL
      })
      eventErrorRef.current = true; // Mark as called after first execution
    }
  }, [error]);

  useEffect(() => {
    if (locale !== decodedToken?.storeName) {
      mutate();
      return;
    } else if (
      store.get("app_data")?.categoryData?.cacheTime > Date.now() &&
      locale === decodedToken?.storeName &&
      locale === store.get("app_data")?.categoryData?.locale
    ) {
      // mutate();
      dispatch({
        type: "get_ctree",
        payload: {
          ...store.get("app_data")?.categoryData,
          cacheTime: store.get("app_data")?.cacheTime,
        },
      });
      return;
    } else {
      mutate();
    }
  }, [decodedToken?.storeName, dispatch, locale, mutate]);

  return null;
}
