import selectedAtteributes from "@/constants/selectedAttrubites";
import axiosRequest from "@/utils/axios-config";
import * as Sentry from "@sentry/react";

/*
===============================|> All Category APis <|===============================
01 |--> get category tree
02 |--> get products by category id
===============================|> All Category APis <|===============================
*/

// 01- =============> get category tree
const getCategoryTree = async (handshake) => {
  const config = {
    method: "get",
    url: "/mstore/category/tree",
    headers:{
      'Authorization':`Bearer ${handshake}`
    }
  };
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
}
// 02- =============> get products by category id
// * sortBy can be position | price
// * sortDir can be ASC | DESC
const getProductsByCategoryId = async (handshake, categoryId, sortKeys = 'sortBy=position&sortDir=ASC', filterKeys = '', pageSize = 30, pageNo = 0, getFullResponse = true) => {
  if(!categoryId)
    return [];
  const url = `/search/products/facets/category/${categoryId}?pageSize=${pageSize}&pageNo=${pageNo}&${sortKeys}&${filterKeys}`
  console.log('url/////////', url)
  const config = {
    method: "get",
    url,
    headers:{
      'Authorization':`Bearer ${handshake}`
    }
  };
  if(!getFullResponse){
    config.url += `&${selectedAtteributes}`
  }
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
}

const getProductsByCategory = async (handshake, categoryId, pageSize = 30, pageNo = 0, getFullResponse = true) => {
  const config = {
    method: "get",
    url: `/search/products/category/${categoryId}?pageSize=${pageSize}&pageNo=${pageNo}`,
    headers:{
      'Authorization':`Bearer ${handshake}`
    }
  };
  if(!getFullResponse){
    config.url += `&${selectedAtteributes}`
  }
  try {
    const req = await axiosRequest(config);
    console.log('===========>>', categoryId, req?.data?.data?.products?.length)
    return req;
  } catch (error) {
    console.log('===========>>', categoryId, error.error)
    return error;
  }
}
export {getCategoryTree, getProductsByCategoryId, getProductsByCategory}
