import { useState } from "react";
import { useCheckout } from "@/context/checkout";
import Address from "@/components/checkout/addresses/Address";
import SavedAddress from "@/components/checkout/addresses/SavedAddress";
import { handleShippingAddress } from "@/utils/checkout/shippingAddress";
import { useTranslation } from "next-i18next";
import EditCheckoutAddress from "./EditCheckoutAddress";
import toast from "react-hot-toast";

const ViewCheckoutAddresses = ({ Context }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("checkout");
  const [active, setActive] = useState(null);
  const [editedAddress, setEditedAddress] = useState();
  const { checkout, dispatch } = useCheckout();
  const [submit, setSubmit] = useState(false);
  const setActiveShippingMethod = Context.state.payload;

  const handleChangeAddress = async () => {
    let address = checkout.userAddresses?.at(active);
    const getCurrentCity =
      address?.region_id in checkout?.regions
        ? checkout?.regions[address?.region_id]
        : {};
    console.log("gabr", getCurrentCity);
    delete address?.id;
    getCurrentCity?.value ? (address.city_id = getCurrentCity?.value) : null;
    dispatch({ type: "user_city", payload: getCurrentCity?.value || "" });

    if (checkout?.activeShippingMethod) {
      setSubmit(true);
      const res = await handleShippingAddress(
        address,
        dispatch,
        checkout?.activeShippingMethod,
        null,
        language
      );
      setActiveShippingMethod("");
      setSubmit(false);
      res && Context.dispatch({ type: "viewAllCheckoutAddresses" });
    } else {
      setSubmit(true);
      dispatch({ type: "shipping_address", payload: address });
      toast.success(t("Shipping address changed successfully!"));
      Context.dispatch({ type: "viewAllCheckoutAddresses" });
      setSubmit(false);
    }
  };

  const editShippingHandler = () => {
    setEditedAddress(checkout?.shippingAddress);
  };

  return (
    <>
      {!editedAddress && (
        <div className="bg-white p-4 rounded-md w-11/12 mx-auto md:w-[768px]">
          {" "}
          <h2 className="font-semibold text-lg border-b pb-2">
            {t("Specify the delivery address")}
          </h2>
          <div className=" max-h-[70vh] overflow-y-auto gray-scrollbar">
            {checkout?.shippingAddress ? (
              <Address
                shippingAddress={checkout?.shippingAddress}
                fromPopup={true}
                onEditShipping={editShippingHandler}
              />
            ) : null}
            {checkout?.userAddresses?.map((address, idx) => (
              <SavedAddress
                key={idx}
                idx={idx}
                active={active === idx}
                setActive={setActive}
                address={address}
                onEdit={() => setEditedAddress(address)}
              />
            ))}
          </div>
          <div className="sm:flex items-center justify-between mt-4">
            <div className="flex items-center justify-between sm:justify-start gap-3">
              <button
                disabled={submit || active === null}
                onClick={handleChangeAddress}
                className="flex items-center justify-center bg-primary text-white w-40 font-semibold py-2 px-4  uppercase disabled:opacity-70 rounded-md"
              >
                {submit ? (
                  <span className="w-6 loader flex items-center justify-center"></span>
                ) : (
                  t("Confirm")
                )}
              </button>
              <button
                className="py-2 sm:px-4 underline font-semibold uppercase"
                onClick={() =>
                  Context?.dispatch({ type: "viewAllCheckoutAddresses" })
                }
              >
                {t("Cancel")}
              </button>
            </div>
            <button
              type="button"
              onClick={() => {
                Context?.dispatch({ type: "viewAllCheckoutAddresses" });
                Context?.dispatch({ type: "addCheckoutAddress" });
                setActiveShippingMethod("");
              }}
              className="py-2 underline font-semibold uppercase text-center block w-full sm:w-fit"
            >
              {t("Add New Address")}
            </button>
          </div>
        </div>
      )}
      {editedAddress && (
        <EditCheckoutAddress Context={Context} editedAddress={editedAddress} />
      )}
    </>
  );
};

export default ViewCheckoutAddresses;
