// GET ORDER BY ID
import axios from "@/utils/axios-config";
import * as Sentry from "@sentry/react";

const getOrderByID = async (handshake, orderId) => {
  let config = {
    method: "GET",
    url: `/customer/orders/${orderId}`,
    headers: {
      Authorization: `Bearer ${handshake}`
    },
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

export { getOrderByID };
