import { ModalContext } from "@/pages/_app";
import { useSession } from "next-auth/react";
import React, { useContext } from "react";
import { useTranslation } from "next-i18next";
import LocationCheck from "@/components/icons/LocationCheck";

const Address = ({
  address: addressData,
  shippingAddress = null,
  fromPopup = false,
  onEditShipping,
  setActive,
}) => {
  const address = shippingAddress || addressData;
  console.log("address", address);
  const Context = useContext(ModalContext);
  const { t } = useTranslation("checkout");
  const { data: session } = useSession();
  return address?.region_id ? (
    <div
      className={`border rounded-sm p-3 ${
        shippingAddress ? "bg-primary-100" : "bg-white"
      }`}
    >
      <div className="flex justify-between gap-4">
        <div className="flex gap-4">
          <LocationCheck className="size-12 text-orange-600" />{" "}
          <div className="flex flex-col gap-1 flex-1">
            <p className="font-semibold">
              {(address?.firstname || "")}
            </p>
            <p className="line-clamp-2">
              {address?.street==="..."?(
                ( address?.city || address?.region ||"")):(
                (address?.street || "") +
                " - " +
                ( address?.city || address?.region ||""))
                }
            </p>
            <p>{address?.region || ""}</p>
            <p className="text-orange-600 font-semibold">
              {address?.telephone || ""}
            </p>
          </div>
        </div>
        <button
          onClick={
            !session?.user
              ? () =>
                  Context?.dispatch({
                    type: "editCheckoutAddress",
                    payload: setActive,
                  })
              : fromPopup
              ? () => {
                  onEditShipping(address);
                }
              : () =>
                  Context?.dispatch({
                    type: "viewAllCheckoutAddresses",
                    payload: setActive,
                  })
          }
          className="text-orange-600 underline h-fit whitespace-nowrap"
        >
          {t("Edit Address")}
        </button>
      </div>
    </div>
  ) : null;
};

export default Address;
