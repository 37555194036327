import Link from "next/link";
import Star from "../icons/Star";
import Heart from "../icons/Heart";
import ImgTag from "../global/ImgTag";
import CloseIcon from "../icons/CloseIcon";
import FillHeart from "../icons/FillHeart";
import CompareIcon from "../icons/CompareIcon";
import AddToCart from "../global/buttons/AddToCart";
import CompareContext from "@/context/compare/compare-context";
// import TamaraTabbySwiper from "../global/swiper/TamaraTabbySwiper";

import { useContext, useEffect, useMemo, useState } from "react";
// import { Autoplay } from "swiper/modules";
// import { Swiper, SwiperSlide } from "swiper/react";

import { useRouter } from "next/router";
import { useWishlist } from "@/context/wishlist";
import { handleWishList } from "@/utils/wishlist";
// import Image from "../global/Image";
// import Image from "@/components/Image";
import toast from "react-hot-toast";
import { useTranslation } from "next-i18next";
import Image from "../Image";
import { validatePrice } from "@/helpers/product";
import { event_select_item } from "@/utils/gtm";
import SarIcon from "../icons/Sar";

const ProductCard = ({
  product,
  categoryName = null,
  categoryID = null,
  onRemoveCompare,
  isShowCompareBtn = true,
  fromCategoryProducts,
}) => {
  const price = validatePrice(product?.prices_with_tax);
  const compareCtx = useContext(CompareContext);
  const { wishlist, dispatch } = useWishlist();
  const [inCompare, setInCompare] = useState(false);
  const { t } = useTranslation("common");
const amount = price?.specialPrice? price?.specialPrice : price?.originalPrice
  const eligible_for_tamara = Math.round(amount) >= process.env.NEXT_PUBLIC_TAMARA_LIMIT_FROM &&
  Math.round(amount) <= process.env.NEXT_PUBLIC_TAMARA_LIMIT_TO &&
  product?.eligible_for_tamara === true;

 const eligible_for_mispay =Math.round(amount) >= process.env.NEXT_PUBLIC_MISPAY_LIMIT_FROM &&
  Math.round(amount)<= process.env.NEXT_PUBLIC_MISPAY_LIMIT_TO 
  && product?.eligible_for_mispay === true;
  useEffect(() => {
    const existingProduct = compareCtx.compareProducts.find(
      (compareProduct) => compareProduct.sku === product.sku
    );

    setInCompare(!!existingProduct);
  }, [product?.sku, compareCtx?.compareProducts]);

  const { locale } = useRouter();

  const extentions = useMemo(() => {
    return [".png", ".jpg", ".webp", ".jpeg"];
  }, []);

  const media = useMemo(
    () =>
      (product?._media_?.image || [])?.sort(
        (a, b) => +a.position - +b.position
      ),
    [product]
  );
  // console.log('product?.overAll_rating', product?.overAll_rating)
  return (
    <div className={`py-0.5 ${onRemoveCompare ? "mt-2" : ""}`}>
      <div
        className={`relative flex flex-col justify-between p-2 mx-px rounded-md shadow-brand sm:p-3 ${
          fromCategoryProducts && "bg-white"
        }`}
      >
        <Link
          onClick={() => event_select_item(product)}
          href={`/product/${product?.rewrite_url}`}
          className="relative flex items-center justify-center w-full overflow-hidden h-28 sm:h-36"
        >
          {product?.label?.length > 0 && (
            <div className="absolute top-0 z-40 flex items-center justify-center max-w-full gap-2 start-0">
              {product?.label?.map(
                (label, i) =>
                  label?.status === "1" &&
                  label?.["frontend_settings"]?.image && (
                    <Image
                      key={i}
                      src={
                        process.env.NEXT_PUBLIC_IMG_BASEURL +
                        "/amasty/amlabel/" +
                        label?.["frontend_settings"]?.image
                      }
                      alt={label?.name}
                      className="h-10"
                    />
                  )
              )}
            </div>
          )}
          {media?.at(0)?.image && (
            <div className="relative flex items-center justify-center w-full h-full overflow-hidden cursor-pointer">
              <ImgTag
                sizes="(min-width: 1700px) 215px, (min-width: 1220px) 12.39vw, (min-width: 1000px) calc(20vw - 25px), (min-width: 780px) calc(27vw - 28px), (min-width: 580px) calc(40vw - 22px), calc(48.85vw - 22px)"
                loading="eager"
                priority={true}
                src={media?.at(0)?.image || ""}
                className="object-contain w-11/12 h-full transition-all duration-500 hover:scale-105"
                fill={true}
                alt=""
              />
            </div>
          )}
        </Link>
        <button
          onClick={() => handleWishList(wishlist, product, dispatch)}
          className="absolute z-30 flex items-center justify-center p-1 bg-white border rounded-full shadow max-sm:top-1.5 max-sm:end-1.5 top-5 end-5 size-7 sm:size-8"
        >
          {product?.sku in wishlist ? (
            <FillHeart className="size-3.5 sm:size-4" />
          ) : (
            <Heart className="size-3.5 sm:size-4" />
          )}
        </button>
        <div
          className={`flex items-center ${
            product?.brand_logo &&
            extentions?.some((item) => product?.brand_logo?.includes(item))
              ? "flex-row justify-between"
              : "flex-row-reverse"
          }`}
        >
          {product?.brand_logo &&
          extentions?.some((item) => product?.brand_logo?.includes(item)) ? (
            <ImgTag
              loading="eager"
              priority={true}
              src={
                product?.brand_logo
                  ? `${process.env.NEXT_PUBLIC_IMG_BASEURL}/${product?.brand_logo}`
                  : null
              }
              className="object-contain w-11 h-7"
              height={75}
              width={75}
              alt={
                product?.option_text_brand?.at(0) ||
                product?.option_text_a_brand?.at(0) ||
                ""
              }
            />
          ) : null}
          <div className="flex items-center gap-1 px-2 pb-0.5 md:py-1 text-white bg-orange-500 rounded-md">
            <Star />
            <span className="text-sm font-bold">
              {+product?.overAll_rating || +product?.reviews_count}
            </span>
          </div>
        </div>
        <div className="min-h-16">
          <Link
            href={`/product/${product?.rewrite_url}`}
            onClick={() => event_select_item(product)}
            className="line-clamp-2 my-1.5 font-semibold text-sm sm:text-base"
          >
            {product && "highlight" in product && product?.highlight?.name ? (
              <h2
                dangerouslySetInnerHTML={{ __html: product.highlight.name }}
              />
            ) : (
              <h2>{product?.name?.at(0) || ""}</h2>
            )}
          </Link>
        </div>
        <ul className="my-1.5 flex items-center gap-1 flex-wrap min-h-14 max-sm:min-h-[27px] max-h-14 max-sm:max-h-[27px] overflow-hidden">
          {product?.keywords?.map(
            (word) =>
              word !== "" && (
                <li
                  key={word}
                  className="px-2 py-1 text-[9px] sm:text-xs border rounded-md"
                >
                  {word}
                </li> 
              )

          )}
        </ul>
        <div className="flex items-center justify-between gap-2 min-h-[52px] my-1.5">
          <div className="flex flex-col">
            <span className="text-sm font-bold sm:text-lg text-primary whitespace-nowrap">
              {Intl.NumberFormat("en-US").format(
                price?.specialPrice || price?.originalPrice
              )}{" "}
                <SarIcon className=" relative inline-block h-6 w-6"/>
            </span>
            {price?.specialPrice && price?.originalPrice && (
              <span className="text-xs line-through text-[#B2B2B2] sm:text-base whitespace-nowrap">
                {Intl.NumberFormat("en-US").format(price?.originalPrice)}{" "}
                <SarIcon className=" relative inline-block h-6 w-6"/>
              </span>
            )}
          </div>
          {price?.specialPrice && price?.originalPrice && (
            <span className="bg-black text-white rounded-md py-1 px-1 sm:px-2 text-[11px] sm:text-sm text-center">
              {locale === "en" ? "Save" : "وفر"} {price?.discount}%
            </span>
          )}
        </div>
        <div className="h-14">
        <div className={eligible_for_mispay || eligible_for_tamara ?"h-14 border border-primary rounded-md p-1 border-opacity-50":"!hidden"} >
         <p className="text-xs">{t("buy now pay later")}</p>
        <div className="flex items-center gap-4 *:flex justify-center">
        <Image
            loading="lazy"
            src="/imgs/mispay.svg"
            width={50} 
            height={8}
            priority={false}
            className={eligible_for_mispay?"":"!hidden"}
            alt="Mis pay"
          />
          <Image
            loading="lazy"
            src="/imgs/tamara.png"
            width={50} 
            height={8}
            priority={false}
            className={eligible_for_tamara?"":"!hidden"}
            alt="Mis pay"
          />
          
        </div>
        </div>
        </div>
        <div className=" mt-2">
          <div className=" h-17 border border-primary rounded-md p-1 border-opacity-50" >
            <p className="text-xs">{t("pay cash")}</p>
            <div className="flex items-center gap-2 *:flex justify-center mt-2">
              <Image
                  loading="lazy"
                  src="/imgs/Mada_Logo.svg"
                  width={40} 
                  height={6}
                  priority={false}
                  alt="Mada"
                />
              <Image
                  loading="lazy"
                  src="/imgs/footer/Visa_Inc._logo.svg.webp"
                  width={40} 
                  height={6}
                  priority={false}
                  alt="Visa"
                />
              <Image
                  loading="lazy"
                  src="/imgs/footer/Apple_Pay_logo.svg"
                  width={40} 
                  height={6}
                  priority={false}
                  alt="ApplePay"
                />

            </div>
            <div className="flex items-center gap-2 *:flex justify-center">
              
              <Image
                  loading="lazy"
                  src="/imgs/footer/master.webp"
                  width={40} 
                  height={6}
                  priority={false}
                  alt="Master"
                />
                <Image
                  loading="lazy"
                  src="/payments/stc_pay.png"
                  width={40} 
                  height={6}
                  priority={false}
                  alt="STC pay"
                />

            </div>
          </div>
        </div>
        <div className="my-1.5 flex items-center justify-between gap-2 *:flex *:items-center *:justify-center">
          <AddToCart {...{ categoryName, categoryID, product }} />
          {isShowCompareBtn && (
            <button
              className={`w-10 max-sm:w-14 border py-2.5 sm:py-3 rounded-md max-sm:flex-1 ${
                inCompare && "bg-orange-500 text-white"
              }`}
              onClick={() => {
                if (compareCtx.compareProducts.length >= 4 && !inCompare) {
                  return toast.error(
                    t(
                      "Maximum 4 products can be compared, please remove one or more from compare to add another."
                    )
                  );
                }
                compareCtx.addProductToCompare(product);
              }}
            >
              <CompareIcon />
            </button>
          )}
        </div>
        {/* <TamaraTabbySwiper {...{ product, locale }} /> */}
        {onRemoveCompare && (
          <div
            onClick={onRemoveCompare}
            className="absolute cursor-pointer -top-1 -right-3 z-50 bg-white border rounded-md p-2 border-[#CDCDCD]"
          >
            <CloseIcon stroke="black" strokeWidth={2} width={14} height={14} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
