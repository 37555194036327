import { useBodyScrollLock } from "@/hooks/global/useBodyScrollLock";
import CloseIcon from "../icons/CloseIcon";
import { useRef, useState } from "react";
import useOnClickOutside from "@/hooks/useOnClickOutside";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

const TrackOrder = ({ Context }) => {
  useBodyScrollLock();
  const ref = useRef();
  const { push } = useRouter();
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  useOnClickOutside(ref, () => Context?.dispatch({ type: "trackOrder" }));
  const handleSubmit = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      Context?.dispatch({ type: "trackOrder" });
      push("/tracking-order");
    }, 1500);
  };
  return (
    <section ref={ref} className="w-11/12 sm:w-[500px] bg-white rounded-md">
      <div className="flex items-center justify-between gap-3 p-3 border-b sm:p-4">
        <div className="font-semibold md:text-xl md:font-bold">
          {t("Track Order")}
        </div>
        <button onClick={() => Context?.dispatch({ type: "trackOrder" })}>
          <CloseIcon className="size-5" stroke="#005471" strokeWidth="2" />
        </button>
      </div>
      <form
        className="p-3.5 sm:p-5"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="">
          <input
            className="w-full p-2 border rounded-md outline-none max-sm:placeholder:text-sm max-sm:text-sm"
            placeholder={language === "en" ? "Order Name" : "رقم الطلب"}
          />
        </div>
        <button
          disabled={loading}
          className="flex items-center justify-center w-full p-2 mt-2 text-white rounded-md disabled:cursor-not-allowed max-sm:text-sm bg-primary"
        >
          {!loading ? (
            t("Track Order")
          ) : (
            <span className="flex items-center justify-center w-6 loader"></span>
          )}
        </button>
      </form>
    </section>
  );
};

export default TrackOrder;
