import LocationCheck from "@/components/icons/LocationCheck";
import useClient from "@/hooks/global/useClient";
import Arrow from "@/components/icons/Arrow";

import { useState } from "react";
import { useGlobalData } from "@/context/global";
import LocationsMenu from "./LocationsMenu";

const LocationHeader = ({ t }) => {
  const {
    appData: { reg, allCities },
  } = useGlobalData();
  const { isClient } = useClient();
  const [show, setShow] = useState(false);

  return (
    isClient && (
      <div className="relative order-last lg:order-none border p-2.5 rounded-xl w-full lg:w-64 hidden lg:flex items-center justify-between bg-zinc-100 cursor-pointer">
        <div
          className="flex items-center justify-between w-full"
          onClick={() => setShow(!show)}
        >
          <LocationCheck className="size-5 text-gray-dark" />
          <p className="mx-3 flex-1 text-xs text-gray-dark">
            {reg &&
            reg?.id &&
            !isNaN(reg?.id) &&
            allCities?.data &&
            allCities?.data?.length
              ? allCities?.data
                  ?.slice(1, allCities?.data?.length)
                  ?.find((item) => item?.value == reg?.id)?.label
              : t("Please Choose Your Location")}
          </p>
          <Arrow className="size-2.5 rotate-90 mt-0.5 text-gray-dark" />
        </div>
        <LocationsMenu {...{ t, show, setShow }} />
      </div>
    )
  );
};

export default LocationHeader;
