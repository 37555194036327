const CompareIcon = (props) => (
  <svg
    width={17}
    height={16}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.0092 13.8398C10.8808 13.8398 10.7523 13.7931 10.6509 13.6931C10.4549 13.4998 10.4549 13.1798 10.6509 12.9865L14.0375 9.64646C14.2336 9.45313 14.558 9.45313 14.7541 9.64646C14.9501 9.8398 14.9501 10.1598 14.7541 10.3531L11.3675 13.6931C11.2661 13.7865 11.1376 13.8398 11.0092 13.8398Z"
      fill="currentColor"
    />
    <path
      d="M14.3969 10.4932H2.90541C2.62827 10.4932 2.39844 10.2665 2.39844 9.99316C2.39844 9.71983 2.62827 9.49316 2.90541 9.49316H14.3969C14.674 9.49316 14.9039 9.71983 14.9039 9.99316C14.9039 10.2665 14.674 10.4932 14.3969 10.4932Z"
      fill="currentColor"
    />
    <path
      d="M2.90568 6.50679C2.77724 6.50679 2.64881 6.46012 2.54741 6.36012C2.35138 6.16679 2.35138 5.84679 2.54741 5.65346L5.93402 2.31346C6.13005 2.12012 6.45451 2.12012 6.65055 2.31346C6.84658 2.50679 6.84658 2.82679 6.65055 3.02012L3.26394 6.36012C3.16931 6.45346 3.03411 6.50679 2.90568 6.50679Z"
      fill="currentColor"
    />
    <path
      d="M14.3969 6.50684H2.90541C2.62827 6.50684 2.39844 6.28017 2.39844 6.00684C2.39844 5.7335 2.62827 5.50684 2.90541 5.50684H14.3969C14.674 5.50684 14.9039 5.7335 14.9039 6.00684C14.9039 6.28017 14.674 6.50684 14.3969 6.50684Z"
      fill="currentColor"
    />
  </svg>
);
export default CompareIcon;
