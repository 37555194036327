import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import CartIcon from "@/components/icons/CartIcon";
import CartItemPopup from "./CartItemPopup";
import { event_checkout_started } from "@/utils/gtm";
import { getCookie } from "cookies-next";
import { validatePrice } from "@/helpers/product";
import SarIcon from "@/components/icons/Sar";
// import { validatePrice } from "@/helpers/product";
// import { event_checkout_started } from "@/utils/gtm";

const CartPopUp = ({ cart }) => {
  const items = cart?.cartItems || [];
  const { t } = useTranslation("common");
  const { locale, pathname, push } = useRouter();
  console.log('cart////////////// in CartPopUp', cart)
  const total = (items || [])?.reduce(
    (cur, acc) =>
      cur +
      (acc?.base_price_incl_tax
        ? acc?.base_price_incl_tax
        : acc?.prices_with_tax?.price) *
        acc.qty,
    0
  );

  // const handleViewCheckoutGtmEvent = () => {
  //   const qty = items
  //     ?.map((item) => item?.qty)
  //     ?.reduce((cur, acc) => cur + acc, 0);
  //   const total = cart?.cart?.grand_total ? cart?.cart?.grand_total : items?.map((item) => item?.data?.price)?.reduce((cur, acc) => cur + acc, 0);

  //   const updateItem = items?.map((item) => {
  //     const prices = validatePrice({
  //       discounted_price_from: item?.extension_attributes?.special_price_from
  //         ? item?.extension_attributes?.special_price_from
  //         : item?.prices_with_tax?.discounted_price_from,
  //       discounted_price_to: item?.extension_attributes?.special_price_to
  //         ? item?.extension_attributes?.special_price_to
  //         : item?.prices_with_tax?.discounted_price_to,
  //       original_price: item?.extension_attributes?.original_price
  //         ? item?.extension_attributes?.original_price
  //         : item?.prices_with_tax?.original_price,
  //       price: item?.extension_attributes?.special_price
  //         ? item?.extension_attributes?.special_price
  //         : item?.prices_with_tax?.price,
  //     });

  //     return {
  //       category: item?.extension_attributes?.category_names
  //         ? item?.extension_attributes?.category_names?.map((category) => ({
  //             name: category?.name,
  //             category_id: category?.id,
  //           }))
  //         : item?.category,
  //       sku: item?.extension_attributes?.product_sku ? item?.extension_attributes?.product_sku : item?.sku,
  //       name: Array.isArray(item?.name) ? item?.name : [item?.name],
  //       rewrite_url: item?.extension_attributes?.rewrite_url
  //         ? item?.extension_attributes?.rewrite_url
  //         : item?.rewrite_url,
  //       ...(item?._media_
  //         ? {_media_: item?._media_}: {
  //             _media_: {
  //               image: [
  //                 {
  //                   image: `${process.env.NEXT_PUBLIC_IMG_BASEURL}/catalog/product/${item?.extension_attributes?.product_image}`,
  //                 },
  //               ],
  //             },
  //           }),
  //       option_text_brand: item?.extension_attributes?.brand_name
  //         ? [item?.extension_attributes?.brand_name]
  //         : "",
  //       ...(item?.prices_with_tax
  //         ? {prices_with_tax: item?.prices_with_tax}: {
  //             prices_with_tax: {
  //               price: prices?.specialPrice,
  //               original_price: prices?.originalPrice,
  //             },
  //           }),
  //       ...(item?.stock
  //         ? {stock: item?.stock}: {
  //             stock: {
  //               qty: item?.qty,
  //               is_in_stock: true,
  //             },
  //           }
  //         ),
  //     };
  //   });
  //   event_checkout_started(updateItem, total, qty);
  // };

  const handleGoToCheckout = () => {
    const { grand_total, coupon_code } = cart?.cart;
    const qty = items
      ?.map((item) => item?.qty)
      ?.reduce((cur, acc) => cur + acc, 0);
    const total = grand_total
      ? grand_total
      : items
          ?.map((item) => item?.data?.price)
          ?.reduce((cur, acc) => cur + acc, 0);

    const updateItem = items?.map((item) => {
      const prices = validatePrice({
        discounted_price_from: item?.extension_attributes?.special_price_from
          ? item?.extension_attributes?.special_price_from
          : item?.prices_with_tax?.discounted_price_from,
        discounted_price_to: item?.extension_attributes?.special_price_to
          ? item?.extension_attributes?.special_price_to
          : item?.prices_with_tax?.discounted_price_to,
        original_price: item?.extension_attributes?.original_price
          ? item?.extension_attributes?.original_price
          : item?.prices_with_tax?.original_price,
        price: item?.extension_attributes?.special_price
          ? item?.extension_attributes?.special_price
          : item?.prices_with_tax?.price,
      });
      return {
        category: item?.extension_attributes?.category_names
          ? item?.extension_attributes?.category_names?.map((category) => ({
              name: category?.name,
              category_id: category?.id,
            }))
          : item?.category,
        sku: item?.extension_attributes?.product_sku
          ? item?.extension_attributes?.product_sku
          : item?.sku,
        name: Array.isArray(item?.name) ? item?.name : [item?.name],
        ...(coupon_code
          ? { coupon: coupon_code }
          : getCookie("coupon_code") || {}),
        rewrite_url: item?.extension_attributes?.rewrite_url
          ? item?.extension_attributes?.rewrite_url
          : item?.rewrite_url,
        ...(item?._media_
          ? { _media_: item?._media_ }
          : {
              _media_: {
                image: [
                  {
                    image: `${process.env.NEXT_PUBLIC_IMG_BASEURL}/catalog/product/${item?.extension_attributes?.product_image}`,
                  },
                ],
              },
            }),
        option_text_brand: item?.extension_attributes?.brand_name
          ? [item?.extension_attributes?.brand_name]
          : item?.option_text_a_brand ? item?.option_text_a_brand : null,
        qty: item?.qty ? item?.qty : "",
        ...(item?.prices_with_tax
          ? { prices_with_tax: item?.prices_with_tax }
          : {
              prices_with_tax: {
                price: prices?.specialPrice,
                original_price: prices?.originalPrice,
              },
            }),
        ...(item?.stock
          ? { stock: item?.stock }
          : {
              stock: {
                qty: item?.qty,
                is_in_stock: true,
              },
            }),
      };
    });
    console.log("items////////////", items);
    event_checkout_started(
      updateItem,
      total,
      qty,
      getCookie("coupon_code") || cart?.coupon_code
    );
    push("/checkout");
  };

  return (
    <div
      className={`hidden group-hover:block duration-500 transition-all absolute w-[300px] ring-1 ring-slate-200 shadow-lg z-50 top-9 ${
        locale === "ar"
          ? "left-3/4 -translate-x-1/4"
          : "right-3/4 translate-x-1/4"
      } bg-white  overflow-hidden rounded-lg`}
    >
      {(items || [])?.length ? (
        <>
          <div className="p-3 pb-0 max-h-[250px] overflow-y-auto gray-scrollbar">
            {(items || [])?.map((product, idx) => (
              <CartItemPopup product={product} key={idx} />
            ))}
          </div>
          <div className="sticky bottom-0 p-3 bg-white border-t cursor-pointer">
            <div className="flex items-center justify-between mb-1 text-orange-600">
              <h2 className="font-extrabold">{t("Total")}</h2>
              <h4 className="font-bold">
                {total?.toFixed(2) || "0"} <SarIcon className=" relative inline-block h-6 w-6"/>
              </h4>
            </div>
            <Link
              href="/cart"
              className="block w-full p-2 mb-2 text-center text-white transition-colors duration-300 bg-orange-600 rounded-md hover:bg-orange-400"
            >
              {t("Cart")}
            </Link>
            <span
              onClick={handleGoToCheckout}
              className="block w-full p-2 text-center text-orange-600 transition-colors duration-300 border border-orange-600 rounded-md hover:text-white hover:bg-orange-600"
            >
              {t("Checkout")}
            </span>
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center gap-4 p-4">
          <CartIcon size={48} className="text-slate-300" />
          <span className="text-orange-600">{t("No Items in Cart")}</span>
          {pathname !== "/" ? (
            <Link
              href="/"
              className="block w-full p-2 mb-2 text-center text-white transition-colors duration-300 bg-orange-600 rounded-md hover:bg-orange-400"
            >
              {t("Continue shopping")}
            </Link>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default CartPopUp;
