import Arrow from "@/components/icons/Arrow";
import { useState } from "react";

const Brands = ({ options, t, selectedFilterKeys, handleFilter, code }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <ul className="flex flex-col gap-2">
      {options?.slice(0, showMore ? options?.length : 11)?.map((option) => (
        <li
          id={`brand-${option?.key}`}
          key={option?.key}
          className="flex items-center gap-2 text-slate-600"
          onClick={() =>
            handleFilter({
              code,
              item: option,
            })
          }
        >
          <input
            id={`brand-${option?.key}`}
            type="checkbox"
            className={`
              accent-orange-600 border border-slate-600 size-3 sm:size-3.5 outline-none ${
                selectedFilterKeys?.includes(`${code}=${option?.Key}`)
                  ? "checked:bg-green-700 checked:border-0"
                  : ""
              }`}
            checked={selectedFilterKeys?.includes(`${code}=${option?.Key}`)}
          />
          <label
            htmlFor={`brand-${option?.key}`}
            className="flex items-center gap-1.5 text-sm cursor-pointer"
          >
            <span>{option?.Label}</span>
            <span className="text-sm opacity-80">({option?.DocCount})</span>
          </label>
        </li>
      ))}
      {options?.length > 10 ? (
        <li
          className="flex items-center gap-2 text-slate-600 cursor-pointer text-[15px]"
          onClick={() => setShowMore(!showMore)}
        >
          {!showMore ? (
            <span>{t("Show More")}</span>
          ) : (
            <span>{t("Show Less")}</span>
          )}
          <Arrow className="rotate-90 size-3 mt-0.5" />
        </li>
      ) : null}
    </ul>
  );
};

export default Brands;
