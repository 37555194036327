import { validatePrice } from "@/helpers/product";
import { setHours, setMinutes } from "date-fns";

export const preventLettersInInput = (event) => {
  const input = event.key;
  // Allow control keys (e.g., backspace, delete)
  if (
    event.ctrlKey ||
    event.altKey ||
    event.metaKey ||
    input === "Backspace" ||
    input === "Delete"
  ) {
    return;
  }

  // Check if the input is not a number
  if (isNaN(Number(input))) {
    event.preventDefault();
  }
};

export function handleProductForGTMEvents(product, qty) {
  const prices = validatePrice({
    discounted_price_from: product?.prices_with_tax?.discounted_price_from
      ? product?.prices_with_tax?.discounted_price_from
      : product?.extension_attributes?.special_price_from,
    discounted_price_to: product?.prices_with_tax?.discounted_price_to
      ? product?.prices_with_tax?.discounted_price_to
      : product?.extension_attributes?.special_price_to,
    original_price: product?.prices_with_tax?.original_price
      ? product?.prices_with_tax?.original_price
      : product?.extension_attributes?.original_price,
    price: product?.prices_with_tax?.price
      ? product?.prices_with_tax?.price
      : product?.extension_attributes?.special_price,
  });

  const updateItem = {
    category: product?.category
      ? product?.category
      : product?.extension_attributes?.category_names?.map((category) => ({
          name: category?.name,
          category_id: category?.id,
        })),
    sku: product?.sku
      ? product?.sku
      : product?.extension_attributes?.product_sku,
    name: Array.isArray(product?.name) ? product?.name : [product?.name],
    rewrite_url: product?.rewrite_url
      ? product?.rewrite_url
      : product?.extension_attributes?.rewrite_url,
    ...(product?._media_
      ? { _media_: product?._media_ }
      : {
          _media_: {
            image: [
              {
                image: `${process.env.NEXT_PUBLIC_IMG_BASEURL}/catalog/product/${product?.extension_attributes?.product_image}`,
              },
            ],
          },
        }),
    option_text_brand: Array.isArray(product?.brand_name)
      ? product?.brand_name
      : [product?.extension_attributes?.brand_name],
    prices_with_tax: {
      price: prices?.specialPrice,
      original_price: prices?.originalPrice,
    },
    ...(product?.stock
      ? { stock: product?.stock }
      : {
          stock: {
            qty,
            is_in_stock: true,
          },
        }),
  };

  return updateItem;
}

export function handleTime() {
  const date = new Date();

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = days[date.getDay()];
  const month = months[date.getMonth()];
  const dateNum = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  // Adjust for your local time zone offset
  const timezoneOffset = -date.getTimezoneOffset();
  const timezoneOffsetHours = Math.floor(timezoneOffset / 60)
    .toString()
    .padStart(2, "0");
  const timezoneOffsetMinutes = (timezoneOffset % 60)
    .toString()
    .padStart(2, "0");
  const timezoneSign = timezoneOffset >= 0 ? "+" : "-";
  const timezone = `GMT${timezoneSign}${timezoneOffsetHours}${timezoneOffsetMinutes}`;

  return `${day} ${month} ${dateNum} ${year} ${hours}:${minutes}:${seconds} ${timezone}`;
}

export function findObjectByIdV2(data, targetKey, searchValue) {
  if (data?.length) {
    for (const item of data) {
      if (item[targetKey] == searchValue) {
        return item;
      }

      if (item?.children_data && item?.children_data.length > 0) {
        const result = findObjectByIdV2(
          item?.children_data,
          targetKey,
          searchValue
        );
        if (result) {
          // prettyConole(searchValue, result)
          return result;
        }
      }
    }
  }
  // Target object not found
  return null;
}

export const excludeTimes = [
  setHours(setMinutes(new Date(), 0), 24),
  setHours(setMinutes(new Date(), 30), 24),
  setHours(setMinutes(new Date(), 0), 1),
  setHours(setMinutes(new Date(), 30), 1),
  setHours(setMinutes(new Date(), 0), 2),
  setHours(setMinutes(new Date(), 30), 2),
  setHours(setMinutes(new Date(), 0), 3),
  setHours(setMinutes(new Date(), 30), 3),
  setHours(setMinutes(new Date(), 0), 4),
  setHours(setMinutes(new Date(), 30), 4),
  setHours(setMinutes(new Date(), 0), 5),
  setHours(setMinutes(new Date(), 30), 5),
  setHours(setMinutes(new Date(), 0), 6),
  setHours(setMinutes(new Date(), 30), 6),
  setHours(setMinutes(new Date(), 0), 7),
  setHours(setMinutes(new Date(), 30), 7),
  setHours(setMinutes(new Date(), 0), 8),
  setHours(setMinutes(new Date(), 30), 8),
  setHours(setMinutes(new Date(), 30), 11),
  setHours(setMinutes(new Date(), 0), 12),
  setHours(setMinutes(new Date(), 30), 12),
  setHours(setMinutes(new Date(), 0), 13),
  setHours(setMinutes(new Date(), 30), 13),
  setHours(setMinutes(new Date(), 0), 14),
  setHours(setMinutes(new Date(), 30), 14),
  setHours(setMinutes(new Date(), 0), 15),
  setHours(setMinutes(new Date(), 30), 15),
];

export function decodeJwt(token, fromServer = true) {
  if (!token) {
    return;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  if (!fromServer) {
    return JSON.parse(window.atob(base64));
  } else {
    return JSON.parse(Buffer.from(base64, "base64").toString());
  }
}

export async function handleProductsWithRating(displayedProducts, triggerProductsRating, setProducts, fromCategory = false) {
  const entity_ids = (Array.isArray(displayedProducts) ? displayedProducts : [displayedProducts])?.map(item => item?.entity_id)
  if (entity_ids?.length > 0) {
    const result = await triggerProductsRating(entity_ids);
    
    const ratings = result?.data?.data;
    const displayedProductsWithRatings = (Array.isArray(displayedProducts) ? displayedProducts : [displayedProducts])?.map(item => {
      const selectedRating = ratings?.[item?.entity_id]
      
      return selectedRating ?  {
        ...item,
        ...selectedRating
      } : item
    })
    fromCategory ?  setProducts((prev) => [...prev, ...displayedProductsWithRatings]): setProducts(Array.isArray(displayedProducts) ? displayedProductsWithRatings : displayedProductsWithRatings?.[0])
    return displayedProductsWithRatings
  }
}

export function unescapeHtml(escapedHtml) {
  const htmlEntities = {
    "&amp;": "&",
    "&lt;": "<",
    "&gt;": ">",
    "&quot;": '"',
    "&#39;": "'",
  };

  return escapedHtml?.replace(
    /&amp;|&lt;|&gt;|&quot;|&#39;/g,
    (match) => htmlEntities[match]
  );
}

export function sortByProperty(arr, property) {
  console.log(arr);
  if (!Array.isArray(arr) || typeof property !== "string") {
    throw new Error("Invalid input");
  }

  return arr.sort((a, b) => {
    if (a[property] < b[property]) {
      return -1;
    }
    if (a[property] > b[property]) {
      return 1;
    }
    return 0;
  });
}
