const ProfileCircle = (props) => (
  <svg
    width={20}
    height={21}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.1004 11.8387C10.0837 11.8387 10.0587 11.8387 10.0421 11.8387C10.0171 11.8387 9.98372 11.8387 9.95872 11.8387C8.06706 11.7774 6.65039 10.2287 6.65039 8.32117C6.65039 6.37867 8.15872 4.79492 10.0087 4.79492C11.8587 4.79492 13.3671 6.37867 13.3671 8.32117C13.3587 10.2374 11.9337 11.7774 10.1254 11.8387C10.1087 11.8387 10.1087 11.8387 10.1004 11.8387ZM10.0004 6.09867C8.83372 6.09867 7.89206 7.09617 7.89206 8.31242C7.89206 9.51117 8.78372 10.4824 9.91706 10.5262C9.94206 10.5174 10.0254 10.5174 10.1087 10.5262C11.2254 10.4649 12.1004 9.50242 12.1087 8.31242C12.1087 7.09617 11.1671 6.09867 10.0004 6.09867Z"
      fill="currentColor"
    />
    <path
      d="M10 19.9065C7.75836 19.9065 5.61669 19.0315 3.95836 17.439C3.80836 17.299 3.74169 17.089 3.75836 16.8878C3.86669 15.8465 4.48336 14.8753 5.50836 14.1578C7.99169 12.4253 12.0167 12.4253 14.4917 14.1578C15.5167 14.884 16.1334 15.8465 16.2417 16.8878C16.2667 17.0978 16.1917 17.299 16.0417 17.439C14.3834 19.0315 12.2417 19.9065 10 19.9065ZM5.06669 16.7128C6.45003 17.929 8.19169 18.594 10 18.594C11.8084 18.594 13.55 17.929 14.9334 16.7128C14.7834 16.179 14.3834 15.6628 13.7917 15.2428C11.7417 13.8078 8.26669 13.8078 6.20003 15.2428C5.60836 15.6628 5.21669 16.179 5.06669 16.7128Z"
      fill="currentColor"
    />
    <path
      d="M9.99935 19.9062C5.05768 19.9062 1.04102 15.6888 1.04102 10.5C1.04102 5.31125 5.05768 1.09375 9.99935 1.09375C14.941 1.09375 18.9577 5.31125 18.9577 10.5C18.9577 15.6888 14.941 19.9062 9.99935 19.9062ZM9.99935 2.40625C5.74935 2.40625 2.29102 6.0375 2.29102 10.5C2.29102 14.9625 5.74935 18.5938 9.99935 18.5938C14.2493 18.5938 17.7077 14.9625 17.7077 10.5C17.7077 6.0375 14.2493 2.40625 9.99935 2.40625Z"
      fill="currentColor"
    />
  </svg>
);
export default ProfileCircle;
