// import { useSession } from "next-auth/react";
import { ModalContext } from "@/pages/_app";
import { useRouter } from "next/router";
import { useContext, useRef } from "react";

import Arrow from "@/components/icons/Arrow";
import MenuCategoryTree from "./MenuCategoryTree";
// import TrackOrder from "@/components/icons/TrackOrder";
import GridEditIcon from "@/components/icons/GridEditIcon";
import ProfileCircle from "@/components/icons/ProfileCircle";
import LocaleSwitcher from "@/components/global/buttons/LocaleSwitcher";
import CallIcon from "@/components/icons/CallIcon";
import EmailIcon from "@/components/icons/EmailIcon";
import WhatsAppIcon from "@/components/icons/WhatsAppIcon";
import Location from "@/components/icons/Location";
import { useBodyScrollLock } from "@/hooks/global/useBodyScrollLock";
import { useSession } from "next-auth/react";

const SideMenu = ({ categoryData, modalRef, setShowSideMenu, t }) => {
  const { locale, push } = useRouter();
  // const { data: session } = useSession();
  const Context = useContext(ModalContext);
  const { data: session } = useSession();
  useBodyScrollLock();

  const changeLangRef = useRef();

  return (
    <section className="fixed top-0 left-0 w-screen h-screen z-modal">
      <div className="flex">
        <div
          className="max-h-screen min-h-screen p-4 pb-3 overflow-y-auto bg-white w-80 sm:w-96"
          modalRef={modalRef}
        >
          <div
            onClick={() => {
              setShowSideMenu(false);
              Context?.dispatch({ type: "auth" });
            }}
            className="flex items-center justify-between pb-5 cursor-pointer"
          >
            <div className="flex items-center gap-2 text-primary">
              <ProfileCircle />
              <span className="text-sm font-bold">
              {!session
                ? t("Login / Register")
                : `${t("Welcome ,")} ${session?.user?.firstname} ${
                    session?.user?.lastname
                  }`}
            </span>
            </div>
            <Arrow
              className={`size-3 ${
                locale === "en" ? "rotate-0" : "rotate-180"
              } ps-1`}
            />
          </div>
          {/* <div
            onClick={() => {
              Context?.dispatch({ type: "trackOrder" });
              setShowSideMenu(false);
            }}
            className="flex items-center justify-between pb-5"
          >
            <div className="flex items-center gap-2">
              <TrackOrder />
              <span className="text-sm">{t("Track Order")}</span>
            </div>
            <Arrow
              className={`size-3 ${
                locale === "en" ? "rotate-0" : "rotate-180"
              } ps-1`}
            />
          </div> */}
          <div
            onClick={() => {
              setShowSideMenu(false);
              push("/branches");
            }}
            className="flex items-center justify-between pb-3"
          >
            <div className="flex items-center gap-2">
              <Location />
              <span className="text-sm">{t("Our Branches")}</span>
            </div>
            <Arrow
              className={`size-3 ${
                locale === "en" ? "rotate-0" : "rotate-180"
              } ps-1`}
            />
          </div>
          <MenuCategoryTree
            setShowSideMenu={setShowSideMenu}
            menuTreeData={categoryData?.children_data}
          />
          <div
            onClick={() => changeLangRef.current?.click()}
            className="flex items-center justify-between pt-3 cursor-pointer"
          >
            <div className="flex gap-2">
              <GridEditIcon className="mt-1" />
              <div className="flex flex-col gap-1">
                <span className="text-sm font-semibold">
                  {t("Change Language")}
                </span>
                <span className="text-sm text-zinc-500">
                  <LocaleSwitcher ref={changeLangRef} />
                </span>
              </div>
            </div>
            <Arrow
              className={`size-3 ${
                locale === "en" ? "rotate-0" : "rotate-180"
              }`}
            />
          </div>
          <div className="flex items-center gap-2 pt-3 pb-1">
            <CallIcon />
            <span className="font-normal">{t("Need Help?")}</span>
          </div>
          
          <div className="grid grid-cols-3 gap-2 pt-3 text-xs">
            <div className="flex items-center justify-center gap-1 p-1 border rounded">
              <CallIcon className="size-2.5" />
              <span>{t("Call Us")}</span>
            </div>
            <div className="flex items-center justify-center gap-1 p-1 border rounded">
              <WhatsAppIcon className="size-2.5" />
              <span>{t("Whats App")}</span>
            </div>
            <div className="flex items-center justify-center gap-1 p-1 border rounded">
              <EmailIcon className="size-2.5" />
              <span>{t("EmailAddress")}</span>
            </div>
          </div>
          <div
            onClick={() => {
              push("/static/faq");
              setShowSideMenu(false);
            }}
            className="flex items-center justify-between pt-3"
          >
            <span className="font-normal">{t("FAQ")}</span>
            <Arrow
              className={`size-3 ${
                locale === "en" ? "rotate-0" : "rotate-180"
              }`}
            />
          </div>
        </div>
        <div
          onClick={() => setShowSideMenu(false)}
          className="flex-1 bg-black bg-opacity-60"
        ></div>
      </div>
    </section>
  );
};

export default SideMenu;
