export const intialState = {
  modal: false,
  auth: false,
  forgot: false,
  otp: false,
  resetPassword: false,
  successPassword: false,
  sortBy: false,
  filter: false,
  changeEmail: false,
  changePassword: false,
  addNewAddress: false,
  editAddress: false,
  payload: false,
  rateProduct: false,
  returnOrder: false,
  addCoupon: false,
  processingPopUp: false,
  refetchCart: null,
  addVoucher: false,
  stcPayOtp: false,
  quaraPayOtp: false,
  editCheckoutAddress: false,
  addCheckoutAddress: false,
  personalInformations: false,
  personalPayload: null,
  setPersonalData: null,
  viewAllCheckoutAddresses: false,
  storePickUp: false,
  storePayload: [],
  setFieldValue: null,
  addToCartModal: false,
  trackOrder: false,
  select_city: false,
};

export const modalReducer = (state = intialState, action) => {
  switch (action.type) {
    case "addToCartModal":
      return {
        ...intialState,
        addToCartModal: !state.addToCartModal,
      };
    case "storePickUp":
      return {
        ...intialState,
        modal: !state.modal,
        storePickUp: !state.storePickUp,
        storePayload: action.storePayload || [],
        setFieldValue: action.setFieldValue || null,
      };
    case "personalInformations":
      return {
        ...intialState,
        modal: !state.modal,
        personalInformations: !state.personalInformations,
        personalPayload: action.personalPayload,
        setPersonalData: action.setPersonalData,
      };
    case "sortBy":
      return {
        ...intialState,
        modal: !state.modal,
        sortBy: !state.sortBy,
      };
    case "trackOrder":
      return {
        ...intialState,
        modal: !state.modal,
        trackOrder: !state.trackOrder,
      };
    case "auth":
      return {
        ...intialState,
        modal: !state.modal,
        auth: !state.auth,
      };
    case "forgot":
      return {
        ...intialState,
        modal: !state.modal,
        forgot: !state.forgot,
      };
    case "otp":
      return {
        ...intialState,
        modal: !state.modal,
        otp: !state.otp,
      };
    case "resetPassword":
      return {
        ...intialState,
        modal: !state.modal,
        resetPassword: !state.resetPassword,
      };
    case "successPassword":
      return {
        ...intialState,
        modal: !state.modal,
        successPassword: !state.successPassword,
      };
    case "filter":
      return {
        ...intialState,
        modal: !state.modal,
        filter: !state.filter,
      };
    case "changeEmail":
      return {
        ...intialState,
        modal: !state.modal,
        changeEmail: !state.changeEmail,
        setPersonalData: action.setPersonalData,
      };
    case "changePassword":
      return {
        ...intialState,
        modal: !state.modal,
        changePassword: !state.changePassword,
      };
    case "changePhone":
      return {
        ...intialState,
        modal: !state.modal,
        changePhone: !state.changePhone,
        setPersonalData: action.setPersonalData,
      };
    case "addNewAddress":
      return {
        ...intialState,
        modal: !state.modal,
        addNewAddress: !state.addNewAddress,
        payload: action.payload,
      };
    case "editAddress":
      return {
        ...intialState,
        modal: !state.modal,
        editAddress: !state.editAddress,
        payload: action.payload,
      };
    case "rateProduct":
      return {
        ...intialState,
        modal: !state.modal,
        rateProduct: !state.rateProduct,
      };
    case "returnOrder":
      return {
        ...intialState,
        modal: !state.modal,
        returnOrder: !state.returnOrder,
      };
    case "addCoupon":
      return {
        ...intialState,
        modal: !state.modal,
        addCoupon: !state.addCoupon,
        refetchCart: action.refetchCart,
      };
    case "processingPopUp":
      return {
        ...intialState,
        modal: !state.modal,
        processingPopUp: !state.processingPopUp,
        payload: action.payload,
      };
    case "addVoucher":
      return {
        ...intialState,
        modal: !state.modal,
        addVoucher: !state.addVoucher,
      };
    case "stcPayOtp":
      return {
        ...intialState,
        modal: !state.modal,
        stcPayData: action?.payload,
        stcPayOtp: !state.stcPayOtp,
      };
      case "quaraPayOtp":
      return {
        ...intialState,
        modal: !state.modal,
        quaraPayData: action?.payload,
        quaraPayOtp: !state.quaraPayOtp,
      };
    case "editCheckoutAddress":
      return {
        ...intialState,
        modal: !state.modal,
        payload: action.payload,
        editCheckoutAddress: !state.editCheckoutAddress,
      };
    case "addCheckoutAddress":
      return {
        ...intialState,
        modal: !state.modal,
        payload: action.payload,
        addCheckoutAddress: !state.addCheckoutAddress,
      };
    case "viewAllCheckoutAddresses":
      return {
        ...intialState,
        modal: !state.modal,
        viewAllCheckoutAddresses: !state.viewAllCheckoutAddresses,
        payload: action.payload,
      };
    case "select_city":
      return {
        ...intialState,
        modal: !state.modal,
        select_city: !state.select_city,
        payload: action.payload,
      };
    case "closeAll":
      return {
        ...intialState,
      };
    default:
      return {
        ...intialState,
      };
  }
};
