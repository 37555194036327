import { personalInformationValidation } from "@/utils/valitation/user";
import { changeUserName } from "@/services/user/account";
import { useTranslation } from "next-i18next";
import { useSession } from "next-auth/react";
import { getCookie } from "cookies-next";
import { Form, Formik } from "formik";
import { useState } from "react";

import Input from "../Input";
import toast from "react-hot-toast";

const PersonalInformationsModal = ({ Context }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const handshake = getCookie("handshake");
  const { data: session } = useSession();
  const [loading, setLoading] = useState(false);
  const {setPersonalData} = Context?.state

  const handleSubmit = async ({ firstName, lastName, currentPassword }) => {
    setLoading(true);
    const res = await changeUserName(
      handshake,
      session?.mage,
      session?.user.id,
      currentPassword,
      firstName,
      lastName
    );
    if (!res.ok && res.status !== 200) {
      toast.error(
        language === "en"
          ? "Error Invalid, Please try again"
          : "برجاء المحاولة مره اخرى"
      );
      setLoading(false);
    } else {
      toast.success(
        language === "en"
          ? "Personal Informations Changes Successfully"
          : "تم تعديل المعلومات الشخصية بنجاح"
      );
      Context?.dispatch({ type: "closeAll" });
      setLoading(false);
      setPersonalData(prev => ({...prev, firstname:firstName, lastname:lastName}))
    }
  };
  return (
    <Formik
      initialValues={{
        firstName: Context?.state?.personalPayload?.firstname || "UnKnown",
        lastName: Context?.state?.personalPayload?.lastname || "UnKnown",
        currentPassword: "",
      }}
      validationSchema={personalInformationValidation(t)}
      onSubmit={handleSubmit}
    >
      <Form className="grid grid-cols-1 gap-3 mt-3">
        <Input
          id="first-name"
          name="firstName"
          placeholder=""
          label={t("First Name")}
          labelClassName="text-zinc-600 font-semibold"
          t={t}
        />
        <Input
          id="last-name"
          name="lastName"
          placeholder=""
          label={t("Last Name")}
          labelClassName="text-zinc-600 font-semibold"
          t={t}
        />
        <Input
          id="current-password"
          name="currentPassword"
          placeholder=""
          label={language === "en" ? "Current Password" : "كلمة المرور الحالية"}
          type="password"
          labelClassName="text-zinc-600 font-semibold"
          t={t}
        />
        <button
          type="submit"
          disabled={loading}
          className="flex items-center justify-center w-full px-3 py-2 font-semibold text-white bg-orange-600 border border-orange-600 rounded-md disabled:cursor-not-allowed"
        >
          {loading ? (
            <span className="w-6 loader flex items-center justify-center"></span>
          ) : (
            t("Update Personal Informations")
          )}
        </button>
      </Form>
    </Formik>
  );
};

export default PersonalInformationsModal;
