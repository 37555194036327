import React from "react";
import Image from "../Image";

const Logo = () => {
  return (
    <Image
      src="/blackbox.webp"
      className="w-32 h-10  md:w-44 md:h-14 object-contain"
      alt="Blackbox"
    />
  );
};

export default Logo;
