import { setShippingAddress } from "@/services/checkout";
import { getCookies } from "cookies-next";
import toast from "react-hot-toast";
import { event_error } from "../gtm";

export const handleShippingAddress = async (
  address,
  dispatch,
  shipping_method,
  am_pickup = null,
  locale
) => {
  const { handshake, mage, quote } = getCookies();
  const { data, error } = await setShippingAddress(
    handshake,
    mage,
    quote,
    address,
    shipping_method != "amstorepickup_amstorepickup"
      ? shipping_method
      : "amstorepickup",
    am_pickup
  );
  if (error === null) {
    const msg =
      data?.message === "Shipping Address Added SuccessFully" && locale === "ar"
        ? "تم حفظ عنوان الشحن بنجاح"
        : data?.message;
    toast.success(msg);
    dispatch({ type: "shipping_address", payload: address });
    dispatch({ type: "pickup_store", payload: { pickupDetails: am_pickup } });
    dispatch({ type: "cart", payload: data?.data?.totals });
    dispatch({ type: "payments", payload: data.data?.payment_methods });
    return {
      payment_methods: data?.data?.payment_methods,
      totals: data?.data?.totals,
    }
  } else {

    typeof window != undefined && event_error({
      error_message: "Error Occured, please try again",
      error_type: "Request Error",
      error_url: process.env.NEXT_PUBLIC_WEBSITE_URL + window.location.pathname
    })

    toast.error(error?.error?.message);
    return false;
  }
};
