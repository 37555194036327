import { useEffect, useRef } from "react";

const useOnClickOutsideV2 = (refs, callback) => {
  const callbackRef = useRef();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        refs.every((ref) => ref.current && !ref.current.contains(event.target)) &&
        !isScrollbarClick(event)
      ) {
        callbackRef.current(event);
      }
    };
    
    function isScrollbarClick(event) {
      const tag = event?.target?.tagName?.toLowerCase()

      return tag === 'html'
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refs]);
};

export default useOnClickOutsideV2;
