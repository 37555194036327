import CloseIcon from "@/components/icons/CloseIcon";
import Star from "@/components/icons/Star";
import { addRateProduct } from "@/services/user/orders";
import { getCookies } from "cookies-next";
import Image from "@/components/Image";
import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { object, string } from "yup";
import useOnClickOutside from "@/hooks/useOnClickOutside";
import SarIcon from "@/components/icons/Sar";

const RateProduct = ({ setShowRateProduct, selectedProduct, t }) => {
  const [rate, setRate] = useState(0);
  const [error, setError] = useState(false);
  const { handshake } = getCookies();

  const handleSubmit = async (values) => {
    if (rate) {
      const res = await addRateProduct(
        handshake,
        rate,
        values?.review,
        selectedProduct?.product_id
      );
      if (res?.status === 200) {
        toast.success(t(res?.data?.message));
        setShowRateProduct(false);
      }
    } else {
      setError(true);
    }
  };

  const validationSchema = object().shape({
    review: string().trim().required(t("Please add a review")),
  });
  const ref = useRef();
  useOnClickOutside(ref, () => setShowRateProduct(false));

  return (
    <div className="bg-white w-11/12 mx-auto my-auto sm:w-[640px] max-h-[95vh] overflow-y-auto p-3 rounded-sm">
      <div className="flex items-center justify-between pb-2 border-b">
        <h2 className="font-bold sm:text-lg">{t("Rate Product")}</h2>
        <CloseIcon
          className="cursor-pointer size-5"
          onClick={() => setShowRateProduct(false)}
        />
      </div>
      <div className="flex flex-col gap-4 p-3 my-2 border rounded-sm sm:flex-row">
        <div className="w-36 aspect-[1/0.8] relative overflow-hidden">
          <Image
            loading="lazy"
            // src={`/imgs/home/product-1.png`}
            src={`${process.env.NEXT_PUBLIC_IMG_BASEURL}/catalog/product${selectedProduct?.extension_attributes?.product_image}`}
            className="object-contain p-2 border rounded-md"
            fill={true}
            alt=""
          />
        </div>
        <div className="flex flex-col flex-1">
          <Image
            loading="lazy"
            src="/imgs/home/product-brand.png"
            className="object-cover"
            height={25}
            width={25}
            alt=""
          />
          <p className="font-bold sm:line-clamp-2 my-0.5 sm:w-3/4">
            {selectedProduct?.name || ""}
          </p>
          <div className="text-lg font-bold text-orange-600">
            {selectedProduct?.price ? (
              <h4 className="text-lg font-bold text-orange-600">
                {Intl.NumberFormat("en-US").format(selectedProduct?.price)}{" "}
                <SarIcon className=" relative inline-block h-6 w-6"/>
              </h4>
            ) : null}
            {selectedProduct?.original_price
              ? selectedProduct?.original_price > selectedProduct?.price && (
                  <h2 className="line-through text-zinc-500">
                    {Intl.NumberFormat("en-US").format(
                      selectedProduct?.original_price
                    )}{" "}
                    <SarIcon className=" relative inline-block h-6 w-6"/>
                  </h2>
                )
              : null}
          </div>
        </div>
      </div>
      <div ref={ref} className="my-2">
        <h2 className="text-lg font-bold">{t("Add your Review")}</h2>
        <div className="flex items-center gap-2 my-2">
          {Array(5)
            .fill()
            .map((_i, key) => (
              <Star
                key={key}
                onClick={() => {
                  setRate(key + 1);
                  setError(false);
                }}
                className={`size-[18px] cursor-pointer ${
                  rate >= key + 1 ? "text-yellow-500" : "text-zinc-200"
                }`}
              />
            ))}
        </div>
        {error ? (
          <p className="text-red-500">{t("Please add a rate")}</p>
        ) : null}
      </div>
      <Formik
        initialValues={{
          review: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field
              component={"textarea"}
              className="w-full p-2 border rounded-md outline-none h-28"
              placeholder={t("Add your review description")}
              name="review"
              // value       = {desc}
              // onChange    = {(e) => setDesc(e.target.value)}
            />
            <ErrorMessage
              name="review"
              component={"p"}
              className="text-red-600"
            />
            <button
              disabled={isSubmitting}
              className="flex items-center justify-center w-full gap-2 py-2 text-white bg-orange-600 rounded-md"
              type="submit"
            >
              {isSubmitting && <div className="w-6 loader" />}
              <p>{t("Add")}</p>
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RateProduct;
