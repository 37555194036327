import { metadata } from "@/constants/Home";
import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";
const Seo = ({
  title,
  description,
  image = null,
  keywords = null,
  children,
}) => {
  const { locale, asPath } = useRouter();
  const newTitle =
    (title
      ? `${title} | ${metadata[locale]?.title}`
      : metadata[locale]?.blackbox_title) || "";
  const newDescription = description || metadata[locale]?.blackbox_description;
  const img =
    image || `${process.env.NEXT_PUBLIC_WEBSITE_URL}/imgs/blackbox.jpg`;
  const url = `${process.env.NEXT_PUBLIC_WEBSITE_URL}${
    locale === "ar" ? "" : "/en"
  }${asPath === "/" ? "" : asPath}`;
  console.log("seo", { title, description, url, image });
  return (
    <Head>
      <title>{newTitle}</title>
      {/* <link
        rel="canonical"
        href={`${process.env.NEXT_PUBLIC_WEBSITE_URL}${
          locale === "en" ? "/en" : ""
        }${asPath}`}
      /> */}
      {keywords && <meta name="keywords" content={keywords} />}
      <meta name="title" content={newTitle} />
      <meta name="description" content={newDescription} />

      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={url || process.env.NEXT_PUBLIC_WEBSITE_URL}
      />
      <meta property="og:title" content={newTitle} />
      <meta property="og:description" content={newDescription} />
      <meta property="og:image" content={img} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:url"
        content={url || process.env.NEXT_PUBLIC_WEBSITE_URL}
      />
      <meta property="twitter:title" content={newTitle} />
      <meta property="twitter:description" content={newDescription} />
      <meta property="twitter:image" content={img} />
      {children}
    </Head>
  );
};

export default Seo;
