import AddEditCheckoutAddress from "@/components/forms/checkout/addresses/AddEditCheckoutAddress";
import CloseIcon from "@/components/icons/CloseIcon";
import { useCheckout } from "@/context/checkout";
import { useTranslation } from "next-i18next";

const EditCheckoutAddress = ({ Context, editedAddress }) => {
  const { t } = useTranslation("checkout");
  const { checkout } = useCheckout();

  return (
    <div className="bg-white p-3 sm:w-[640px] max-h-[90vh] overflow-y-auto rounded-md w-11/12 mx-auto">
      <div className="flex items-center justify-between pb-2 border-b">
        <h1 className="font-bold sm:text-lg">{t("Edit Address")}</h1>
        <button
          onClick={() => Context?.dispatch({ type: "editCheckoutAddress" })}
        >
          <CloseIcon className="size-3.5 object-contain" />
        </button>
      </div>
      <AddEditCheckoutAddress
        isPopup={true}
        editedAddress={editedAddress || checkout?.shippingAddress}
      />
    </div>
  );
};

export default EditCheckoutAddress;
