import BranchInfo from "@/components/branches/BranchInfo";
import Map from "@/components/branches/Map";
import CloseIcon from "@/components/icons/CloseIcon";
import useOnClickOutside from "@/hooks/useOnClickOutside";

import { useTranslation } from "next-i18next";
import {useRef, useState } from "react";

const StorePickUpModal = ({ Context }) => {
  const [branchInView, setBranchInView] = useState()
  const { t } = useTranslation("common");
  const [map, setMap] = useState(null);
  const ref = useRef(null);
  const data = Context?.state?.storePayload || [];
  const setFieldValue = Context?.state?.setFieldValue || null;

  useOnClickOutside(ref, () => Context?.dispatch({ type: "storePickUp" }));

  console.log("!!!!!!!!!!!", data)
  return (
    <div
      ref={ref}
      className="grid w-11/12 grid-cols-12 gap-4 h-[90vh] overflow-y-auto p-3 mx-auto bg-white"
    >
      <div className="flex items-center justify-between col-span-full">
        <h2 className="text-lg font-extrabold sm:text-xl">
          {t("Our Branches")}
        </h2>
        <CloseIcon
          className="size-6 cursor-pointer"
          onClick={() => Context?.dispatch({ type: "storePickUp" })}
        />
      </div>
      <div className="col-span-full max-lg:flex max-lg:gap-4 max-lg:overflow-x-auto lg:col-span-4 lg:order-1 order-2 *:mb-4 max-h-screen mb-6 overflow-y-auto red-scrollbar">
        {data.map((info, i) => (
          <BranchInfo key={i} t={t} info={info} map={map} setFieldValue={setFieldValue} branchInView={branchInView} setBranchInView={setBranchInView} />
        ))}
      </div>
      <div className="order-1 col-span-full lg:col-span-8 lg:order-2 ">
        <Map fromModal data={data} setMap={setMap} branchInView={branchInView} setBranchInView={setBranchInView} />
      </div>
    </div>
  );
};

export default StorePickUpModal;
