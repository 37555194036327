export const customStyles = {
  control: (provided) => ({
    ...provided,
    boxShadow: "none",
    padding: "0.2rem",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "none",
    },
    border: "1px solid #cbd5e1",
    '&:hover':{
      borderColor: "#fed7aa",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#f97316" : "white",
    color: "black",
    border: "none",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f97316",
      color: "white",
    },
  }),
  menu: (provided) => ({
    ...provided,
    borderColor: "white",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  }),
};
