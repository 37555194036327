import { useGlobalData } from "@/context/global";
import { useEffect } from "react";
import useSWR from "swr";
import store from "store";
import { useRouter } from "next/router";
import { getCookie, setCookie } from "cookies-next";
import { decodeJwt } from "@/utils/global";
import axiosRequest from "@/utils/axios-config";

export default function useGettingCities() {
  const { locale } = useRouter();
  const { dispatch } = useGlobalData();
  const handshake = getCookie("handshake");

  const decodedToken = decodeJwt(handshake);

  const { mutate } = useSWR(
    () => "/customer/getCities",
    async (url) => {
      const { data, error, status } = await axiosRequest({
        method: "GET",
        url,
        headers: {
          Authorization: `Bearer ${handshake}`,
          "Content-Type": "application/json",
        },
      });

      return { data, error, status };
    },
    {
      revalidateOnMount: false, // 30 minutes in milliseconds
      revalidateOnFocus: false,
      onSuccess: (data) => {
        dispatch({
          type: "get_cities",
          payload: {
            data: [...(data?.data?.data || {})],
            cacheTime: Date.now() + 30 * 60 * 1000,
          },
        });

        return data;
      },
    }
  );

  useEffect(() => {
    if (
      store.get("app_data")?.allCities?.cacheTime > Date.now() &&
      locale === decodedToken?.storeName
    ) {
      mutate();
      dispatch({
        type: "get_cities",
        payload: {
          data: [...store.get("app_data")?.allCities?.data],
          cacheTime: store.get("app_data")?.allCities?.cacheTime,
        },
      });
      return;
    } else {
      mutate();
      setCookie("cityName", null);
    }
  }, [decodedToken?.storeName, dispatch, locale, mutate]);

  return null;
}
