export function groupBy(arr, prop) {
  return arr?.reduce((acc, curr) => {
    acc[curr[prop]] = curr;
    return acc;
  }, {});
}

export function validateExpirationDate(dateFrom, dateTo) {
  const now = new Date();
  const from = new Date(dateFrom);
  const to = new Date(dateTo);
  return now > from && now < to;
}

export function parseJwt(token) {
  try {
    const tokenArr = (token || "")?.split(".");
    const decodedPayload = JSON.parse(
      Buffer.from(tokenArr?.at(1), "base64").toString("utf-8")
    );
    return decodedPayload;
  } catch (error) {
    console.error("Error decoding token:", error);
    return null;
  }
}

export function decodeJwt(token, fromServer = true) {
  if (!token) {
    return;
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  if (!fromServer) {
    return JSON.parse(window.atob(base64));
  } else {
    return JSON.parse(Buffer.from(base64, 'base64').toString());
  }
}

export function createOnceFunction(fn) {
  let called = false;
  return function (...args) {
    if (!called) {
      called = true;
      return fn(...args);
    } else {
      console.log("Function has already been called once.");
    }
  };
}

export function compareObjects(obj1, obj2) {
  // Get the keys of the objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Iterate over the keys and compare the values
  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  // If all keys and values are equal, return true
  return true;
}
