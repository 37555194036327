import { useTranslation } from "next-i18next";
import CloseIcon from "../icons/CloseIcon";
import SuccessPasswordIcon from "../icons/SuccessPasswordIcon";

const SuccessPassword = ({ Context }) => {
  const { t } = useTranslation("common");
  return (
    <section className="bg-white w-11/12 mx-auto sm:w-[640px] max-h-[90vh] overflow-y-auto p-4 rounded-md">
      <div className="flex items-center justify-between pb-2 border-b">
        <h2 className="font-bold text-lg">{t("Change Password")}</h2>
        <button onClick={() => Context?.dispatch({ type: "successPassword" })}>
          <CloseIcon className="size-5" />
        </button>
      </div>
      <SuccessPasswordIcon className="w-80 h-60 mx-auto my-2" />
      <p className="text-orange-600 font-bold text-lg text-center my-2">
        {t("The password has been changed successfully")}
      </p>
      <p className="my-2 text-center font-semibold">
        {t("Please log in to enjoy Our features")}
      </p>
      <button
        className="w-11/12 mx-auto bg-orange-600 text-white p-2 rounded-md"
        type="button"
        onClick={() => {
          Context?.dispatch({ type: "successPassword" });
          Context?.dispatch({ type: "auth" })
        }}
      >
        {t("Back to Login")}
      </button>
    </section>
  );
};

export default SuccessPassword;
