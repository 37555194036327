// GET ORDER BY ID
import axios from "@/utils/axios-config";
import * as Sentry from "@sentry/react";
import { getOrderByID } from "../success";

const getAllUserOrders = async (handshake, userId, pageSize = 20, pageNo = 0) => {
  const url = `/customer/orders?searchCriteria[sortOrders][0][field]=created_at&searchCriteria[sortOrders][0][direction]=DESC&searchCriteria[filter_groups][0][filters][0][field]=customer_id&searchCriteria[filter_groups][0][filters][0][value]=${userId}&searchCriteria[page_size]=${pageSize}&searchCriteria[current_page]=${pageNo}`
  console.log('url', url)
  let config = {
    method: "GET",
    url,
    headers: {
      'Authorization':`Bearer ${handshake}`,
      admin: "Bearer " + process.env.NEXT_PUBLIC_ADMIN_TOKEN,
    },
  };
  try {
    const req = await axios(config);
    return req
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

const addRateProduct = async (handshake, rate, desc, product_id) => {
  const data = JSON.stringify({
    "review": {
      "title": "Review Title",
      "detail": desc ? desc : "This is review details",
      "nickname": "Yaseen",
      "ratings": [
        {
          "rating_name": "Rating",
          "value": rate
        },
        // {
        //   "rating_name": "Quality",
        //   "value": 1
        // }
      ],
      "review_entity": "product",
      "review_status": 1,
      "entity_pk_value": product_id
    }
  });

  let config = {
    method: "POST",
    url: "/product/reviews/add",
    headers: {
      'Authorization':`Bearer ${handshake}`,
      // admin: "Bearer " + process.env.NEXT_PUBLIC_ADMIN_TOKEN,
    },
    data
  };
  try {
    const req = await axios(config);
    return req
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

const returnProducts = async (handshake, mage, orderId, items) => {
  let config = {
    method: "POST",
    url: `/customer/returnOrder/${orderId}`,
    headers: {
      'Authorization':`Bearer ${handshake}`,
      // admin: "Bearer " + process.env.NEXT_PUBLIC_ADMIN_TOKEN,
      mage: "Bearer " + mage,
    },
    data: JSON.stringify({"order_item_ids" : items})
    }
  try {
    const req = await axios(config);
    return req
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

export {getAllUserOrders, addRateProduct, returnProducts, getOrderByID}