import { Form, Formik } from "formik";
import Input from "../Input";
import { changeUserEmail } from "@/services/user/account";
import { getCookies } from "cookies-next";
import { useSession } from "next-auth/react";
import toast from "react-hot-toast";
import { ModalContext } from "@/pages/_app";
import { useContext } from "react";

const UpdateEmail = ({ t }) => {
  const { handshake, mage } = getCookies();
  const session = useSession();
  const user = session?.data?.user;
  const Context = useContext(ModalContext);
  const setPersonalData = Context?.state?.setPersonalData;

  async function handleSubmit(values) {
    const res = await changeUserEmail(
      handshake,
      mage,
      user?.id,
      values?.newEmail,
      values?.password
    );
    if (res?.status == 200) {
      toast.success(res?.data?.message);
      setPersonalData((prev) => ({ ...prev, email: values?.newEmail }));
      Context?.dispatch({ type: "changeEmail" });
    } else {
      toast.error(res?.error?.message);
    }
  }
  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ newEmail: "", password: "" }}
    >
      {({ isSubmitting }) => (
        <Form className="mt-3 grid grid-cols-1 gap-3">
          <Input
            id="new-email"
            name="newEmail"
            placeholder=""
            label={t("NewEmail")}
            type="email"
            labelClassName="text-zinc-600 font-semibold"
            t={t}
          />
          <Input
            id="password"
            name="password"
            placeholder=""
            label={t("Password")}
            type="password"
            labelClassName="text-zinc-600 font-semibold"
            t={t}
          />
          <button
            type="submit"
            className="bg-orange-600 flex justify-center items-center gap-2 border rounded-md border-orange-600 text-white py-2 px-3 w-full font-semibold"
          >
            {isSubmitting && <div className="w-6 loader" />}
            <p>{t("updateEmail")}</p>
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateEmail;
