// import dynamic from "next/dynamic";
import Arrow from "../icons/Arrow";
import ProductCard from "../cards/ProductCard";

import { useEffect, useState } from "react";
import { event_view_products_list } from "@/utils/gtm";

const ProductsList = ({
  allProducts,
  totalProducts,
  sortKeys,
  handleSorting,
  t,
  fromCategory,
  categoryName,
  categoryID,
}) => {
  const [sortName, setSortName] = useState("Most Views");
  console.log('allProducts', allProducts)
  useEffect(() => {
    event_view_products_list(allProducts);
  }, [allProducts]);

  return (
    <div className="col-span-full lg:col-span-9">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 sm:gap-5">
        <div className="flex flex-wrap items-center justify-between gap-5 col-span-full">
          <div className="flex flex-col">
            <h1 className="text-lg font-semibold uppercase sm:text-xl">
              {fromCategory ? categoryName : t("SearchResult")}
            </h1>
            <span className="text-sm sm:text-base">
              {t("Showing")} 1-{allProducts?.length} {t("of")} {totalProducts}
            </span>
          </div>
          <div className="relative items-center justify-between hidden w-full gap-1 p-2 border rounded-md text-[15px] cursor-pointer min-w-52 group sm:w-48 text-slate-600 lg:flex">
            <span className="font-normal text-slate-500">{t(sortName)}</span>
            <Arrow className="size-3 rotate-90 mt-0.5" />
            <ul className="hidden group-hover:block absolute top-full left-0 w-full min-w-52 border shadow-lg rounded-md bg-white p-2 z-50 *:text-[15px] *:text-black *:cursor-pointer">
              {sortKeys?.map((item, key) => (
                <li
                  key={key}
                  onClick={() => {
                    handleSorting({ keys: item?.key });
                    setSortName(item?.label);
                  }}
                  className={`hover:text-secondary-700 ${
                    sortKeys?.length - 1 == key ? "mb-0" : "mb-1.5"
                  } `}
                >
                  {t(item?.label)}
                </li>
              ))}
            </ul>
          </div>
        </div>
        {allProducts?.map((product, i) => (
          <ProductCard {...{categoryName, categoryID, product}} key={i} />
        ))}
        <div id="afterProductsSection" className="w-full"></div>
      </div>
    </div>
  );
};

export default ProductsList;
