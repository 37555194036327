import React from "react";
const VoucherIcon = ({ width = 24, height = 25, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    {...props}
  >
    <path
      d="M14.89 18.98H6.1c-.52 0-1.01-.053-1.45-.157a3.6 3.6 0 0 1-.8-.23c-1.33-.52-2.9-1.801-2.9-4.989V8.24c0-3.407 1.88-5.365 5.15-5.365h8.79c2.76 0 4.54 1.375 5.02 3.875.09.458.13.948.13 1.49v5.364c0 3.427-1.87 5.375-5.15 5.375M6.11 4.458c-2.46 0-3.65 1.24-3.65 3.802v5.364c0 1.865.63 3.01 1.92 3.521q.3.11.57.156c.38.084.75.125 1.16.125h8.79c2.46 0 3.65-1.24 3.65-3.802V8.261c0-.438-.03-.823-.1-1.178-.34-1.77-1.5-2.624-3.55-2.624z"
      fill="currentColor"
    />
    <path
      d="M17.89 22.104H9.1c-.85 0-1.61-.125-2.26-.385-1.47-.573-2.43-1.781-2.76-3.51a.79.79 0 0 1 .21-.709.71.71 0 0 1 .69-.198c.33.073.7.115 1.12.115h8.79c2.46 0 3.65-1.24 3.65-3.802V8.25c0-.437-.03-.823-.1-1.177-.05-.26.03-.52.2-.708a.7.7 0 0 1 .68-.209c2.4.51 3.72 2.365 3.72 5.22v5.364c0 3.416-1.87 5.364-5.15 5.364M5.92 18.98c.32.605.8 1.032 1.46 1.282.48.187 1.06.28 1.73.28h8.79c2.46 0 3.65-1.239 3.65-3.801v-5.365c0-1.646-.49-2.74-1.5-3.312v5.552c0 3.406-1.88 5.364-5.15 5.364z"
      fill="currentColor"
    />
    <path
      d="M10.5 14.469c-1.87 0-3.39-1.583-3.39-3.531s1.52-3.532 3.39-3.532 3.39 1.584 3.39 3.532-1.52 3.531-3.39 3.531m0-5.5c-1.04 0-1.89.885-1.89 1.969 0 1.083.85 1.968 1.89 1.968s1.89-.885 1.89-1.968c0-1.084-.85-1.97-1.89-1.97M4.78 14.01c-.41 0-.75-.354-.75-.78V8.645c0-.427.34-.781.75-.781s.75.354.75.781v4.583c0 .428-.33.782-.75.782m11.43 0c-.41 0-.75-.354-.75-.78V8.645c0-.427.34-.781.75-.781s.75.354.75.781v4.583c0 .428-.33.782-.75.782"
      fill="currentColor"
    />
  </svg>
);
export default VoucherIcon;
