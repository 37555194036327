import CartIcon from "@/components/icons/CartIcon";

import { useCart } from "@/context/cart";
import { ModalContext } from "@/pages/_app";
import { useContext, useRef, useState } from "react";
import { useTranslation } from "next-i18next";
import { handleAddItemToCart } from "@/utils/product/addToCartFunc";
import { useRouter } from "next/router";
import { getCookies } from "cookies-next";
import { notifyWhenAvailableProduct } from "@/services/product";
import toast from "react-hot-toast";
import { useSession } from "next-auth/react";
import { useGlobalData } from "@/context/global";
import store from "store";
import { event_error } from "@/utils/gtm";

const AddToCart = ({
  product,
  categoryName,
  categoryID,
  qty = 1,
  configurations,
  fromPDP = false,
  // fastBuy = false,
}) => {
  const addtoCartRef = useRef(null);
  // const fastPayRef = useRef(null);
  const { t } = useTranslation("common");
  const { dispatch } = useCart();
  const {
    appData: { reg },
  } = useGlobalData();
  const [loading, setLoading] = useState(false);
  // const [loadingFast, setLoadingFast] = useState(false);
  const Context = useContext(ModalContext);

  const { locale, asPath } = useRouter();

  const { handshake } = getCookies();

  const { data: session } = useSession();

  const handleNotifyMe = async (entity_id) => {
    if (!session?.user) {
      Context?.dispatch({ type: "auth" });
      store.set("notify-me-product", entity_id);
      return;
    }
    setLoading(true);

    const res = await notifyWhenAvailableProduct({
      handshake,
      userId: session?.user?.id,
      entity_id,
    });
    const { error, message } = res?.data?.data?.[0];

    if (error) {
      event_error({
        error_message: message || error,
        error_type: "Request Error",
        error_url: process.env.NEXT_PUBLIC_WEBSITE_URL + asPath,
      });

      toast.error(
        message ||
          (locale === "en"
            ? "Please Login and try again"
            : "برجاء تسجيل الدخول والمحاولة مره أخرى")
      );
    } else {
      toast.success(
        // message ||
        locale === "en"
          ? "Alert subscription has been saved for this product."
          : "تم تسجيل الاشتراك في هذا المنتج."
      );
    }
    setLoading(false);
  };

  return (
    <>
      <div className="flex w-full gap-4">
        {fromPDP &&
          ( product?.alKhobarRegion <= 0) && ( //product?.stock?.qty === 0 || !product?.stock?.is_in_stock ||
            <div>
              <p className="flex items-center justify-center px-10 py-2 text-white bg-red-600 rounded-md disabled:opacity-75">
                {t("Out of stock and will be available and will be available soon")}
              </p>
            </div>
          )}
        <button
          ref={addtoCartRef}
          disabled={loading}
          title={product?.alKhobarRegion > 0 ? t("Add to cart") : t("Out of stock and will be available")}
          onClick={() => {
            if (!new RegExp("^\\d+$").test(reg?.id || "")) {
              Context?.dispatch({
                type: "select_city",
                payload: { ref: addtoCartRef },
              });
            } else {
               product?.alKhobarRegion > 0 //product?.stock?.is_in_stock &&
                ? handleAddItemToCart(
                    product,
                    qty,
                    setLoading,
                    dispatch,
                    reg || {},
                    configurations,
                    Context,
                    locale,
                    categoryName, 
                    categoryID
                  )
                : fromPDP
                ? handleNotifyMe(product?.entity_id)
                : null;
            }
          }}
          className={`flex items-center text-black justify-center gap-2 w-full
            transition-colors duration-500 disabled:opacity-75 flex-1 border py-2 rounded-md  
            ${
              product?.back_orders === 101
                ? " bg-red-600 text-white hover:bg-red-500 "
                : product?.alKhobarRegion > 0   //product?.stock?.is_in_stock && 
                ? "bg-primary hover:bg-orange-500 text-white "
                : fromPDP
                ? "bg-primary hover:bg-orange-500 text-white"
                : "bg-zinc-100"
            }
            ${
              fromPDP
                ? "max-sm:flex-1 bg-primary max-sm:p-1.5"
                : " p-2 rounded-md "
            }
            `}
        >
          {loading ? (
            <span className="w-6 loader" />
          ) : (
            !fromPDP && (
              <CartIcon className="max-[420px]:size-[11px] size-[17px]" />
            )
          )}
          <span
            className={`text-base ${
              !fromPDP && "max-sm:text-[11px]"
            } uppercase max-sm:capitalize whitespace-nowrap block`}
          >
            { product?.alKhobarRegion >0  //product?.stock?.is_in_stock == true &&
              ? product?.back_orders === 101
                ? t("preorder")
                : t("Add To Cart")
              : fromPDP
              ? t("Notify Me")
              : t("Out of stock and will be available")}
          </span>
        </button>
      </div>
    </>
  );
};

export default AddToCart;
