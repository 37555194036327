import { Form, Formik } from "formik";
import Input from "../Input";
import { object, string } from "yup";

const UpdatePhone = ({ t }) => {

  const validationSchema = (t) =>
    object().shape({
      telephone: string()
      .label("Phone number")
      .matches(/^(?!.*[\u0660-\u0669])[0-9]+$/, t("telephoneError1"))
      .matches(/^(05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/, t("telephoneError2"))
      .min(10, t("ErrorMinLength"))
      .required(t("TelephoneRequired")),
      password: string().required(t("PasswordRequired"))
    })

  return (
    <Formik 
      validationSchema = {validationSchema}
      initialValues    = {{ phone: "", password: "" }}
      // onSubmit         = {handleSubmit}
    >
      {({isSubmitting})=>(
        <Form className="mt-3 grid grid-cols-1 gap-3">
          <Input
            id             = "phone"
            name           = "phone"
            placeholder    = ""
            label          = "Add New Phone Number"
            type           = "tel"
            labelClassName = "text-zinc-600 font-semibold"
            t              = {t}
            />
          <Input
            id             = "password"
            name           = "password"
            placeholder    = ""
            label          = "Password"
            type           = "password"
            labelClassName = "text-zinc-600 font-semibold"
            t              = {t}
          />
        <button
          type      = "submit"
          disabled  = {isSubmitting}
          className = "bg-orange-600 border flex justify-center items-center rounded-md border-orange-600 text-white py-2 px-3 w-full font-semibold"
          >
          {isSubmitting && <div className="w-6 loader"/>}
          <p>{t("Change Phone")}</p>
        </button>
      </Form>
  )}
    </Formik>
  );
};

export default UpdatePhone;
