export const Categories = [
  {
    image: "/imgs/home/category-1.png",
    name: "Laptops & Computers",
  },
  {
    image: "/imgs/home/category-2.png",
    name: "Cameras & Photography",
  },
  {
    image: "/imgs/home/category-3.png",
    name: "Smart Phones & Tablets",
  },
  {
    image: "/imgs/home/category-4.png",
    name: "Video Games & Consoles",
  },
  {
    image: "/imgs/home/category-5.png",
    name: "TV & Audio",
  },
  {
    image: "/imgs/home/category-6.png",
    name: "Laptops & Computers",
  },
  {
    image: "/imgs/home/category-7.png",
    name: "Cameras & Photography",
  },
];

export const HeaderBrands = [
  "Apple",
  "Samsung",
  "Nokia",
  "Toshiba",
  "Anker",
  "Tommy Hilfiger",
];

export const GridSection1 = [
  {
    src: "/imgs/home/static-banner-1.png",
    col: "col-span-6",
    link: "/",
  },
  {
    src: "/imgs/home/static-banner-2.png",
    col: "col-span-6",
    link: "/",
  },
  {
    src: "/imgs/home/static-banner-3.png",
    col: "col-span-6 md:col-span-3",
    link: "/",
  },
  {
    src: "/imgs/home/static-banner-4.png",
    col: "col-span-6 md:col-span-3",
    link: "/",
  },
  {
    src: "/imgs/home/static-banner-5.png",
    col: "col-span-6 md:col-span-3",
    link: "/",
  },
  {
    src: "/imgs/home/static-banner-6.png",
    col: "col-span-6 md:col-span-3",
    link: "/",
  },
  {
    src: "/imgs/home/static-banner-7.png",
    col: "col-span-6 md:col-span-3",
    link: "/",
  },
  {
    src: "/imgs/home/static-banner-8.png",
    col: "col-span-6 md:col-span-3",
    link: "/",
  },
  {
    src: "/imgs/home/static-banner-9.png",
    col: "col-span-6 md:col-span-3",
    link: "/",
  },
  {
    src: "/imgs/home/static-banner-10.png",
    col: "col-span-6 md:col-span-3",
    link: "/",
  },
];

// Product Section

export const HomeProducts = [
  {
    img: "/imgs/home/product-1.png",
    brandImg: "/imgs/home/product-brand.png",
    title: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat soluta
        cupiditate, veniam enim impedit nesciunt. Voluptatum iste corrupti sit
        pariatur?`,
    services: [
      "Bluetooth",
      "USB Charge",
      "Black",
      "Octi",
      "Built in microphone",
    ],
    price: {
      new: "1900",
      old: "2500",
    },
  },
  {
    img: "/imgs/home/product-2.png",
    brandImg: "/imgs/home/product-brand.png",
    title: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat soluta
        cupiditate, veniam enim impedit nesciunt. Voluptatum iste corrupti sit
        pariatur?`,
    services: [
      "Bluetooth",
      "USB Charge",
      "Black",
      "Octi",
      "Built in microphone",
    ],
    price: {
      new: "1900",
      old: "2500",
    },
  },
  {
    img: "/imgs/home/product-3.png",
    brandImg: "/imgs/home/product-brand.png",
    title: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat soluta
        cupiditate, veniam enim impedit nesciunt. Voluptatum iste corrupti sit
        pariatur?`,
    services: [
      "Bluetooth",
      "USB Charge",
      "Black",
      "Octi",
      "Built in microphone",
    ],
    price: {
      new: "1900",
      old: "2500",
    },
  },
  {
    img: "/imgs/home/product-4.png",
    brandImg: "/imgs/home/product-brand.png",
    title: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat soluta
        cupiditate, veniam enim impedit nesciunt. Voluptatum iste corrupti sit
        pariatur?`,
    services: [
      "Bluetooth",
      "USB Charge",
      "Black",
      "Octi",
      "Built in microphone",
    ],
    price: {
      new: "1900",
      old: "2500",
    },
  },
  {
    img: "/imgs/home/product-5.png",
    brandImg: "/imgs/home/product-brand.png",
    title: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat soluta
        cupiditate, veniam enim impedit nesciunt. Voluptatum iste corrupti sit
        pariatur?`,
    services: [
      "Bluetooth",
      "USB Charge",
      "Black",
      "Octi",
      "Built in microphone",
    ],
    price: {
      new: "1900",
      old: "2500",
    },
  },
  {
    img: "/imgs/home/product-1.png",
    brandImg: "/imgs/home/product-brand.png",
    title: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat soluta
        cupiditate, veniam enim impedit nesciunt. Voluptatum iste corrupti sit
        pariatur?`,
    services: [
      "Bluetooth",
      "USB Charge",
      "Black",
      "Octi",
      "Built in microphone",
    ],
    price: {
      new: "1900",
      old: "2500",
    },
  },
  {
    img: "/imgs/home/product-2.png",
    brandImg: "/imgs/home/product-brand.png",
    title: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat soluta
        cupiditate, veniam enim impedit nesciunt. Voluptatum iste corrupti sit
        pariatur?`,
    services: [
      "Bluetooth",
      "USB Charge",
      "Black",
      "Octi",
      "Built in microphone",
    ],
    price: {
      new: "1900",
      old: "2500",
    },
  },
  {
    img: "/imgs/home/product-3.png",
    brandImg: "/imgs/home/product-brand.png",
    title: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat soluta
        cupiditate, veniam enim impedit nesciunt. Voluptatum iste corrupti sit
        pariatur?`,
    services: [
      "Bluetooth",
      "USB Charge",
      "Black",
      "Octi",
      "Built in microphone",
    ],
    price: {
      new: "1900",
      old: "2500",
    },
  },
];

export const ProductBreakPoints = {
  // 320: {
  //   slidesPerView: 1.5,
  // },
  // 421: {
  //   slidesPerView: 2,
  // },
  // 640: {
  320: {
    slidesPerView: 2.2,
    spaceBetween: 3,
  },
  768: {
    slidesPerView: 3.5,
  },
  991: {
    slidesPerView: 4,
  },
  1200: {
    slidesPerView: 5,
  },
};

export const CategoryProductsBreakPoints = {
  320: {
    slidesPerView: 1.8,
    spaceBetween: 46,
  },
  768: {
    slidesPerView: 1.8,
    spaceBetween: 46,
  },
  991: {
    slidesPerView: 3.75,
    spaceBetween: 60,
  },
  1200: {
    slidesPerView: 3.75,
    spaceBetween: 60,
  },
};

export const swiperOffersBreakPoints = {
  320: {
    slidesPerView: 2.5,
    loop: true,
    spaceBetween: 10,
  },
  421: {
    slidesPerView: 2.5,
    loop: true,
    spaceBetween: 10,
  },
  640: {
    slidesPerView: 4,
    loop: true,
    spaceBetween: 20,
  },
  768: {
    slidesPerView: 4,
    loop: true,
    spaceBetween: 20,
  },
  991: {
    slidesPerView: 5,
    loop: true,
    spaceBetween: 20,
  },
  1200: {
    slidesPerView: 6,
    loop: false,
    spaceBetween: 20,
  },
};

export const BrandBreakPoints = {
  320: {
    slidesPerView: 3,
    spaceBetween: 5,
  },
  481: {
    slidesPerView: 4,
  },
  640: {
    slidesPerView: 5,
  },
  769: {
    slidesPerView: 7,
  },
  1024: {
    slidesPerView: 9,
  },
};

export const GridSection2 = [
  {
    src: "/imgs/home/static-banner-11.png",
    col: "col-span-4 md:col-span-2",
    link: "/",
  },
  {
    src: "/imgs/home/static-banner-12.png",
    col: "col-span-4 md:col-span-2",
    link: "/",
  },
  {
    src: "/imgs/home/static-banner-13.png",
    col: "col-span-4 md:col-span-2",
    link: "/",
  },
  {
    src: "/imgs/home/static-banner-14.png",
    col: "col-span-4 md:col-span-2",
    link: "/",
  },
  {
    src: "/imgs/home/static-banner-15.png",
    col: "col-span-4 md:col-span-2",
    link: "/",
  },
  {
    src: "/imgs/home/static-banner-16.png",
    col: "col-span-4 md:col-span-2",
    link: "/",
  },
  {
    src: "/imgs/home/static-banner-7.png",
    col: "col-span-6 md:col-span-3",
    link: "/",
  },
  {
    src: "/imgs/home/static-banner-8.png",
    col: "col-span-6 md:col-span-3",
    link: "/",
  },
  {
    src: "/imgs/home/static-banner-9.png",
    col: "col-span-6 md:col-span-3",
    link: "/",
  },
  {
    src: "/imgs/home/static-banner-10.png",
    col: "col-span-6 md:col-span-3",
    link: "/",
  },
];

export const metadata = {
  ar: {
    title: "الصندوق الأسود",
    blackbox_title:
      "تسوق أجهزة منزلية, مكيفات, تلفزيونات والجوالات | الصندوق الأسود",
    blackbox_description:
      "أفضل العروض المتجددة بالسعودية على الأجهزة المنزلية, مكيف سبليت, الشاشات, اللابتوبات, الجوالات, ايفون والمزيد من الصندوق الأسود",
  },
  en: {
    title: "BlackBox",
    blackbox_title:
      "Shop Home Appliances, Air Conditioners, Televisions, and Mobile Phones | BlackBox",
    blackbox_description:
      "Best ongoing deals in Saudi Arabia on home appliances, split ACs, screens, laptops, mobile phones, iPhones, and more from BlackBox",
  },
};