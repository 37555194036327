const TrackOrder = (props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.75 11.0624H1.5C1.1925 11.0624 0.9375 10.8074 0.9375 10.4999V5.71487C0.9375 5.47487 1.08749 5.26486 1.30499 5.18236C1.52999 5.09986 1.77751 5.16738 1.92751 5.34738C2.38501 5.89488 3.09751 6.20237 3.80251 6.17987C4.40251 6.16487 4.95749 5.93989 5.37749 5.54239C5.57249 5.37739 5.72999 5.18237 5.84999 4.96487C6.08249 4.56737 6.195 4.13236 6.1875 3.68987C6.1725 2.99987 5.87252 2.36987 5.36252 1.91237C5.18252 1.75487 5.1225 1.50736 5.205 1.28986C5.2875 1.07236 5.49751 0.922363 5.73001 0.922363H11.25C11.5575 0.922363 11.8125 1.17736 11.8125 1.48486V8.98486C11.8125 10.1399 10.89 11.0624 9.75 11.0624ZM2.0625 9.93738H9.75C10.2675 9.93738 10.6875 9.51738 10.6875 8.99988V2.06238H6.8925C7.155 2.54988 7.2975 3.09739 7.3125 3.66739C7.3275 4.31989 7.16249 4.96487 6.83249 5.52737C6.65249 5.84987 6.40499 6.15739 6.13499 6.38239C5.53499 6.95239 4.70999 7.28988 3.83249 7.31238C3.20249 7.33488 2.59501 7.17738 2.07001 6.89238V9.93738H2.0625Z"
      fill="currentColor"
    />
    <path
      d="M14.25 15.5625H13.5C13.1925 15.5625 12.9375 15.3075 12.9375 15C12.9375 14.4825 12.5175 14.0625 12 14.0625C11.4825 14.0625 11.0625 14.4825 11.0625 15C11.0625 15.3075 10.8075 15.5625 10.5 15.5625H7.5C7.1925 15.5625 6.9375 15.3075 6.9375 15C6.9375 14.4825 6.5175 14.0625 6 14.0625C5.4825 14.0625 5.0625 14.4825 5.0625 15C5.0625 15.3075 4.8075 15.5625 4.5 15.5625H3.75C2.1975 15.5625 0.9375 14.3025 0.9375 12.75V10.5C0.9375 10.1925 1.1925 9.9375 1.5 9.9375H9.75C10.2675 9.9375 10.6875 9.5175 10.6875 9V3.75C10.6875 3.4425 10.9425 3.1875 11.25 3.1875H12.63C13.3725 3.1875 14.055 3.58501 14.4225 4.23001L15.705 6.4725C15.8025 6.645 15.8025 6.8625 15.705 7.035C15.6075 7.2075 15.42 7.3125 15.2175 7.3125H14.25C14.145 7.3125 14.0625 7.395 14.0625 7.5V9.75C14.0625 9.855 14.145 9.9375 14.25 9.9375H16.5C16.8075 9.9375 17.0625 10.1925 17.0625 10.5V12.75C17.0625 14.3025 15.8025 15.5625 14.25 15.5625ZM13.9875 14.4375H14.25C15.18 14.4375 15.9375 13.68 15.9375 12.75V11.0625H14.25C13.53 11.0625 12.9375 10.47 12.9375 9.75V7.5C12.9375 6.78 13.5225 6.1875 14.25 6.1875L13.4475 4.785C13.2825 4.4925 12.9675 4.3125 12.63 4.3125H11.8125V9C11.8125 10.14 10.89 11.0625 9.75 11.0625H2.0625V12.75C2.0625 13.68 2.82 14.4375 3.75 14.4375H4.01248C4.25998 13.575 5.055 12.9375 6 12.9375C6.945 12.9375 7.74002 13.575 7.98752 14.4375H10.02C10.2675 13.575 11.0625 12.9375 12.0075 12.9375C12.9525 12.9375 13.74 13.575 13.9875 14.4375Z"
      fill="currentColor"
    />
    <path
      d="M6 17.0625C4.86 17.0625 3.9375 16.14 3.9375 15C3.9375 13.86 4.86 12.9375 6 12.9375C7.14 12.9375 8.0625 13.86 8.0625 15C8.0625 16.14 7.14 17.0625 6 17.0625ZM6 14.0625C5.4825 14.0625 5.0625 14.4825 5.0625 15C5.0625 15.5175 5.4825 15.9375 6 15.9375C6.5175 15.9375 6.9375 15.5175 6.9375 15C6.9375 14.4825 6.5175 14.0625 6 14.0625Z"
      fill="currentColor"
    />
    <path
      d="M12 17.0625C10.86 17.0625 9.9375 16.14 9.9375 15C9.9375 13.86 10.86 12.9375 12 12.9375C13.14 12.9375 14.0625 13.86 14.0625 15C14.0625 16.14 13.14 17.0625 12 17.0625ZM12 14.0625C11.4825 14.0625 11.0625 14.4825 11.0625 15C11.0625 15.5175 11.4825 15.9375 12 15.9375C12.5175 15.9375 12.9375 15.5175 12.9375 15C12.9375 14.4825 12.5175 14.0625 12 14.0625Z"
      fill="currentColor"
    />
    <path
      d="M16.5 11.0625H14.25C13.53 11.0625 12.9375 10.47 12.9375 9.75V7.5C12.9375 6.78 13.53 6.1875 14.25 6.1875H15.2175C15.42 6.1875 15.6075 6.2925 15.705 6.4725L16.9875 8.7225C17.0325 8.805 17.0625 8.9025 17.0625 9V10.5C17.0625 10.8075 16.8075 11.0625 16.5 11.0625ZM14.25 7.3125C14.145 7.3125 14.0625 7.395 14.0625 7.5V9.75C14.0625 9.855 14.145 9.9375 14.25 9.9375H15.9375V9.15001L14.8875 7.3125H14.25Z"
      fill="currentColor"
    />
    <path
      d="M3.74221 7.3125C2.70721 7.3125 1.73221 6.86248 1.07221 6.07498C0.967214 5.96248 0.854726 5.80498 0.757226 5.65498C0.404726 5.12248 0.209726 4.49249 0.194726 3.83249C0.164726 2.73749 0.629735 1.70998 1.46974 1.01248C2.10724 0.487482 2.8722 0.2025 3.6822 0.1875C4.5672 0.195 5.45224 0.487502 6.11224 1.08C6.86224 1.74 7.29723 2.66251 7.31973 3.66751C7.33473 4.32001 7.16972 4.965 6.83972 5.5275C6.65972 5.85 6.41222 6.15751 6.14222 6.38251C5.54222 6.95251 4.71722 7.29 3.83972 7.3125C3.80222 7.3125 3.77221 7.3125 3.74221 7.3125ZM3.74221 1.3125C3.72721 1.3125 3.71221 1.3125 3.69721 1.3125C3.14971 1.32 2.6247 1.52251 2.1822 1.88251C1.6122 2.35501 1.29722 3.06001 1.31222 3.80251C1.32722 4.25251 1.45473 4.68002 1.69473 5.03252C1.76223 5.13752 1.82971 5.23498 1.91221 5.32498C2.39221 5.89498 3.10472 6.19499 3.80222 6.17999C4.40222 6.16499 4.95721 5.94001 5.37721 5.54251C5.57221 5.37751 5.72971 5.1825 5.84971 4.965C6.08221 4.5675 6.19472 4.13249 6.18722 3.68999C6.17222 2.99999 5.87224 2.36999 5.36224 1.91249C4.91224 1.52999 4.34221 1.3125 3.74221 1.3125Z"
      fill="currentColor"
    />
    <path
      d="M3.33765 5.06226C3.19515 5.06226 3.06014 5.00975 2.94764 4.90475L2.19013 4.18477C1.96513 3.96727 1.95766 3.61478 2.17516 3.38978C2.39266 3.16478 2.74516 3.15726 2.97016 3.37476L3.33765 3.72724L4.51516 2.58727C4.74016 2.36977 5.09266 2.37724 5.31016 2.60224C5.52766 2.82724 5.52014 3.17978 5.29514 3.39728L3.72762 4.91225C3.61512 5.00975 3.47265 5.06226 3.33765 5.06226Z"
      fill="currentColor"
    />
  </svg>
);
export default TrackOrder;
