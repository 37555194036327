const { useReducer, useEffect, createContext } = require("react");
import store from "store";
import { cartReducer } from "./reducers";
export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const savedSData = store.get("cart") || { cart: {}, cartItems: [], shippingAddress: null };
  const [cart, dispatch] = useReducer(cartReducer, {
    ...savedSData,
    cartInfo: [],
  });
  useEffect(() => {
    store.set("cart", cart);
  }, [cart]);

  return (
    <CartContext.Provider value={{ cart, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};
