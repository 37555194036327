import { useCheckout } from "@/context/checkout";
import Location from "../icons/Location";
import { useRouter } from "next/router";

const BranchInfo = ({
  t,
  info,
  map,
  branchInView = null,
  setBranchInView = null,
  setFieldValue = null,
}) => {
  const checkoutContext = useCheckout();
  const { pathname } = useRouter();
  const { asPath } = useRouter();
  const inBranchesPage = asPath?.includes("branches");
  const active = checkoutContext?.checkout?.pickupStore?.id == info?.location_id;
  console.log(checkoutContext?.checkout?.pickupStore?.id)
  console.log("lat_lng", info)
  return (
    <div
      className={`flex flex-col 
      ${
        inBranchesPage
          ? `hover:border-primary/50 ${
              branchInView?.location_id == info?.location_id ? "border-primary/50" : ""
            }`
          : ""
      } 
      gap-3 p-2 sm:p-3 border cursor-pointer rounded-md max-lg:min-w-60`}
      onClick={() => {
        if (!pathname?.startsWith("/checkout")) {
          setBranchInView(info);
          map.panTo({ lng: parseFloat(info?.lng), lat: parseFloat(info?.lat) });
        }
      }}
    >
      <p className="text-xl font-semibold max-sm:text-sm text-primary">
        {info?.name || ""}
      </p>
      <div className="flex gap-2 items-center justify-center -mt-1">
        <Location className="mt-1 h-6" />
        <p className="flex-1 text-sm max-sm:text-xs text-zinc-500 max-sm:line-clamp-2 max-sm:min-h-8">
          {info?.address || ""}
        </p>
      </div>
      
      <div className="flex justify-between">
        <button
          onClick={() =>{
            if (!pathname?.startsWith("/checkout")) {
            map?.panTo({
              
              lng: parseFloat(info?.lng),
              lat: parseFloat(info?.lat),
            })
          }
          else{
            map?.panTo({
              lng: parseFloat(info?.longitude),
              lat: parseFloat(info?.latitude),
            })
          }
        }
      }
          className="px-5 py-2 text-white rounded-md max-sm:text-xs bg-primary lg:w-fit"
        >
          {t("Show Map")}
        </button>
        {!inBranchesPage && (
          <button
            onClick={() => {
              if (!pathname?.startsWith("/checkout")) {
                map?.panTo({
                  
                  lng: parseFloat(info?.lng),
                  lat: parseFloat(info?.lat),
                })
              }
              else{
                map?.panTo({
                  lng: parseFloat(info?.longitude),
                  lat: parseFloat(info?.latitude),
                })
              }
              setBranchInView(info);
              checkoutContext.dispatch({
                type: "pickup_store",
                payload: { id: info?.location_id },
              });
              setFieldValue("store_id", info?.location_id);
            }}
            className={`px-5 py-2 ${
              active ? "text-primary bg-white border" : "text-white bg-primary"
            } rounded-md  lg:w-fit`}
          >
            {t("Set Address")}
          </button>
        )}
      </div>
    </div>
  );
};

export default BranchInfo;
