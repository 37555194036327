import { ModalContext } from "@/pages/_app";
import { useContext } from "react";

import SortBy from "./category/SortBy";
import Filter from "./category/Filter";
import ChangeEmail from "./user/ChangeEmail";
import ChangePhone from "./user/ChangePhone";
import ChangePassword from "./user/ChangePassword";
import AddNewAddress from "./addresses/AddNewAddress";
import EditAddress from "./addresses/EditAddress";
import RateProduct from "./user/RateProduct";
// import ReturnOrder from "./user/ReturnOrder";
import AddCoupon from "./cart/AddCoupon";
import AddVoucher from "./cart/AddVoucher";
import VerifyOtp from "../forms/checkout/payments/VerifyOtp";
import VerifyQuaraOtp from "../forms/checkout/payments/VerifyQuaraOtp";
import EditCheckoutAddress from "./checkout/EditCheckoutAddress";
import AddCheckoutAddress from "./checkout/AddCheckoutAddress";
import Auth from "./Auth";
import Forgot from "../forms/auth/Forgot";
import Otp from "../forms/auth/Otp";
import ResetPassword from "../forms/auth/ResetPassword";
import SuccessPassword from "./SuccessPassword";
import PersonalInformations from "./user/PersonalInformations";
import ViewCheckoutAddresses from "./checkout/ViewCheckoutAddresses";
import StorePickUpModal from "./checkout/StorePickUpModal";
import CartModal from "./CartModal";
import TrackOrder from "./TrackOrder";
import SelectCity from "./SelectCity";
import ProcessingPopUp from "./cart/ProcessingPopUp";

const Modals = () => {
  const Context = useContext(ModalContext);
  if (Context?.state.modal)
    return (
      <div
        className={`fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-60 z-modal ${
          (!Context?.state.sortBy || !Context?.state.filter) &&
          "flex items-center justify-center overflow-hidden"
        }`}
      >
        {Context?.state.auth ? <Auth Context={Context} /> : null}
        {Context?.state.select_city ? <SelectCity Context={Context} /> : null}
        {Context?.state.trackOrder ? <TrackOrder Context={Context} /> : null}
        {Context?.state.forgot ? <Forgot Context={Context} /> : null}
        {Context?.state.otp ? <Otp Context={Context} /> : null}
        {Context?.state.resetPassword ? (
          <ResetPassword Context={Context} />
        ) : null}
        {Context?.state.successPassword ? (
          <SuccessPassword Context={Context} />
        ) : null}
        {Context?.state.sortBy ? <SortBy Context={Context} /> : null}
        {Context?.state.filter ? <Filter Context={Context} /> : null}
        {Context?.state.personalInformations ? (
          <PersonalInformations Context={Context} />
        ) : null}
        {Context?.state.changeEmail ? <ChangeEmail Context={Context} /> : null}
        {Context?.state.changePhone ? <ChangePhone Context={Context} /> : null}
        {Context?.state.changePassword ? (
          <ChangePassword Context={Context} />
        ) : null}
        {Context?.state.addNewAddress ? (
          <AddNewAddress Context={Context} />
        ) : null}
        {Context?.state.editAddress ? <EditAddress Context={Context} /> : null}
        {Context?.state.rateProduct ? <RateProduct Context={Context} /> : null}
        {/* {Context?.state.returnOrder ? <ReturnOrder Context={Context} /> : null} */}
        {Context?.state.addCoupon ? <AddCoupon Context={Context} /> : null}
        {Context?.state.processingPopUp ? <ProcessingPopUp Context={Context} /> : null}
        {Context?.state.addVoucher ? <AddVoucher Context={Context} /> : null}
        {Context?.state.addVoucher ? <AddVoucher Context={Context} /> : null}
        {Context?.state.stcPayOtp ? <VerifyOtp Context={Context} /> : null}
        {Context?.state.quaraPayOtp ? <VerifyQuaraOtp Context={Context} /> : null}
        {Context?.state.viewAllCheckoutAddresses ? (
          <ViewCheckoutAddresses Context={Context} />
        ) : null}
        {Context?.state.editCheckoutAddress ? (
          <EditCheckoutAddress Context={Context} />
        ) : null}
        {Context?.state.addCheckoutAddress ? (
          <AddCheckoutAddress Context={Context} />
        ) : null}
        {Context?.state.storePickUp ? (
          <StorePickUpModal Context={Context} />
        ) : null}
      </div>
    );
  else if (Context?.state.addToCartModal) {
    return <CartModal Context={Context} />;
  }
};

export default Modals;
