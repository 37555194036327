import { useState, useEffect, useRef } from 'react';

const useInViewPort = (callback, data = {}) => {
  console.log('data//////////////', data)
  const [isInViewPort, setIsInViewPort] = useState(false);
  const elementRef = useRef(null);
  const callbackCalled = useRef(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        const inView = entry.isIntersecting;
        setIsInViewPort(inView);

        if (inView && !callbackCalled.current) {
          callback();
          callbackCalled.current = true;
        }
      },
      { threshold: 0.1 }
    );

    const currentElement = elementRef.current;
    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, [callback]);

  return [elementRef, isInViewPort];
};

export default useInViewPort;
