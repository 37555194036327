import Input from "../../Input";
import toast from "react-hot-toast";
import CloseIcon from "@/components/icons/CloseIcon";

import { otpValidation } from "@/utils/valitation/auth";
import { useTranslation } from "next-i18next";
import { Form, Formik } from "formik";
import { verifyStcPayOtp } from "@/services/checkout/stcpay";
import { placeOrder } from "@/services/checkout";
import { useRouter } from "next/router";
import { getCookies } from "cookies-next";

const VerifyOtp = ({ Context }) => {
  const { handshake, mage, quote } = getCookies();

  const {
    // dispatch,
    state: { stcPayData },
  } = Context;
  console.log("Context", stcPayData);
  const { t } = useTranslation("common");
  const { push } = useRouter();
  const handleVerifyOtp = async ({ otp }, { setSubmitting }) => {
    if (otp?.trim()?.length < 4)
      return toast.error(t("otp can not be an empty value"));
    setSubmitting(true);
    const verifyOTP = await verifyStcPayOtp(
      handshake,
      quote,
      otp,
      stcPayData?.paymentReference,
      stcPayData?.OtpReference,
      stcPayData?.mobile
    );
    debugger;
    if (verifyOTP?.data?.status) {
      const place_order = await placeOrder(handshake, mage, quote, "stc_pay");

      if (place_order?.status === 200) {
        toast.success(t("placedOrder"));
        setTimeout(() => {
          push(`/success?success=true&order_id=${place_order?.data?.data}`);
        }, 1000);
        Context.dispatch({ type: "stcPayOtp" });
      } else {
        console.log("place_order", place_order);
        toast.error(
          t(place_order?.error?.error?.message) || t("placeOrderErr")
        );
        // close otp modal
      }
    } else {
      setSubmitting(false);
    }
  };
  return (
    <div className="w-11/12 mx-auto min-[450px]:w-[450px] p-4 bg-white rounded-md">
      <div className="flex items-center justify-between border-b pb-2">
        <h2 className="font-bold text-lg sm:text-xl text-center">
          {t("Verify OTP")}
        </h2>
        <CloseIcon
          onClick={() => Context?.dispatch({ type: "stcPayOtp" })}
          className="text-secondary-800 size-3.5 cursor-pointer"
        />
      </div>
      <p className="my-2 text-center">
        <span className="text-secondary-800 font-bold">
          {t("Enter your otp Code")}
        </span>
        <span className="text-zinc-500 mx-1">
          {" "}
          {t("that is sent from your mobile number")}
        </span>
      </p>
      <Formik
        onSubmit={handleVerifyOtp}
        initialValues={{ otp: "" }}
        validationSchema={otpValidation(t)}
      >
        {({ isSubmitting }) => (
          <Form>
            <Input
              placeholder={t("Enter OTP")}
              name="otp"
              dir="ltr"
              inputClassName="p-4 rounded-md w-full border outline-none text-center"
              t={t}
            />
            <button
              className="mt-2 w-full bg-primary text-white font-semibold p-3 rounded-md uppercase flex justify-center items-center gap-3"
              type="submit"
              disabled={isSubmitting}
            >
              {t("Send Code")}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default VerifyOtp;
