import selectedAtteributes from "@/constants/selectedAttrubites";
import axios from "@/utils/axios-config";
import * as Sentry from "@sentry/react";

/*
===============================|> All Search api APis <|===============================
01 |--> search Api
02 |--> save search term
===============================|> All Search api APis <|===============================
*/
// 01- =============> get products by category id
// * sortBy can be position | price
// * sortDir can be ASC | DESC
const productsSearch = async (
  handshake,
  word,
  pageSize = 30,
  pageNo = 0,
  allSortingKeys = "sortBy=position&sortDir=ASC",
  allFilterKeys = "",
  getFullResponse = true,
  regionId = null
) => {
  const config = {
    method: "get",
    url: `/search/products/searchQuery?search=${word}&pageNo=${pageNo}&pageSize=${pageSize}&${allSortingKeys}&${allFilterKeys}${
      regionId === "1504" ? "&region=2106" : ""
    }`,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  if (!getFullResponse) {
    config.url += `&${selectedAtteributes}`;
  }

  console.log("config in productsSearch", config);
  try {
    const req = await axios(config);
    console.log("req", req);
    return req;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};
// 02- =============> save search term
const saveSearchTerm = async (handshake, searchWord) => {
  const config = {
    method: "get",
    url: `/search/terms/save?q=${encodeURIComponent(searchWord)}`,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

export { productsSearch, saveSearchTerm };
