import { getProductsByCategoryId } from "@/services/category";
import { productsSearch } from "@/services/search";
import { event_error } from "@/utils/gtm";
import { handleProductsWithRating } from "@/utils/global";
import { getCookie } from "cookies-next";
import { useRouter } from "next/router";
import { useCallback } from "react";
import useSWRMutation from "swr/mutation";
import useGetProductsRating from "../global/useGetProductsRating";

const useSortProducts = ({
  setAllSortingKeys,
  setAllProducts,
  categoryID,
  allSortingKeys,
  allFilterKeys,
  fromCategory = true,
  setPageIndex,
  word = "",
}) => {
  const handshake = getCookie("handshake");
  const { asPath } = useRouter();

  const { triggerProductsRating } = useGetProductsRating(`${asPath}`);

  const res = useSWRMutation(
    fromCategory
      ? `/api/category/pages/getMoreProducts?pageIdx=0&categoryID=${categoryID}&${allSortingKeys}`
      : `/api/search?word=${word}&pageNo=0&pageSize=30&${allSortingKeys}&${allFilterKeys}`,
    async (_, { arg }) => {
      const { pageNo, pageSize, categoryID, filterKeys, sortKeys } = arg;
      const { data: productsData, error: productsError } = fromCategory
        ? await getProductsByCategoryId(
            handshake,
            categoryID,
            sortKeys,
            filterKeys,
            pageSize,
            pageNo
          )
        : await productsSearch(
            handshake,
            word,
            pageSize,
            pageNo,
            sortKeys,
            filterKeys
          );

      if (productsError) {
        event_error({
          error_message: productsError,
          error_type: "Request Error",
          error_url: process.env.NEXT_PUBLIC_WEBSITE_URL + asPath,
        });
      }

      let handleProducts = {
        products: productsData?.data?.products,
        aggregate: productsData?.data?.aggregate,
        pages: productsData?.data?.pages,
        pageParam: +pageNo,
        error: productsError,
      };
      return handleProducts;
    },
    {
      revalidateOnMount: 30 * 60 * 1000, // 30 minutes in milliseconds
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      populateCache: true,
    }
  );

  const handleSorting = useCallback(
    async ({ keys }) => {
      setAllSortingKeys(keys);
      setPageIndex(1);
      let result = await res?.trigger(
        fromCategory
          ? {
              pageNo: 0,
              pageSize: 30,
              categoryID,
              filterKeys: allFilterKeys,
              sortKeys: keys,
            }
          : {
              pageNo: 0,
              pageSize: 30,
              filterKeys: allFilterKeys,
              sortKeys: keys,
              word,
            }
      );

      // console.log("handleSorting", {
      //   pageNo: 0,
      //   pageSize: 30,
      //   filterKeys: allFilterKeys,
      //   sortKeys: keys,
      //   word,
      // }, result?.products);
      let products = result?.products;
      products?.length &&
        products != undefined &&
        handleProductsWithRating(
          products,
          triggerProductsRating,
          setAllProducts
        );
      // setAllProducts(result?.products?.length && result?.products != undefined ? result?.products : []);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allFilterKeys, categoryID, res, setAllProducts, setAllSortingKeys]
  );

  return {
    handleSorting,
  };
};

export default useSortProducts;
