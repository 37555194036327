import * as Sentry from "@sentry/react";
import axios from "@/utils/axios-config";
/*
===============================|> AUTH APIS <|===============================
01 |--> Get user data Api
02 |--> change user email Api
03 |--> change user password Api
04 |--> change user name Api
===============================|> AUTH APIS <|===============================
*/

// 01 --> Register api
const getUserData = async (handshake, mage) => {
  const config = {
    method: "get",
    url: "/customer/me",
    headers: {
      'Authorization':`Bearer ${handshake}`,
      mage: `Bearer ${mage}`,
    },
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

// 02 --> Change email api
const changeUserEmail = async (handshake, mage, customerId, newEmail, currentPassword) => {
  const data = JSON.stringify({ customerId, newEmail, currentPassword });
  const config = {
    method: "post",
    url: "/customer/changeEmail",
    headers: {
      'Authorization':`Bearer ${handshake}`,
      mage: `Bearer ${mage}`,
    },
    data,
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

// 03 --> Change user password api
const changeUserPassword = async (
 { handshake,
  mage,
  customerId,
  currentPassword,
  newPassword,
  confirmPassword}
) => {
  const data = JSON.stringify({
    customerId,
    currentPassword,
    newPassword,
    confirmPassword,
  });
  const config = {
    method: "post",
    url: "/customer/changePassword",
    headers: {
      Authorization: `Bearer ${handshake}`,
      mage         : `Bearer ${mage}`,
    },
    data,
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

// 04 --> Change user name api
const changeUserName = async (
  handshake,
  mage,
  customerId,
  currentPassword,
  firstName,
  lastName
) => {
  const data = JSON.stringify({
    customerId,
    currentPassword,
    firstName,
    lastName,
  });
  const config = {
    method: "post",
    url: "/customer/changeName",
    headers: {
      'Authorization':`Bearer ${handshake}`,
      mage: `Bearer ${mage}`,
    },
    data,
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

export {
  getUserData,
  changeUserEmail,
  changeUserPassword,
  changeUserName
}