import { registerValidation } from "@/utils/valitation/auth";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { useTranslation } from "next-i18next";
import { register } from "@/services/auth";
import { getCookie } from "cookies-next";
import { useState } from "react";

import Input from "../Input";
import Image from "@/components/Image";
import toast from "react-hot-toast";
import Eye from "@/components/icons/Eye";
import Eyelock from "@/components/icons/Eyelock";
import Link from "next/link";
import { event_error, event_sign_up } from "@/utils/gtm";

const Register = ({ setLogin, Context }) => {
  const handshake = getCookie("handshake");
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const intialValues = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    mobile: "",
    confirmPassword: "",
    terms: "",
  };

  const handleRegister = async (values) => {
    setLoading(true);
    const { error } = await register(handshake, values);
    const { email, firstname, lastname, mobile } = values;
    const event_sign_up_data = {
      firstname,
      lastname,
      email,
      phone: mobile ? `966${mobile?.slice(-9)}` : null,
      account_creation_date: new Date(
        new Date().toUTCString("en-US", { timeZone: "Asia/riyadh" })
      ),
      gender: "Male",
      city: getCookie("cityName"),
    };

    console.log("error////////", error);
    console.log("typeof error////////", typeof error);
    if (error != null) {
      setLoading(false);
      // handling the error coming from backend and show to client
      toast.error(
        error?.error?.message ||
          (language === "en"
            ? "Error Invalid , Please check your data and try again"
            : "برجاء المحاولة مره أخرى")
      );
      const data = {
        method: "Email",
        status: `Signup Failed: ${
          error?.error?.message ||
          (language === "en"
            ? "Error Invalid , Please check your data and try again"
            : "برجاء المحاولة مره أخرى")
        }`,
        user_id: "",
      };

      const { method, status, user_id } = data;

      event_sign_up({ method, status, user_id, ...event_sign_up_data });
    } else {
      setLoading(false);

      event_error({
        error_message: "Account Created Successfully",
        error_type: "Request Error",
        error_url: process.env.NEXT_PUBLIC_WEBSITE_URL,
      });

      event_sign_up({
        method: "Email",
        user_type: "Customer",
        status: "Signup Success",
        // status:
        //   language === "en"
        //     ? "Account Created Successfully"
        //     : "تم الإشتراك بنجاح",
        user_id: "",
        ...event_sign_up_data,
      });
      toast.success(
        language === "en" ? "Account Created Successfully" : "تم الإشتراك بنجاح"
      );
      setLogin(true);
    }
    setLoading(false);
  };
  return (
    <div>
      <p className="my-3 text-sm text-center">
        <span className="font-semibold text-orange-600">
          <span className="font-bold sm:text-2xl">
            {t("You can create a new account")}
          </span>{" "}
          <span className="font-[400] sm:text-[20px] text-zinc-500">
            {t(
              "To enjoy the site’s benefits, such as tracking orders and registering products in your favorites"
            )}
          </span>
        </span>
      </p>
      <Formik
        onSubmit={handleRegister}
        initialValues={intialValues}
        validationSchema={registerValidation(t)}
      >
        <Form className="grid grid-cols-2 gap-3 mt-2">
          <Input
            name="firstname"
            id="first-name"
            cols="col-span-full sm:col-span-1"
            placeholder={t("First Name")}
            t={t}
          />
          <Input
            name="lastname"
            id="last-name"
            cols="col-span-full sm:col-span-1"
            placeholder={t("Last Name")}
            t={t}
          />
          <div className="col-span-full">
            <div
              dir="ltr"
              className="flex items-center gap-3 px-3 py-2 border rounded-md "
            >
              <div className="flex items-center w-20">
                <Image
                  loading="lazy"
                  src="/imgs/KSA.svg"
                  height={35}
                  width={30}
                  alt="KSA IMG!"
                />
                <span className="px-2 border-r max-sm:text-sm">+966</span>
              </div>
              <Field
                name="mobile"
                type="text"
                minLength={10}
                maxLength={10}
                className="flex-1 px-2 border-none outline-none max-sm:text-sm"
                placeholder={t("Phone Number")}
              />
            </div>
            <ErrorMessage
              name="mobile"
              component="p"
              className="mt-1 text-sm text-red-600"
            />
          </div>
          <Input
            name="email"
            id="email"
            type="email"
            placeholder={t("Email")}
            t={t}
          />
          <Input
            name="password"
            id="password"
            type={showPassword ? "text" : "password"}
            placeholder={t("Password")}
            relative
            icon={
              !showPassword ? (
                <Eye
                  onClick={() => setShowPassword(!showPassword)}
                  className={`text-zinc-400 size-5 absolute top-1/2 ${
                    language === "en" ? "right-2" : "left-2"
                  } -translate-y-1/2 cursor-pointer`}
                />
              ) : (
                <Eyelock
                  onClick={() => setShowPassword(!showPassword)}
                  className={`text-zinc-400 size-5 absolute top-1/2 ${
                    language === "en" ? "right-2" : "left-2"
                  } -translate-y-1/2 cursor-pointer`}
                />
              )
            }
            t={t}
          />
          <Input
            name="confirmPassword"
            id="confirm-password"
            type={showConfirm ? "text" : "password"}
            placeholder={t("Confirm Password")}
            relative
            icon={
              !showConfirm ? (
                <Eye
                  onClick={() => setShowConfirm(!showConfirm)}
                  className={`text-zinc-400 size-5 absolute top-1/2 ${
                    language === "en" ? "right-2" : "left-2"
                  } cursor-pointer -translate-y-1/2`}
                />
              ) : (
                <Eyelock
                  onClick={() => setShowConfirm(!showConfirm)}
                  className={`text-zinc-400 size-5 absolute top-1/2 ${
                    language === "en" ? "right-2" : "left-2"
                  } cursor-pointer -translate-y-1/2`}
                />
              )
            }
            t={t}
          />
          <div className="flex flex-wrap items-center gap-2 col-span-full">
            <Field
              type="checkbox"
              name="terms"
              className="cursor-pointer appearance-none size-[18px] border border-zinc-300 rounded-sm checked:bg-orange-600 checked:border-orange-600"
            />
            <span className="flex items-center gap-1 max-sm:text-sm">
              <span>{t("I accept")}</span>
              <Link
                href={"/terms-conditions"}
                target="_blank"
                onClick={() => {
                  Context?.dispatch({ type: "auth" });
                }}
                className="font-bold underline"
              >
                {t("terms & conditions")}
              </Link>
            </span>
            <ErrorMessage
              name="terms"
              component="p"
              className="w-full text-sm text-red-600"
            />
          </div>
          <button
            className="flex items-center justify-center w-full p-2 text-white bg-orange-600 rounded-md max-sm:text-sm col-span-full disabled:cursor-not-allowed"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <span className="flex items-center justify-center w-6 loader"></span>
            ) : (
              t("Register")
            )}
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default Register;
