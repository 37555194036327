import { object, string } from "yup";

export const personalInformationValidation = (t) =>
  object().shape({
    firstName: string().required(t("FirstNameRequired")),
    lastName: string().required(t("LastNameRequired")),
    currentPassword: string()
      .min(8, t("HasAtLeast8Characters"))
      .matches(/[A-Z]/, t("ContainsOneUppercaseLetter"))
      .matches(/[a-z]/, t("ContainsOneLowercaseLetter"))
      .matches(/[0-9]/, t("ContainsOneNumber"))
      .required(t("PasswordRequired")),
  });

export const userAddressesValidation = (t) =>
  object().shape({
    firstname: string().required(t("FirstNameRequired")),
    lastname: string().required(t("LastNameRequired")),
    region: string().required(t("RegionRequired")),
    email: string()
      .trim()
      .email(t("notValidEmail"))
      .required(t("emailRequired")),
    city: string().when("region", {
      is: (region) => !region,
      then: (schema) => schema.required(t("cityRequired")),
      otherwise: (schema) => schema.required(t("cityRequired")),
    }),
    telephone: string()
      .matches(/^(?!.*[\u0660-\u0669])[0-9]+$/, t("telephoneError1"))
      .matches(/^(05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/, t("telephoneError2"))
      .min(10)
      .max(10)
      .required(t("TelephoneRequired")),
    street: string().required(t("StreetRequired")),
  });
