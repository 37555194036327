import Image from "@/components/Image";
import Arrow from "@/components/icons/Arrow";

import { useRouter } from "next/router";
import { ModalContext } from "@/pages/_app";
import { useContext, useState } from "react";
import { modifyMenuTreeData } from "@/helpers/category";

const MenuCategoryTree = ({
  menuTreeData,
  modalRef,
  setShowSideMenu = null,
}) => {
  const { locale, push } = useRouter();
  const [isShowChild, setIsShowChild] = useState(
    Array(menuTreeData?.length).fill(false)
  );

  const modalsCtx = useContext(ModalContext);

  const toggleSubCategory = (index) => {
    setIsShowChild((prev) => {
      const newVisibilityState = [...prev];
      newVisibilityState[index] = !newVisibilityState[index];
      return newVisibilityState;
    });
  };
  return (
    <div className="pt-2 border-b" ref={modalRef}>
      {menuTreeData?.map((cat, i) => {
        const parentUrl = `/${cat.url_key}`;
        const hasChildren = modifyMenuTreeData(cat?.children_data)?.length > 0;
        return (
          cat?.include_in_menu &&
          cat?.product_count > 0 && (
            <div key={i}>
              <div
                onClick={() => hasChildren && toggleSubCategory(i)}
                className="flex items-center justify-between px-1 pb-5 cursor-pointer"
              >
                <div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      modalsCtx.dispatch({ type: "sidemenu" });
                      push(parentUrl);
                      setShowSideMenu && setShowSideMenu(false);
                    }}
                    // href={}
                    className={`uppercase text-sm ${
                      isShowChild[i]
                        ? "text-tertiary-800 font-bold"
                        : "font-medium"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-2">
                      {cat?.category_icon ? (
                        <div className="flex items-center justify-center size-6">
                          <Image
                            src={`${
                              process.env.NEXT_PUBLIC_IMG_BASEURL
                            }${cat?.category_icon?.replace("/media", "")}`}
                            height={30}
                            width={30}
                            className={`size-full object-contain`}
                            loading="lazy"
                            alt=""
                          />
                        </div>
                      ) : null}
                      <span className="text-xs transition-all duration-300 cursor-pointer hover:text-orange-600">
                        {cat?.name}
                      </span>
                    </div>
                  </div>
                </div>

                {/* {hasChildren && <Arrow className="size-3.5" />} */}
                <Arrow
                  className={`size-2.5 transition-all ${
                    locale === "ar" && "rotate-180"
                  } ${isShowChild[i] ? "rotate-90" : ""}`}
                />
              </div>

              {isShowChild[i] && (
                <SubMenu
                  data={cat?.children_data}
                  setShowSideMenu={setShowSideMenu ? setShowSideMenu : null}
                  parentUrl={parentUrl}
                />
              )}
            </div>
          )
        );
      })}
    </div>
  );
};

export default MenuCategoryTree;

const SubMenu = ({ data, parentUrl, setShowSideMenu }) => {
  const { locale, push } = useRouter();
  const modifiedData = modifyMenuTreeData(data);
  const [isShowChild, setIsShowChild] = useState(
    Array(modifiedData?.length).fill(false)
  );

  const toggleSubCategory = (index) => {
    setIsShowChild((prev) => {
      const newVisibilityState = [...prev];
      newVisibilityState[index] = !newVisibilityState[index];
      return newVisibilityState;
    });
  };

  const modalsCtx = useContext(ModalContext);

  return (
    <div className={`ps-2 pb-4`}>
      {modifiedData?.map((sub, i) => {
        const childUrl = `${parentUrl}/${sub.url_key}`;
        const hasChildren = modifyMenuTreeData(sub?.children_data)?.length > 0;

        return (
          <div key={i}>
            <div
              onClick={() => hasChildren && toggleSubCategory(i)}
              className="flex items-center justify-between px-2 pb-4 cursor-pointer"
            >
              <div
                // href={}
                onClick={(e) => {
                  e.stopPropagation();
                  modalsCtx.dispatch({ type: "sidemenu" });
                  push(childUrl);
                  setShowSideMenu && setShowSideMenu(false);
                }}
                className={`text-zinc-500 text-xs  ${
                  isShowChild[i] ? "font-bold" : "font-medium"
                }`}
              >
                <div className="flex items-center gap-2">
                  {sub?.image ? (
                    <Image
                      src={sub?.image}
                      height={30}
                      width={30}
                      className="object-contain"
                      loading="lazy"
                      alt=""
                    />
                  ) : null}
                  <span className="text-sm transition-all duration-300 cursor-pointer hover:text-orange-600">
                    {sub?.name}
                  </span>
                </div>
              </div>
              {hasChildren && (
                <Arrow
                  className={`size-2 transition-all ${
                    locale === "ar" && "rotate-180"
                  } ${isShowChild[i] ? "rotate-90" : ""}`}
                />
              )}
            </div>
            {isShowChild[i] && (
              <SubMenu
                data={sub?.children_data}
                parentUrl={childUrl}
                setShowSideMenu={setShowSideMenu}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
