import Brands from "@/components/category/filters/Brands";
// import Colors from "@/components/category/filters/Colors";
// import Prices from "@/components/category/filters/Prices";
// import Rating from "@/components/category/filters/Rating";
import CloseIcon from "@/components/icons/CloseIcon";
import FilterIcon from "@/components/icons/Filter";
import Arrow from "@/components/icons/Arrow";

import { useRef, useState } from "react";
import useOnClickOutside from "@/hooks/useOnClickOutside";
import { useBodyScrollLock } from "@/hooks/global/useBodyScrollLock";

const Filter = ({
  setShowFilter,
  filterList,
  selectedFilterKeys,
  handleFilter,
  handleResetFilter = null,
  t,
  language,
}) => {
  const [state, setState] = useState(false);
  const ref = useRef();
  const [open, setOpen] = useState(filterList?.map(() => false) || []);
  useOnClickOutside(ref, () => setShowFilter(false));
  useBodyScrollLock();
  return (
    <div
      ref={ref}
      className="absolute bottom-0 left-0 w-screen max-h-[70vh] overflow-y-auto bg-white"
    >
      <div className="flex items-center justify-between px-2 py-3 border-b">
        <div className="flex items-center gap-2">
          <FilterIcon className="size-5" />
          <span className="font-semibold">{t("Filter")}</span>
        </div>
        <button onClick={() => setShowFilter(false)}>
          <CloseIcon className="size-5" />
        </button>
      </div>
      <div className="p-2 border-b">
        {filterList?.map((filterSection, key) => {
          const title = filterSection?.label || "";
          const options = filterSection?.filterable_values || [];
          const code = filterSection?.filter_key || "";
          console.log({ open });
          return options?.length ? (
            <div className="mb-3" key={key}>
              <div
                onClick={() => {
                  setState(state === "brands" ? false : "brands");
                  setOpen([
                    ...open.slice(0, key),
                    !open[key],
                    ...open.slice(key + 1, open.length),
                  ]);
                }}
                className="flex items-center justify-between mb-1.5"
              >
                <span className="font-semibold max-sm:text-sm">{title}</span>
                <Arrow
                  className={`size-3.5 text-zinc-500 ${
                    !open[key]
                      ? language === "en"
                        ? "rotate-0"
                        : "rotate-180"
                      : "rotate-90"
                  }`}
                />
              </div>
              {open[key] && (
                <Brands
                  {...{ options, selectedFilterKeys, code, handleFilter, t }}
                />
              )}
            </div>
          ) : null;
        })}
      </div>
      <div className="sticky bottom-0 z-50 grid grid-cols-2 p-2 bg-white max-sm:text-sm">
        <button
          onClick={() => setShowFilter(false)}
          className="p-1 text-white bg-orange-600 border border-orange-600 rounded-md sm:p-2"
        >
          {t("Show Result")}
        </button>
        <button
          className="p-1 font-semibold text-red-600 sm:p-2"
          onClick={() => handleResetFilter && handleResetFilter()}
        >
          {t("Reset Filter")}
        </button>
      </div>
    </div>
  );
};

export default Filter;
