import { createCart, getCart, mergeMaskedCart } from "@/services/cart";
import { setCookie, getCookies } from "cookies-next";

export const mergeGuestCart = async (session, dispatchCart) => {
  const { handshake, quote } = getCookies();
  const mage = session?.mage;
  if (quote?.match(/^\d+$/) === null && session) {
    const { store_id, id } = session?.user || {};
    const res = await mergeMaskedCart(handshake, mage, quote, store_id, id);
    if (res.error === null && res.data?.data) {
      await loginCartHandler(session, dispatchCart);
    }
    return;
  }

  if (!quote?.match(/^\d+$/) && session) {
    await loginCartHandler(session, dispatchCart);
  }
};

export const loginCartHandler = async (session, dispatchCart) => {
  const { handshake, quote } = getCookies();
  const mage = session?.mage;

  const { error, data } = await createCart(handshake, mage);
  if (!error) {
    setCookie("quote", data?.cart_id);
  }
  const cartRes = await getCart(handshake, mage, quote);
  debugger;
  dispatchCart({ type: "get_cart", payload: cartRes?.data?.data });
  window.location.reload();
};
