import { object, ref, string } from "yup";

export const loginValidation = (t) =>
  object().shape({
    email: string()
      // .matches(
      //   /^(05\d{8}|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
      //   t(
      //     "Please enter a valid email or 10-digit mobile number starting with 05"
      //   )
      // )
      .email(t("InvalidEmail"))
      .required(t("EmailRequired")),
    password: string().required(t("PasswordRequired")),
  });

export const registerValidation = (t) =>
  object().shape({
    firstname: string().required(t("FirstNameRequired")),
    lastname: string().required(t("LastNameRequired")),
    terms: string().required(t("YouMustAcceptTerms")),
    email: string().email(t("InvalidEmail")).required(t("EmailRequired")),
    mobile: string()
      .matches(/^(?!.*[\u0660-\u0669])[0-9]+$/, t("telephoneError1"))
      .matches(/^(05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/, t("telephoneError2"))
      .min(10)
      .max(10)
      .required(t("TelephoneRequired")),
    password: string()
      .min(8, t("HasAtLeast8Characters"))
      .matches(/[A-Z]/, t("ContainsOneUppercaseLetter"))
      .matches(/[a-z]/, t("ContainsOneLowercaseLetter"))
      .matches(/[0-9]/, t("ContainsOneNumber"))
      .required(t("PasswordRequired")),
    confirmPassword: string()
      .oneOf([ref("password"), null], t("PasswordsMustMatch"))
      .required(t("ConfirmPasswordRequired")),
  });

export const otpValidation = (t) =>
  object().shape({
    otp: string().required(t("Otp is required")),
  });

export const emailValidation = (t) =>
  object().shape({
    email: string().email(t("Invalid Email")).required(t("email is required")),
  });

export const resetPasswordValidation = (t) =>
  object().shape({
    password: string()
      .min(8, t("HasAtLeast8Characters"))
      .matches(/[A-Z]/, t("ContainsOneUppercaseLetter"))
      .matches(/[a-z]/, t("ContainsOneLowercaseLetter"))
      .matches(/[0-9]/, t("ContainsOneNumber"))
      .required(t("PasswordRequired")),
    confirmPassword: string()
      .oneOf([ref("password"), null], t("PasswordsMustMatch"))
      .required(t("ConfirmPasswordRequired")),
  });
