const Lang = (props) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.99935 13.2712C3.54018 13.2712 0.728516 10.4595 0.728516 7.00033C0.728516 3.54116 3.54018 0.729492 6.99935 0.729492C10.4585 0.729492 13.2702 3.54116 13.2702 7.00033C13.2702 10.4595 10.4585 13.2712 6.99935 13.2712ZM6.99935 1.60449C4.02435 1.60449 1.60352 4.02533 1.60352 7.00033C1.60352 9.97533 4.02435 12.3962 6.99935 12.3962C9.97435 12.3962 12.3952 9.97533 12.3952 7.00033C12.3952 4.02533 9.97435 1.60449 6.99935 1.60449Z"
      fill="currentColor"
    />
    <path
      d="M5.24965 12.688H4.66632C4.42715 12.688 4.22882 12.4897 4.22882 12.2505C4.22882 12.0113 4.41548 11.8188 4.65465 11.813C3.73882 8.68632 3.73882 5.31465 4.65465 2.18799C4.41548 2.18215 4.22882 1.98965 4.22882 1.75049C4.22882 1.51132 4.42715 1.31299 4.66632 1.31299H5.24965C5.38965 1.31299 5.52381 1.38299 5.60548 1.49382C5.68715 1.61049 5.71048 1.75632 5.66382 1.89049C4.56715 5.18632 4.56715 8.81465 5.66382 12.1163C5.71048 12.2505 5.68715 12.3963 5.60548 12.513C5.52381 12.618 5.38965 12.688 5.24965 12.688Z"
      fill="currentColor"
    />
    <path
      d="M8.74942 12.6876C8.70275 12.6876 8.65608 12.6817 8.60942 12.6642C8.38192 12.5884 8.25358 12.3376 8.33525 12.1101C9.43192 8.81423 9.43192 5.1859 8.33525 1.88423C8.25942 1.65673 8.38192 1.4059 8.60942 1.33006C8.84275 1.25423 9.08775 1.37673 9.16358 1.60423C10.3244 5.0809 10.3244 8.90756 9.16358 12.3784C9.10525 12.5709 8.93025 12.6876 8.74942 12.6876Z"
      fill="currentColor"
    />
    <path
      d="M7 10.0338C5.3725 10.0338 3.75083 9.80628 2.1875 9.34545C2.18167 9.57878 1.98917 9.77128 1.75 9.77128C1.51083 9.77128 1.3125 9.57295 1.3125 9.33378V8.75045C1.3125 8.61045 1.3825 8.47628 1.49333 8.39462C1.61 8.31295 1.75583 8.28962 1.89 8.33628C5.18583 9.43295 8.82 9.43295 12.1158 8.33628C12.25 8.28962 12.3958 8.31295 12.5125 8.39462C12.6292 8.47628 12.6933 8.61045 12.6933 8.75045V9.33378C12.6933 9.57295 12.495 9.77128 12.2558 9.77128C12.0167 9.77128 11.8242 9.58462 11.8183 9.34545C10.2492 9.80628 8.6275 10.0338 7 10.0338Z"
      fill="currentColor"
    />
    <path
      d="M12.2482 5.68811C12.2016 5.68811 12.1549 5.68228 12.1082 5.66478C8.81241 4.56811 5.17825 4.56811 1.88241 5.66478C1.64908 5.74061 1.40408 5.61811 1.32825 5.39061C1.25825 5.15728 1.38075 4.91228 1.60825 4.83645C5.08491 3.67561 8.91158 3.67561 12.3824 4.83645C12.6099 4.91228 12.7382 5.16311 12.6566 5.39061C12.6041 5.57145 12.4291 5.68811 12.2482 5.68811Z"
      fill="currentColor"
    />
  </svg>
);
export default Lang;
