import AddEditCheckoutAddress from "@/components/forms/checkout/addresses/AddEditCheckoutAddress";
import CloseIcon from "@/components/icons/CloseIcon";
import { useTranslation } from "next-i18next";
const AddCheckoutAddress = ({ Context }) => {
  const { t } = useTranslation("checkout");

  return (
    <div className="bg-white p-3 sm:w-[800px] max-h-[90vh] overflow-y-auto rounded-md w-11/12 mx-auto">
      <div className="flex items-center justify-between pb-2 border-b">
        <h1 className="font-bold sm:text-lg">{t("Add New Address")}</h1>
        <button
          onClick={() => Context?.dispatch({ type: "addCheckoutAddress" })}
        >
          <CloseIcon className="size-3.5 object-contain" />
        </button>
      </div>
      <AddEditCheckoutAddress isPopup={true} />
    </div>
  );
};

export default AddCheckoutAddress;
