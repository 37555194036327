import useOnClickOutside from "@/hooks/useOnClickOutside";
import CloseIcon from "@/components/icons/CloseIcon";
import SortByIcon from "@/components/icons/SortBy";
import { sortKeys } from "@/constants/Category";

import { useRef } from "react";
import { useBodyScrollLock } from "@/hooks/global/useBodyScrollLock";

const SortBy = ({ setShowSort, handleSorting, t }) => {
  const ref = useRef();
  useOnClickOutside(ref, () => setShowSort(false));
  useBodyScrollLock()
  return (
    <div ref={ref} className="absolute bottom-0 left-0 w-screen bg-white">
      <div className="flex items-center justify-between px-2 py-3 border-b">
        <div className="flex items-center gap-2">
          <SortByIcon className="size-5" />
          <span className="font-semibold">{t("Sort By")}</span>
        </div>
        <button onClick={() => setShowSort(false)}>
          <CloseIcon className="size-5" />
        </button>
      </div>
      <ul className="p-2 mt-2 border-b">
        {sortKeys?.map((item, key) => (
          <li
            key={key}
            onClick={() => {
              handleSorting({ keys: item?.key });
              setShowSort(false);
            }}
            className="mx-4 mb-4 max-sm:text-sm hover:text-secondary-700"
          >
            {t(item?.label)}
          </li>
        ))}
      </ul>
      <div className="grid grid-cols-2 p-2 max-sm:text-sm">
        <button
          onClick={() => setShowSort(false)}
          className="p-1 text-white bg-orange-600 border border-orange-600 rounded-md sm:p-2"
        >
          {t("Show Result")}
        </button>
        <button
          className="p-1 font-semibold text-red-600 sm:p-2"
          onClick={() => handleSorting({ keys: "sortBy=position&sortDir=ASC" })}
        >
          {t("Reset Filter")}
        </button>
      </div>
    </div>
  );
};

export default SortBy;
