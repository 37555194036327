const Star = (props) => (
  <svg
    width={12}
    height={11}
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.1637 4.09625C11.01 3.59671 10.5488 3.19707 10.0365 3.14712L7.88446 2.84739L6.91093 0.849223C6.65474 0.299726 6.1936 0 5.62998 0C5.06636 0 4.60521 0.299726 4.34902 0.799268L3.37549 2.79744L1.22349 3.14712C0.711105 3.24703 0.249961 3.59671 0.096246 4.09625C-0.108707 4.64575 0.0450078 5.19524 0.454914 5.59488L2.0433 7.14346L1.68463 9.34145C1.58215 9.89095 1.83835 10.4404 2.29949 10.7402C2.55568 10.89 2.81187 10.9899 3.1193 10.9899C3.37549 10.9899 3.58045 10.94 3.7854 10.8401L5.73245 9.79104L7.67951 10.8401C8.14065 11.0898 8.70427 11.0399 9.16541 10.7402C9.62656 10.4404 9.88275 9.89095 9.78027 9.34145L9.42161 7.14346L11.01 5.59488C11.2149 5.19524 11.3687 4.59579 11.1637 4.09625Z"
      fill="currentColor"
    />
  </svg>
);
export default Star;
