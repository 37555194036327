import Link from "next/link";
import Arrow from "../icons/Arrow";
import { useRouter } from "next/router";

const TitleArrows = ({ title, viewTitle, url = "/", swiper }) => {
  const { locale } = useRouter();
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center justify-between w-full h-8 gap-3 sm:w-fit sm:justify-normal">
        <div className="text-lg font-bold sm:text-2xl">{title}</div>
        {viewTitle ? (
          <Link
            className="flex items-center h-full gap-1 text-orange-600 underline sm:self-end"
            href={url}
          >
            <span className="max-sm:text-xs text-sm font-bold">
              {viewTitle}
            </span>
            <Arrow
              className={`size-2 mt-0.5 ${
                locale === "en" ? "rotate-0" : "rotate-180"
              }`}
            />
          </Link>
        ) : null}
      </div>
      <div
        className={`lg:flex hidden ${
          locale == "en" ? "flex-row" : "flex-row-reverse"
        } items-center gap-3`}
      >
        <button
          onClick={() => swiper?.slidePrev()}
          className="flex items-center justify-center p-1 bg-white border rounded-full shadow-brand size-8"
        >
          <Arrow width={6} height={12} className="rotate-180" />
        </button>
        <button
          onClick={() => swiper?.slideNext()}
          className="flex items-center justify-center p-1 bg-white border rounded-full shadow-brand size-[34px]"
        >
          <Arrow width={6} height={12} />
        </button>
      </div>
    </div>
  );
};

export default TitleArrows;
