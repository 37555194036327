/*
===============================|> All quarapay APis <|===============================
01 |--> get quarapay otp
02 |--> verify quarapay otp
===============================|> All quarapay APis <|===============================
*/

import axios from "@/utils/axios-config";
import { isMaskCart } from "@/helpers/common/IsMaskCart";
import * as Sentry from "@sentry/react";

// 01- =============> set payfort payment method by quote
const getQuaraPayOtp = async (handshake, quote, mobile, email) => {
  const checkQuote = isMaskCart(quote);
  let data = JSON.stringify({
    mobile: mobile,
    email:email,
    quoteId: quote,
    isMask: checkQuote,
  });
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "/quara/send",
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
    data: data,
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

// 02- =============> quara-pay verify otp
const verifyQuaraPayOtp = async (
  handshake,
  quote,
  otp,
  mobile,
  email
) => {
  const checkQuote = isMaskCart(quote);
  let data = {
    otp: otp,
    mobile: mobile,
    quoteId: quote,
    isMask: checkQuote,
    email:email
  };

  let config = {
    method: "post",
    url: "/quara/verfiy",
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
    data: JSON.stringify(data),
  };
  try {
    console.log("quaradata", data);
    const req = await axios(config);
    return req.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

const createQuaraRequest = async (
  handshake,
  values
) => {
  let config = {
    method: "post",
    url: "/quara/createRequest",
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
    data: JSON.stringify(values),
  };
  try {
    debugger;
    console.log("quaradata", values);
    const req = await axios(config);
    return req.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

export { getQuaraPayOtp, verifyQuaraPayOtp, createQuaraRequest };
