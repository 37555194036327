import RemoveIcon from "../icons/RemoveIcon";
import AddIcon from "../icons/AddIcon";
import toast from "react-hot-toast";
import Heart from "../icons/Heart";
import Image from "@/components/Image";

import { useState } from "react";
import { getCookie, getCookies, setCookie } from "cookies-next";
import { deleteCartItem, updateCartItem } from "@/services/cart";
import { handleWishList } from "@/utils/wishlist";
import { useWishlist } from "@/context/wishlist";
import FillHeart from "../icons/FillHeart";
import { useCart } from "@/context/cart";
import MinusIcon from "../icons/MinusIcon";
import { validatePrice } from "@/helpers/product";
import { event_add_to_cart, event_remove_from_cart } from "@/utils/gtm";
import { handleProductForGTMEvents } from "@/utils/global";
import SarIcon from "../icons/Sar";

const CartModalCard = ({ language, info, Data }) => {
  console.log("info", info);
  const price = validatePrice(
    info?.prices_with_tax || {
      discounted_price_from:
        info?.extension_attributes?.special_price_from || "",
      discounted_price_to: info?.extension_attributes?.special_price_to || "",
      original_price: info?.extension_attributes?.original_price || 0,
      price: info?.extension_attributes?.special_price || 0,
    }
  );
  const { wishlist, dispatch } = useWishlist();
  const [loadingBtn, setLoadingBtn] = useState("");
  const [qty, setQty] = useState(info?.qty || 1);
  const { dispatch: dispatchCart } = useCart();

  const handleRemoveItem = async () => {
    const { handshake, mage, quote } = getCookies();
    setLoadingBtn("deleting");
    const res = await deleteCartItem(
      handshake,
      mage,
      quote,
      info?.item_id || info?.data?.item_id
    );
    console.log("res quote check ================================", {
      old: quote,
      new: res?.data?.quoteId,
    });
    if (res?.data?.quoteId !== undefined && res?.data?.quoteId != quote) {
      setCookie("quote", res?.data?.quoteId);
      dispatchCart({ type: "clear" });
    }
    if (res?.status === 200) {
      event_remove_from_cart(handleProductForGTMEvents(info || info?.data, qty), qty, getCookie("coupon_code") || (info || info?.data)?.coupon_code)
      Data?.dispatch({
        type: "remove",
        payload: { item_id: info?.item_id || info?.data?.item_id },
      });
      toast.success(
        language === "en"
          ? "Item Deleted From Cart"
          : "تم إزالة المنتج من عربة التسوق"
      );
    } else {
      toast.error(
        language === "en"
          ? "Error Occured, please try again"
          : "حذث خطأ, برجاء إعادة المحاولة"
      );
    }
    setLoadingBtn("");
  };
  const handleUpdateItem = async (qty, status) => {
    const { handshake, mage, quote } = getCookies();
    setLoadingBtn(status);
    const res = await updateCartItem(
      handshake,
      mage,
      quote,
      info?.item_id || info?.data?.item_id,
      qty
    );
    if (res?.data?.quoteId !== undefined && res?.data?.quoteId != quote) {
      setCookie("quote", res?.data?.quoteId);
      dispatchCart({ type: "clear" });
    }
    if (res?.status === 200) {
      +(info || info?.data)?.qty < qty
        ? event_add_to_cart(handleProductForGTMEvents((info || info?.data), 1), 1, getCookie("coupon_code") || (info || info?.data)?.coupon_code)
        : event_remove_from_cart(handleProductForGTMEvents((info || info?.data), 1), 1, getCookie("coupon_code") || (info || info?.data)?.coupon_code);
      
      setQty(qty);
      toast.success(
        language === "en"
          ? "Item Updated Successfully"
          : "تم تعديل كمية المنتج بنجاح"
      );
      dispatchCart({ type: "add_item", payload: { ...info, qty } });
    } else {
      toast.error(
        language === "en"
          ? "Error Occured, please try again"
          : "حذث خطأ, برجاء إعادة المحاولة"
      );
    }
    setLoadingBtn("");
  };

  const keywords = info?.keywords || info?.extension_attributes?.keywords || [];

  return (
    <div className="flex gap-2 p-2 my-1 border rounded">
      <div className="relative">
        <div className=" border rounded-md p-2 size-20">
          <Image
            src={
              info?._media_?.image[0]?.image ||
              `${process.env.NEXT_PUBLIC_IMG_BASEURL}/catalog/product/${info?.extension_attributes?.product_image}` ||
              "/imgs/pdp/Album-Main-Img-1.png"
            }
            className="size-full object-contain"
            height={100}
            width={100}
            alt={
              Array.isArray(info?.name[0])
                ? info?.name[0]
                : info?.name || "Cart Product Img!"
            }
            loading="eager"
          />
        </div>
        <button
          onClick={() => handleWishList(wishlist, info, dispatch)}
          className="absolute z-50 shadow flex items-center justify-center p-1 bg-white border rounded-full -top-0.5 -right-0.5"
        >
          {info?.sku in wishlist ? (
            <FillHeart className="size-4" />
          ) : (
            <Heart className="size-4" />
          )}
        </button>
      </div>
      <div className="flex-1 *:mb-1.5">
        <p className="font-semibold uppercase max-sm:text-sm line-clamp-2">
          {Array.isArray(info?.name[0]) ? info?.name[0] : info?.name || ""}
        </p>
        <ul className="flex flex-wrap items-center gap-2 text-xs max-h-[26px] overflow-hidden">
          {keywords?.map((word, i) => (
            <li key={i} className="px-2 py-1 border rounded-lg">
              {word}
            </li>
          ))}
        </ul>
        <div className="flex items-center justify-between gap-1.5 flex-wrap my-2">
          <div className="flex items-center *:flex *:items-center *:justify-center">
            <button
              onClick={() =>
                info?.qty > 1
                  ? handleUpdateItem(qty - 1, "removing")
                  : handleRemoveItem()
              }
              disabled={loadingBtn === "removing" || loadingBtn === "deleting"}
              className="border rounded-md size-6 disabled:cursor-not-allowed"
            >
              {loadingBtn === "removing" || loadingBtn === "deleting" ? (
                <span className="border-t border-r border-black rounded-full animate-spin size-3" />
              ) : info?.qty > 1 ? (
                <MinusIcon className="object-contain size-3" />
              ) : (
                <RemoveIcon className="size-4" />
              )}
            </button>
            <span className="w-10 text-sm">{qty}</span>
            <button
              disabled={loadingBtn === "adding"}
              onClick={() => handleUpdateItem(qty + 1, "adding")}
              className="border rounded-md size-6 disabled:cursor-not-allowed"
            >
              {loadingBtn === "adding" ? (
                <span className="border-t border-r border-black rounded-full animate-spin size-3" />
              ) : (
                <AddIcon className="size-2.5" />
              )}
            </button>
          </div>
          <div className="flex flex-row-reverse items-center gap-2">
            <p className="font-bold max-sm:text-sm">
              {price?.specialPrice || price?.originalPrice} <SarIcon className=" relative inline-block h-6 w-6"/>
            </p>
            {price?.specialPrice && (
              <p className="text-sm line-through max-sm:text-xs text-zinc-500">
                {price?.originalPrice} <SarIcon className=" relative inline-block h-6 w-6"/>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartModalCard;
