import selectedAtteributes from "@/constants/selectedAttrubites";
import * as Sentry from "@sentry/react";
import axios from "@/utils/axios-config";
const getProductBySku = async (handshake, sku, getFullResponnse = true) => {
  const config = {
    method: "get",
    url: `/search/product/${sku}`,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  if (!getFullResponnse) {
    config.url += `?${selectedAtteributes}`;
  }
  try {
    const req = await axios(config);
    console.log("getProductBySku Success ==================>>", req);
    return req;
  } catch (error) {
    console.log("getProductBySku Error ==================>>", error);
    return error;
  }
};

const getProductsBySkus = async (
  handshake,
  skuBulk,
  getFullResponnse = true,
  fromPageProducts = false
) => {
  const transformSKus = skuBulk
    ? skuBulk.map((sku) => "sku=" + sku).join("&")
    : "";
console.log("transformSKus", transformSKus);
  const config = {
    method: "get",
    url: "/search/product?" + transformSKus,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  if (!getFullResponnse) {
    config.url += `&${selectedAtteributes}`;
  }
  if(fromPageProducts){
    config.url += "&pageSize=100";
  }
  try {
    console.log("getProductsBySkus ==================>>", config);
    const req = await axios(config);
    console.log("getProductsBySkus Success ==================>>", req);
    return req;
  } catch (error) {
    console.log("getProductsBySkus Error ==================>>", error);
    Sentry.captureException(error);
    return error;
  }
};

const getAvailableBranches = async (handshake, sku) => {
  const config = {
    method: "get",
    url: "/mstore/store-locator-by-product/" + sku,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

const notifyWhenAvailableProduct = async ({ handshake, userId, entity_id }) => {
  const config = {
    method: "get",
    url: `/mstore/notify-me/customer/${userId}/product/${entity_id}`,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

const getProductsRating = async (handshake, entity_ids) => {

  let config = {
    method: 'post',
    url: '/product/reviews/rating',
    headers: { 
      'Authorization': `Bearer ${handshake}`, 
    },
    data: {
      // "product_ids" : [1076]
      "product_ids" : entity_ids
  }
  };
  try {
    const req = await axios(config)
    return req
    
  } catch (error) {
    return error
  }
}

export {
  getProductBySku,
  getProductsBySkus,
  getAvailableBranches,
  notifyWhenAvailableProduct,
  getProductsRating,
};
