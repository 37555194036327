const LocationCheck = (props) => (
  <svg
    width={14}
    height={16}
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.00109 15.1735C6.01443 15.1735 5.02109 14.8002 4.24776 14.0602C2.28109 12.1668 0.107761 9.14683 0.927761 5.5535C1.66776 2.2935 4.51443 0.833496 7.00109 0.833496C7.00109 0.833496 7.00109 0.833496 7.00776 0.833496C9.49443 0.833496 12.3411 2.2935 13.0811 5.56016C13.8944 9.1535 11.7211 12.1668 9.75443 14.0602C8.98109 14.8002 7.98776 15.1735 7.00109 15.1735ZM7.00109 1.8335C5.06109 1.8335 2.56776 2.86683 1.90776 5.7735C1.18776 8.9135 3.16109 11.6202 4.94776 13.3335C6.10109 14.4468 7.90776 14.4468 9.06109 13.3335C10.8411 11.6202 12.8144 8.9135 12.1078 5.7735C11.4411 2.86683 8.94109 1.8335 7.00109 1.8335Z"
      fill="currentColor"
    />
    <path
      d="M6.1663 9.16646C6.03964 9.16646 5.91297 9.1198 5.81297 9.0198L4.81297 8.0198C4.61964 7.82646 4.61964 7.50646 4.81297 7.31313C5.0063 7.1198 5.3263 7.1198 5.51964 7.31313L6.1663 7.9598L8.47964 5.64646C8.67297 5.45313 8.99297 5.45313 9.1863 5.64646C9.37964 5.8398 9.37964 6.1598 9.1863 6.35313L6.51964 9.0198C6.41964 9.1198 6.29297 9.16646 6.1663 9.16646Z"
      fill="currentColor"
    />
  </svg>
);
export default LocationCheck;
