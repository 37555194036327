import SearchIcon from "@/components/icons/SearchIcon";
import { useGlobalData } from "@/context/global";
import { ModalContext } from "@/pages/_app";
import { useContext, useState } from "react";

const Search = ({
  inputValue,
  setInputValue,
  className,
  t,
  ourSearchInput,
}) => {
  const Context = useContext(ModalContext);
  const {
    appData: { reg },
  } = useGlobalData();
  const [focused, setFocused] = useState(false);
  return (
    <div
      className={`${
        focused ? "border border-black" : "border"
      } p-1.5 max-sm:p-1.5 rounded-xl max-lg:order-3 max-lg:w-full flex items-center gap-2  ${
        className || ""
      } flex-grow`}
    >
      <SearchIcon className="size-5 text-zinc-200" />
      <input
        ref={ourSearchInput}
        value={inputValue}
        placeholder={t("Search")}
        onBlur={() => setFocused(false)}
        onFocus={() => {
          if (!reg?.id && false) {
            Context?.dispatch({
              type: "select_city",
              payload: { ourSearchInput },
            });
          } else {
            setFocused(true);
          }
        }}
        className="flex-1 border-none outline-none text-xs"
        onChange={(e) => setInputValue(e.target.value)}
      />
    </div>
  );
};

export default Search;
