import CloseIcon from "@/components/icons/CloseIcon";
import Register from "../forms/auth/Register";
import Login from "../forms/auth/Login";

import { useRef, useState } from "react";
import { useRouter } from "next/router";
import useOnClickOutside from "@/hooks/useOnClickOutside";

const Auth = ({ Context }) => {
  const [login, setLogin] = useState(true);
  const { locale } = useRouter();
  const ref = useRef();
  useOnClickOutside(ref, () => Context?.dispatch({ type: "auth" }));
  return (
    <section
      ref={ref}
      className="bg-white w-11/12 mx-auto sm:w-[640px] max-h-[90vh] overflow-y-auto rounded-md"
    >
      <div className="flex items-center justify-between p-3 pb-3 border-b sm:p-4">
        <h2 className="font-bold sm:text-lg">
          {login
            ? locale === "en"
              ? "Login"
              : "تسجيل الدخول"
            : locale === "en"
            ? "Register"
            : "إشتراك"}
        </h2>
        <button onClick={() => Context?.dispatch({ type: "auth" })}>
          <CloseIcon className="size-5" stroke="#005471" strokeWidth="2" />
        </button>
      </div>
      <section className="p-3 sm:p-4">
        <div className="grid grid-cols-2 max-sm:text-sm">
          <button
            onClick={() => setLogin(true)}
            className={`w-full border p-2 font-bold ${
              login
                ? "border-orange-600 text-white bg-orange-600"
                : "text-[#005471]"
            } rounded-s-lg`}
          >
            {locale === "en" ? "Login" : "تسجيل الدخول"}
          </button>
          <button
            onClick={() => setLogin(false)}
            className={`w-full border p-2 font-bold ${
              !login
                ? "border-orange-600 text-white bg-orange-600"
                : "text-[#005471]"
            } rounded-e-lg`}
          >
            {locale === "en" ? "Register" : "إشتراك"}
          </button>
        </div>
        {login ? (
          <Login Context={Context} />
        ) : (
          <Register Context={Context} setLogin={setLogin} />
        )}
      </section>
    </section>
  );
};

export default Auth;
