import { otpValidation } from "@/utils/valitation/auth";
import { useTranslation } from "next-i18next";
import { Form, Formik } from "formik";

import Input from "../Input";
import CloseIcon from "@/components/icons/CloseIcon";
import { getCookie, setCookie } from "cookies-next";
import { useRef } from "react";
import useOnClickOutside from "@/hooks/useOnClickOutside";
import { useRouter } from "next/router";

const Otp = ({ Context }) => {
  const { t } = useTranslation("common");
  const ref = useRef();
  const { locale } = useRouter();
  useOnClickOutside(ref, () => Context?.dispatch({ type: "otp" }));

  const handleOTP = async ({ otp }) => {
    const resetPassword = JSON.parse(getCookie("resetPassword"));
    resetPassword &&
      setCookie("resetPassword", JSON.stringify({ ...resetPassword, otp }));
    Context?.dispatch({ type: "otp" });
    Context?.dispatch({ type: "resetPassword" });
  };

  return (
    <section
      ref={ref}
      className="bg-white w-11/12 mx-auto sm:w-[570px] p-4 rounded-md"
    >
      <div className="flex items-center justify-between pb-2 border-b">
        <h2 className="sm:text-lg font-bold">{t("OTP")}</h2>
        <button onClick={() => Context?.dispatch({ type: "otp" })}>
          <CloseIcon className="size-5" />
        </button>
      </div>
      <p className="max-sm:text-sm my-2 text-center">
        <span className="font-semibold text-orange-600">{t("Enter OTP")}</span>{" "}
        <span className="font-semibold text-zinc-500">
          {t("send to your email address")}
        </span>
      </p>
      <Formik
        onSubmit={handleOTP}
        initialValues={{ otp: "" }}
        validationSchema={otpValidation(t)}
      >
        {() => (
          <Form className="grid grid-cols-1 gap-2">
            <Input
              name="otp"
              id="otp"
              placeholder={t("OTP")}
              dir={locale === "en" ? "ltr" : "rtl"}
              t={t}
            />
            <button
              className="max-sm:text-sm w-full p-2 text-white bg-orange-600 rounded-md"
              type="submit"
            >
              {t("Checking OTP")}
            </button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default Otp;
